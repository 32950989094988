import { User } from "dtos/User";
import { useAppSelector } from "app/store";
import { selectMe } from "features/users/usersSlice";
import { getYear } from "date-fns";
import { useGetEarliestGigDatetimeQuery } from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";

/**
 * Returns an ordered array of years from the earliest year the current user has a gig in CYCTB until the present year.
 */
export function useYearsActive(): number[] {
  const me: User | null = useAppSelector(selectMe);

  const { data: earliestGigDatetime } = useGetEarliestGigDatetimeQuery(
    me?.id ?? skipToken,
  );

  const latestYear = getYear(new Date());
  const earliestYear = getYear(earliestGigDatetime || new Date());
  return Array.from(
    Array(latestYear - earliestYear + 1),
    (e, i) => i + earliestYear,
  ).reverse();
}
