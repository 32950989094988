import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Stack,
  TextField,
} from "@mui/material";
import { DepInstrument } from "dtos/Dep";
import { grey } from "@mui/material/colors";
import { byId } from "utils/utils";
import { useGetInstrumentsQuery } from "api/apiSlice";

export interface EditDepProps {
  givenName: string;
  familyName: string;
  instruments: DepInstrument[];
  notes: string;
  createNew: boolean;
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (
    givenName: string,
    familyName: string,
    notes: string,
    instruments: number[],
  ) => void;
}

/**
 * Allows an existing dep to be edited, or a new one created.
 */
export function EditDep(props: EditDepProps) {
  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [notes, setNotes] = useState("");
  const [instruments, setInstruments] = useState<number[]>([]);

  const { data: allInstruments = [] } = useGetInstrumentsQuery();

  const sortedInstruments = useMemo(
    () => allInstruments.slice().sort((a, b) => a.order - b.order),
    [allInstruments],
  );
  const instrumentsById = byId(allInstruments);

  const setFormFromProps = useCallback(() => {
    setGivenName(props.givenName);
    setFamilyName(props.familyName);
    setNotes(props.notes);
    setInstruments(
      props.instruments.map((depInstrument) => depInstrument.instrument),
    );
  }, [props]);

  useEffect(() => {
    setFormFromProps();
  }, [props]);

  const instrumentCheckbox = (instrumentId: number) => {
    const checked = instruments.indexOf(instrumentId) !== -1;
    // The dep's instruments with this instrument removed.
    const depInstrumentsWithout = instruments.filter((i) => i !== instrumentId);

    return (
      <FormControlLabel
        key={instrumentId}
        control={
          <Checkbox
            checked={checked}
            onChange={(ignored, checked) => {
              setInstruments(
                checked
                  ? [...depInstrumentsWithout, ...[instrumentId]]
                  : depInstrumentsWithout,
              );
            }}
            name={instrumentsById[instrumentId].name}
          />
        }
        label={instrumentsById[instrumentId].name}
      />
    );
  };

  const handleCancel = () => {
    props.handleClose();
    setFormFromProps();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={props.isOpen}
      onClose={props.handleClose}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          props.handleSubmit(givenName, familyName, notes, instruments);
        }}
      >
        <DialogTitle>{props.createNew ? "Add dep" : "Edit dep"}</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <TextField
              margin="dense"
              id="givenName"
              value={givenName}
              onChange={(event) => setGivenName(event.target.value)}
              label="Given name"
              type="text"
              variant="standard"
              autoFocus
            />
            <TextField
              margin="dense"
              id="familyName"
              value={familyName}
              onChange={(event) => setFamilyName(event.target.value)}
              label="Family name"
              type="text"
              variant="standard"
            />
            <div>
              <FormLabel
                component="legend"
                sx={{ fontSize: "80%", color: grey[500] }}
              >
                Instruments
              </FormLabel>
              <FormGroup>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: "1",
                    }}
                  >
                    {sortedInstruments
                      .slice(0, 6)
                      .map((instrument) => instrumentCheckbox(instrument.id))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: "1",
                    }}
                  >
                    {sortedInstruments
                      .slice(6)
                      .map((instrument) => instrumentCheckbox(instrument.id))}
                  </div>
                </div>
              </FormGroup>
            </div>
            <TextField
              margin="dense"
              id="notes"
              value={notes}
              onChange={(event) => setNotes(event.target.value)}
              label="Notes"
              type="text"
              variant="standard"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            disabled={givenName.trim().length === 0 || instruments.length === 0}
          >
            {props.createNew ? "Add" : "Update"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
