import { useMemo, useState } from "react";
import {
  AvailabilityStatus,
  AvailabilitySummary,
  getLatestEvent,
} from "domain/availabilityDomain";
import { byId, groupBy, keys } from "utils/utils";
import { Availability } from "dtos/Availability";
import { AvailabilityEvent, EventType } from "dtos/AvailabilityEvent";
import { useAppSelector } from "app/store";
import {
  getAvailabilitiesByGigIdAndStartTime,
  useAddAvailabilityMutation,
  useAddNewAvailabilityEventsMutation,
  useDeleteAvailabilityMutation,
  useGetManagerAvailabilitiesQuery,
  useGetPlayersQuery,
  useGetSeatsQuery,
} from "api/apiSlice";
import { selectFilterState } from "features/filter/filterSlice";
import { selectBandId } from "features/users/usersSlice";
import { skipToken } from "@reduxjs/toolkit/query";

export interface PlayerManagement {
  availabilities: { [availabilityId: number]: Availability };
  changes: { [availabilityId: number]: EventType };
  selected: { [availabilityId: number]: boolean };
  numSelected: number;
  someSelected: boolean;
  allSelected: boolean;
  depSelected: boolean;
  depsExist: boolean;
  availabilityIdSelected: number | undefined;
  saving: boolean;
  selectNone: () => void;
  selectAll: () => void;
  selectAvailable: () => void;
  selectById: (availabilityId: number, selected: boolean) => void;
  setEventForSelected: (eventType: EventType) => void;
  addAvailabilities: (newAvailabilities: {
    [availabilityId: number]: Availability;
  }) => void;
  hasUnsavedAvailabilities: () => boolean;
  getModifiedAvailabilitySummary: (
    availability: Availability,
  ) => AvailabilitySummary;
  isModified: (availability: Availability) => boolean;
  someChanges: () => boolean;
  cancelChanges: () => void;
  saveChanges: () => void;
}

export interface GigIdAndStartTime {
  gigId: number;
  startDatetime: number;
}

/**
 * Tracks all state associated with selecting players and modifying their availability.
 *
 * @param events The events to manage.
 * @param addImplicitAvailabilities Add Availability entries for all seats (including those without a player currently
 * attached) where an entry doesn't already explicitly exist. If an event is then added to any of these and saved, a new
 * Availability entry will be saved to hold the event. Used for rehearsals, where availability is implicit until
 * specified otherwise by players.
 */
export function usePlayerManagement(
  events: GigIdAndStartTime[],
  addImplicitAvailabilities: boolean,
): PlayerManagement {
  const bandId = useAppSelector(selectBandId);
  const filterCriteria = useAppSelector(selectFilterState);

  // TODO (526): Hack to load the right availability info if the request is coming from a single-event page, such as the
  //  event details page. Such events could be at a time that doesn't match the global filter settings, so without this,
  //  we'd fail to load the availability info. Note that we're loading a full year of availability here for all events
  //  in that year, which isn't terribly efficient (but maybe useful to load once in bulk rather than individually?)
  //  Anyway, this all needs a bit of a refactor really.
  const actualFilterCriteria =
    events.length === 1
      ? { year: new Date(events[0].startDatetime).getUTCFullYear() }
      : filterCriteria;

  const {
    data: managerAvailabilityInfo = {
      byId: {},
      byGigId: {},
      byGigIdAndStartTime: {},
    },
  } = useGetManagerAvailabilitiesQuery(
    {
      bandId: bandId === undefined ? 0 : bandId,
      filterCriteria: actualFilterCriteria,
    },
    { skip: bandId === null },
  );

  const dbAvailabilities = useMemo(() => {
    return byId(
      events.flatMap((event) =>
        getAvailabilitiesByGigIdAndStartTime(
          managerAvailabilityInfo,
          event.gigId,
          event.startDatetime,
        ),
      ),
    );
  }, [JSON.stringify(events), JSON.stringify(managerAvailabilityInfo)]);

  const [addAvailability, { isLoading: isAddAvailabilityLoading }] =
    useAddAvailabilityMutation();
  const [
    addNewAvailabilityEvents,
    { isLoading: isAddAvailabilityEventsLoading },
  ] = useAddNewAvailabilityEventsMutation();
  const saving = isAddAvailabilityLoading || isAddAvailabilityEventsLoading;

  const [deleteAvailability] = useDeleteAvailabilityMutation();

  const [unsavedAvailabilities, setUnsavedAvailabilities] = useState<{
    [availabilityId: number]: Availability;
  }>({});
  const [selected, setSelected] = useState<{
    [availabilityId: number]: boolean;
  }>({});
  const [changes, setChanges] = useState<{
    [availabilityId: number]: EventType;
  }>({});

  const { data: allSeats = [] } = useGetSeatsQuery(bandId ?? skipToken);
  const { data: players = [] } = useGetPlayersQuery(bandId ?? skipToken);
  const playersBySeatId = useMemo(
    () => groupBy(players, (p) => p.seat),
    [JSON.stringify(players)],
  );

  const implicitAvailabilities = useMemo(() => {
    if (addImplicitAvailabilities) {
      const existingUsers = Object.values(dbAvailabilities).map((a) => a.user);
      const existingSeats = Object.values(dbAvailabilities).map((a) => a.seat);
      let nextAvailabilityId =
        keys(dbAvailabilities).reduce((a, b) => Math.max(a, b), 0) + 1;

      return byId(
        // For each non deleted seat...
        allSeats
          .filter((seat) => !seat.deleted)
          .flatMap((seat) =>
            // For each player on that seat, or else the system user if the seat isn't filled...
            (playersBySeatId[seat.id] || [{ user: -1 }])
              .flatMap((player) =>
                // For each event we're managing...
                events.map((event) => ({
                  id: nextAvailabilityId++,
                  // Normally set the user to the user that occupies this seat, but if there's already an existing
                  // availability putting that user in a different seat, set the user to the system user so that a row
                  // is shown for their normal position that can be filled with a dep. This happens if a user has moved
                  // seat (not possible in the UI at the moment, but I can do it directly in the database).
                  user:
                    existingUsers.indexOf(player.user) !== -1 &&
                    Object.values(dbAvailabilities).find(
                      (a) => a.user === player.user,
                    )?.seat !== seat.id
                      ? -1
                      : player.user,
                  gig: event.gigId,
                  startDatetime: event.startDatetime,
                  seat: seat.id,
                  seatRename: null,
                  order: seat.order,
                  dep: false,
                  events: [],
                  nextCheck: 0,
                })),
              )
              // Remove users already present because they (or the band manager) provided some availability.
              .filter(
                (availability) =>
                  existingUsers.indexOf(availability.user) === -1,
              )
              // Don't include the system user on a seat if that seat is already occupied by somebody. This happens if
              // somebody has been explicitly moved into a seat that's normally empty (not possible in the UI at the
              // moment, but I can do it directly in the database).
              .filter(
                (availability) =>
                  availability.user !== -1 ||
                  existingSeats.indexOf(availability.seat) === -1,
              ),
          ),
      );
    } else {
      return [];
    }
  }, [allSeats, dbAvailabilities, playersBySeatId]);

  const availabilities = {
    ...dbAvailabilities,
    ...implicitAvailabilities,
    ...unsavedAvailabilities,
  };
  const numAvailability = keys(availabilities).length;
  const numSelected = keys(selected).filter(
    (availabilityId) => selected[availabilityId],
  ).length;
  const someSelected = numSelected > 0;
  const allSelected = numSelected === numAvailability;
  const availabilityIdSelected = keys(selected).find(
    (availabilityId) => selected[availabilityId],
  );
  const depSelected =
    availabilityIdSelected !== undefined &&
    availabilities[availabilityIdSelected]?.dep;
  const depsExist = keys(availabilities).some((id) => availabilities[id].dep);

  const clearChanges = () => {
    setChanges({});
    setUnsavedAvailabilities({});
  };

  const selectNone = () => setSelected({});

  const selectAll = () => setSelected(transformMap(availabilities, () => true));

  const selectAvailable = () =>
    setSelected(
      transformMap(availabilities, (availability) => {
        const summary = new AvailabilitySummary(availability.events);
        return summary.playerAvailability === AvailabilityStatus.AVAILABLE;
      }),
    );

  const selectById = (availabilityId: number, isSelected: boolean) => {
    setSelected({
      ...selected,
      [availabilityId]: isSelected,
    });
  };

  const setEventForSelected = (eventType: EventType) => {
    setChanges({
      ...changes,
      ...transformMap(selected, (playerSelected, availabilityId) => {
        return playerSelected ? eventType : changes[availabilityId];
      }),
    });
    selectNone();
  };

  const addAvailabilities = (newAvailabilities: {
    [availabilityId: number]: Availability;
  }) => {
    setUnsavedAvailabilities({
      ...unsavedAvailabilities,
      ...newAvailabilities,
    });
  };

  const hasUnsavedAvailabilities = () => keys(unsavedAvailabilities).length > 0;

  const getModifiedAvailabilitySummary = (availability: Availability) => {
    if (changes[availability.id] === undefined) {
      return new AvailabilitySummary(availability.events);
    }

    if (changes[availability.id] === "REMOVE") {
      return new AvailabilitySummary(availability.events, false, true);
    }

    const extraEvent: AvailabilityEvent = {
      user: -1,
      datetime: Number.MAX_VALUE,
      type: changes[availability.id],
      askAgain: 0,
    };

    return new AvailabilitySummary([...availability.events, extraEvent]);
  };

  const isModified = (availability: Availability) =>
    (changes[availability.id] !== undefined &&
      getLatestEvent(availability.events)?.type !== changes[availability.id]) ||
    unsavedAvailabilities[availability.id] !== undefined;

  const someChanges = () =>
    keys(changes).some(
      (availabilityId) => changes[availabilityId] !== undefined,
    );

  const cancelChanges = () => {
    clearChanges();
    selectNone();
  };

  const saveChanges = async () => {
    // Remove any removed Availabilities.
    await Promise.all(
      keys(availabilities)
        .filter((availabilityId) => changes[availabilityId] === "REMOVE")
        // TODO: need to support removing implicit availability in the back end. Better for now to probably remove this
        //  option from the menu.
        .map((availabilityId) => availabilities[availabilityId])
        .map((availability) => {
          return deleteAvailability(availability.id);
        }),
    );

    // Save any new Availabilities -- i.e. deps and implicit availabilities that have changes.
    const availabilityIdsForSaving = [
      ...keys(unsavedAvailabilities),
      ...Object.values(implicitAvailabilities)
        .filter((availability) => changes[availability.id] !== undefined)
        .map((availability) => availability.id),
    ];
    const newAvailabilities = await Promise.all(
      availabilityIdsForSaving
        .map((availabilityId) => availabilities[availabilityId])
        .map((availability) => {
          return addAvailability({
            user: availability.user,
            gig: availability.gig,
            startDatetime: availability.startDatetime,
            seat: availability.seat,
            seatRename: null,
            order: availability.order,
            dep: availability.dep,
            events: availability.events,
            nextCheck: availability.nextCheck,
          }).unwrap();
        }),
    );

    // Once we've saved the new availabilities, they will almost certainly have a different id allocated to them by
    // the backend than the one we made up. Build a mapping from old to new.
    const oldToNew: { [oldAvailabilityId: number]: number } = {};
    availabilityIdsForSaving.forEach((oldAvailabilityId, i) => {
      oldToNew[oldAvailabilityId] = newAvailabilities[i].id;
    });

    // Now save the availability events for both existing availabilities and dep availabilities.
    await addNewAvailabilityEvents(
      keys(availabilities)
        .filter((availabilityId) => changes[availabilityId] !== undefined)
        .filter((availabilityId) => changes[availabilityId] !== "REMOVE")
        .map((availabilityId) => availabilities[availabilityId])
        // Filter to only those events where we're actually adding a different event (not duplicating the latest one).
        .filter(
          (availability) =>
            getLatestEvent(availability.events)?.type !==
            changes[availability.id],
        )
        .reduce(
          (availabilityEvents, availability) => {
            return {
              ...availabilityEvents,
              [oldToNew[availability.id] || availability.id]: {
                user: 0,
                datetime: 0,
                type: changes[availability.id],
                askAgain: 0,
              },
            };
          },
          {} as { [availabilityId: number]: AvailabilityEvent },
        ),
    );

    clearChanges();
    selectNone();
  };

  return {
    availabilities,
    changes,
    selected,
    numSelected,
    someSelected,
    allSelected,
    depSelected,
    depsExist,
    availabilityIdSelected,
    saving,
    selectNone,
    selectAll,
    selectAvailable,
    selectById,
    setEventForSelected,
    addAvailabilities,
    hasUnsavedAvailabilities,
    getModifiedAvailabilitySummary,
    isModified,
    someChanges,
    cancelChanges,
    saveChanges,
  };
}

/**
 * Takes a map from availability id to some value and returns a new map built by applying the given function to each
 * value in the original map. If the function returns null for an item, that item isn't included in the new map.
 */
function transformMap<T, V>(
  itemMap: { [availabilityId: number]: T } | null,
  getValue: (item: T, availabilityId: number) => V | null,
) {
  return itemMap === null
    ? {}
    : keys(itemMap).reduce(
        (newMap, availabilityId) => {
          const value = getValue(itemMap[availabilityId], availabilityId);
          if (value !== null) {
            newMap[availabilityId] = value;
          }
          return newMap;
        },
        {} as { [availabilityId: number]: V },
      );
}
