import React, { useEffect, useMemo, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowParams,
  GridRowsProp,
  GridSortModel,
  GridToolbarExport,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { fullName } from "features/users/userUtils";
import { DepInstrument } from "dtos/Dep";
import { Box, Button, IconButton, Stack, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { EditDep } from "features/deps/EditDep";
import { selectBandId } from "features/users/usersSlice";
import { useAppSelector } from "app/store";
import { byId } from "utils/utils";
import {
  useAddDepMutation,
  useAddUserMutation,
  useEditDepMutation,
  useEditUserMutation,
  useGetDepsQuery,
  useGetInstrumentsQuery,
  useGetUsersQuery,
} from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { usePermissions } from "auth/usePermissions";

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}

function getQuickSearchToolbar(addNew: () => void) {
  return function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    const permissions = usePermissions();
    const canCreateDeps = permissions.hasPermission("UPDATE_DEP");

    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
          justifyContent: "space-between",
          display: "flex",
          alignItems: "flex-end",
          flexWrap: "wrap",
          m: (theme) => theme.spacing(1, 0.5, 1.5),
        }}
      >
        <Stack direction="row" spacing={1}>
          {canCreateDeps && (
            <Button size="small" startIcon={<AddIcon />} onClick={addNew}>
              Add new...
            </Button>
          )}
          {/* We don't allow export of deps if this isn't a full band manager. The data's still available, of course,
              via the API call, but this discourages it. */}
          {canCreateDeps && (
            <GridToolbarExport
              printOptions={{ disableToolbarButton: true }}
              csvOptions={{ fileName: "Deps" }}
            />
          )}
        </Stack>
        <div />
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          sx={{
            width: {
              xs: 1,
              sm: "auto",
            },
            "& .MuiSvgIcon-root": {
              mr: 0.5,
            },
            "& .MuiInput-underline:before": {
              borderBottom: 1,
              borderColor: "divider",
            },
          }}
        />
      </Box>
    );
  };
}

export interface DepManagementProps {
  disableAutoPageSize?: boolean;
}

/**
 * Shows all deps the band knows about, and allows them to be edited.
 */
export function DepManagement(props: DepManagementProps) {
  const permissions = usePermissions();
  const canEditDeps = permissions.hasPermission("UPDATE_DEP");
  const canDeleteDeps = permissions.hasPermission("DELETE_DEP");
  const canDoAnyActions = canEditDeps || canDeleteDeps;

  const bandId = useAppSelector(selectBandId);
  const { data: deps = [] } = useGetDepsQuery(bandId ?? skipToken);
  const depsById = useMemo(() => byId(deps), [deps]);
  const { data: users = [] } = useGetUsersQuery(bandId ?? skipToken);
  const usersById = useMemo(() => byId(users), [users]);
  const { data: allInstruments = [] } = useGetInstrumentsQuery();
  const [addUser] = useAddUserMutation();
  const [editUser] = useEditUserMutation();
  const [addDep] = useAddDepMutation();
  const [editDep] = useEditDepMutation();
  const instrumentsById = byId(allInstruments);
  const [editDialogueOpen, setEditDialogueOpen] = useState(false);
  const [editingDepId, setEditingDepId] = useState(-1);

  const getInstruments = (instrumentIds: DepInstrument[]) => {
    const instruments = instrumentIds.map(
      (instrument) => instrumentsById[instrument.instrument],
    );
    return instruments
      .sort((a, b) => a.order - b.order)
      .map((instrument) => instrument.name)
      .join(", ");
  };

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "name",
      sort: "asc",
    },
  ]);

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 0.2,
        hideable: false,
      },
      {
        field: "instrument",
        headerName: "Instrument",
        flex: 0.2,
        hideable: false,
      },
      {
        field: "notes",
        headerName: "Notes",
        flex: 0.6,
        hideable: false,
      },
      ...(canDoAnyActions
        ? [
            {
              field: "actions",
              type: "actions",
              getActions: (params: GridRowParams) => [
                ...(canDeleteDeps
                  ? [
                      <GridActionsCellItem
                        key={"delete"}
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => {
                          console.log("Hello", params.id);
                        }}
                      />,
                    ]
                  : []),
                ...(canEditDeps
                  ? [
                      <GridActionsCellItem
                        key={"edit"}
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={() => {
                          setEditingDepId(params.id as number);
                          setEditDialogueOpen(true);
                        }}
                      />,
                    ]
                  : []),
              ],
            },
          ]
        : []),
    ],
    [],
  );

  const allRows: GridRowsProp = useMemo(
    () =>
      deps.map((dep) => {
        return {
          id: dep.id,
          name: fullName(usersById[dep.user]),
          instrument: getInstruments(dep.instruments),
          notes: dep.notes,
        };
      }),
    [deps, usersById],
  );

  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState(allRows);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = allRows.filter((row: GridValidRowModel) => {
      return Object.keys(row).some((field: string) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  useEffect(() => {
    setRows(allRows);
  }, [allRows]);

  const editingDep = depsById[editingDepId];
  const editingUser = editingDep ? usersById[editingDep.user] : undefined;

  const quickSearchToolbar = useMemo(
    () =>
      getQuickSearchToolbar(() => {
        setEditingDepId(-1);
        setEditDialogueOpen(true);
      }),
    [],
  );

  const handleSubmit = async (
    givenName: string,
    familyName: string,
    notes: string,
    instrumentIds: number[],
  ) => {
    setEditDialogueOpen(false);
    const instruments = instrumentIds.map((id) => {
      return {
        instrument: id,
      };
    });
    if (editingDepId === -1) {
      // Create new dep.
      const newUser = await addUser({ givenName, familyName }).unwrap();
      if (bandId !== undefined) {
        addDep({
          user: newUser.id,
          band: bandId,
          instruments,
          notes,
        });
      }
    } else if (editingDep !== undefined) {
      // Edit existing dep.
      editUser({ id: editingDep.user, givenName, familyName });
      editDep({
        ...editingDep,
        notes,
        instruments,
      });
    }
  };

  return (
    <>
      <div style={{ display: "flex", maxWidth: 1000, height: "85vh" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            components={{
              Toolbar: quickSearchToolbar,
            }}
            autoPageSize={!props.disableAutoPageSize}
            disableColumnMenu
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            sortingOrder={["desc", "asc"]}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                  requestSearch(event.target.value),
                clearSearch: () => requestSearch(""),
              },
            }}
          />
        </div>
      </div>
      <EditDep
        givenName={editingUser ? editingUser.givenName : ""}
        familyName={editingUser ? editingUser.familyName : ""}
        instruments={editingDep ? editingDep.instruments : []}
        notes={editingDep ? editingDep.notes : ""}
        createNew={editingDepId === -1}
        isOpen={editDialogueOpen}
        handleClose={() => setEditDialogueOpen(false)}
        handleSubmit={handleSubmit}
      />
    </>
  );
}
