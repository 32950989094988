import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Theme, Typography } from "@mui/material";
import { CopyableTextField } from "utils/CopyableTextField";
import { useUrlSigner } from "features/misc/signedUrl";
import { useAppSelector } from "app/store";
import { selectBandId } from "features/users/usersSlice";
import { apiLink } from "utils/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      maxWidth: 1000,
      padding: theme.spacing(2),
    },
  }),
);

/**
 * Shows the manager a link to use with the WordPress plugin.
 */
export function WpCalendar() {
  const classes = useStyles();
  const bandId = useAppSelector(selectBandId);
  const urlSigner = useUrlSigner();

  const [url, setUrl] = React.useState("");
  useEffect(() => {
    const getUrl = async () => {
      if (bandId === undefined) {
        return;
      }
      setUrl(
        // TODO: this will add a user id, which we don't really want I guess.
        await urlSigner.signUrl(apiLink(`bands/${bandId}/wpcalendar`), true),
      );
    };
    getUrl();
  }, [bandId]);

  return (
    <div className={classes.main}>
      <Typography>Copy this link:</Typography>
      <CopyableTextField name="iCalendar link" text={url} />
    </div>
  );
}
