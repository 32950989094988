import {
  Button,
  Fade,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useMemo } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { AvailabilitySummaryIcon } from "features/misc/AvailablitySummaryIcon";
import { fullName } from "features/users/userUtils";
import { Availability } from "dtos/Availability";
import { selectFilterState } from "features/filter/filterSlice";
import { selectBandId } from "features/users/usersSlice";
import { useAppSelector } from "app/store";
import { Seat } from "dtos/Seat";
import { useNavigate } from "react-router-dom";
import {
  apiSlice,
  getAvailabilitiesByGigIdAndStartTime,
  ManagerAvailabilityInfo,
  useGetEventInstancesQuery,
  useGetManagerAvailabilitiesQuery,
  useGetPlayersQuery,
  useGetSeatsQuery,
  useGetUsersQuery,
} from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { byId, groupBy } from "utils/utils";
import { createSelector } from "@reduxjs/toolkit";
import { User } from "dtos/User";
import { usePermissions } from "auth/usePermissions";
import { EventInstanceForGig } from "dtos/EventInstance";
import { DatetimeSpan } from "features/misc/DatetimeSpan";
import { Player } from "dtos/Player";
import { FilterCriteria } from "features/filter/filterCriteria";
import { AvailabilitySummary } from "domain/availabilityDomain";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 1000,
    },
    header: {
      position: "sticky",
      top: "56px",
      background: "white",
      zIndex: 2,
    },
    seatCell: {
      position: "sticky",
      left: "264px",
      [theme.breakpoints.down("sm")]: {
        left: theme.spacing(3),
      },
      background: theme.palette.background.default,
      zIndex: 1,
    },
    otherCell: {
      position: "sticky",
      left: "264px",
      [theme.breakpoints.down("sm")]: {
        left: theme.spacing(3),
      },
    },
    modifiedCell: {
      background: "#eee",
    },
    selectedCell: {
      background: "#fdf",
    },
    selectedAndModifiedCell: {
      background: "#ece",
    },
    headerButton: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    },
    date: {
      color: "#777",
    },
    confirm: {
      marginTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(2),
      height: theme.spacing(8),
      position: "sticky",
      bottom: 0,
      background: theme.palette.background.default,
      zIndex: 1,
      outline: `5px solid ${theme.palette.background.default}`,
    },
  }),
);

/**
 * Shows availability for rehearsals as a grid. Temporary hack-fest to get rehearsals out before we work to do a decent
 * job of the manager's UI.
 */
export function ManagerRehearsals() {
  const bandId = useAppSelector(selectBandId);
  const permissions = usePermissions();
  const managesSeats = permissions.managesSeats();

  const { data: allSeats = [] } = useGetSeatsQuery(bandId ?? skipToken);
  const seats = useMemo(
    () =>
      allSeats
        .filter((s) => !s.deleted)
        .filter((s) =>
          // User isn't allowed to manage any seats. They probably shouldn't be on this page to begin with...
          managesSeats === undefined
            ? false
            : // User can manage any seat (they're a proper "band manager").
              managesSeats.length === 0
              ? true
              : managesSeats.includes(s.id),
        )
        .sort((a, b) => a.order - b.order),
    [allSeats],
  );

  const { data: players = [] } = useGetPlayersQuery(bandId ?? skipToken);
  const playersBySeatId = groupBy(players, (p) => p.seat);

  const { data: allRehearsals = [], isLoading: isRehearsalsLoading } =
    useGetEventInstancesQuery(
      {
        bandId: bandId === undefined ? 0 : bandId,
        filterCriteria: { year: "future" },
      },
      { skip: bandId === undefined },
    );

  const rehearsals = allRehearsals.slice(0, 10);

  const { isLoading: isAvailabilityLoading } = useGetManagerAvailabilitiesQuery(
    {
      bandId: bandId === undefined ? 0 : bandId,
      filterCriteria: { year: "future", tags: [] },
    },
    { skip: bandId === null },
  );

  useGetUsersQuery(bandId ?? skipToken);

  const classes = useStyles();

  return isRehearsalsLoading || isAvailabilityLoading ? (
    <Fade in={true} unmountOnExit style={{ transitionDelay: "800ms" }}>
      <LinearProgress />
    </Fade>
  ) : (
    <div className={classes.root}>
      <Table>
        <TableHead className={classes.header}>
          <TableRow>
            <TableCell colSpan={2}></TableCell>
            {rehearsals.map((rehearsal) => (
              <MultiGigHeader
                key={`${rehearsal.gig}/${rehearsal.eventInstance.startDatetime}`}
                rehearsal={rehearsal}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {seats.map((seat) =>
            (playersBySeatId[seat.id] || [undefined]).map((player) => (
              <MultiGigRow
                key={`${seat.id}/${player?.id}`}
                seat={seat}
                player={player}
                rehearsals={rehearsals}
              />
            )),
          )}
        </TableBody>
      </Table>
    </div>
  );
}

function MultiGigHeader({ rehearsal }: { rehearsal: EventInstanceForGig }) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <TableCell>
      <Button
        onClick={() =>
          navigate(
            `/manager/rehearsals/${rehearsal.gig}/${rehearsal.eventInstance.startDatetime}`,
          )
        }
      >
        <div className={classes.headerButton}>
          <DatetimeSpan datetime={rehearsal.eventInstance} />
        </div>
      </Button>
    </TableCell>
  );
}

function MultiGigRow({
  seat,
  player,
  rehearsals,
}: {
  seat: Seat;
  player?: Player;
  rehearsals: EventInstanceForGig[];
}) {
  const classes = useStyles();
  const bandId = useAppSelector(selectBandId);
  const users = useAppSelector(selectAllUsers(bandId));
  const user = player && byId(users)[player.user];
  const userName = user ? fullName(user) : "n/a";

  return (
    <TableRow>
      <TableCell className={classes.seatCell}>{seat.name}</TableCell>
      <TableCell className={classes.otherCell}>{userName}</TableCell>
      {rehearsals.map((rehearsal) => (
        <MultiGigCell
          key={`${rehearsal.gig}/${rehearsal.eventInstance.startDatetime}`}
          seatId={seat.id}
          rehearsal={rehearsal}
          multi={false}
        />
      ))}
    </TableRow>
  );
}

const selectManagerAvailabilitiesResult = (
  bandId: number | undefined,
  filterCriteria: FilterCriteria,
) =>
  apiSlice.endpoints.getManagerAvailabilities.select({
    bandId: bandId === undefined ? 0 : bandId,
    filterCriteria,
  });

const emptyManagerAvailability: ManagerAvailabilityInfo = {
  byId: {},
  byGigId: {},
  byGigIdAndStartTime: {},
};

const selectManagerAvailabilities = (
  bandId: number | undefined,
  filterCriteria: FilterCriteria,
) =>
  createSelector(
    selectManagerAvailabilitiesResult(bandId, filterCriteria),
    (usersResult) => usersResult?.data ?? emptyManagerAvailability,
  );

const selectUsersResult = (bandId: number | undefined) =>
  apiSlice.endpoints.getUsers.select(bandId === undefined ? 0 : bandId);

const emptyUsers: User[] = [];

const selectAllUsers = (bandId: number | undefined) =>
  createSelector(
    selectUsersResult(bandId),
    (usersResult) => usersResult?.data ?? emptyUsers,
  );

function MultiGigCell(props: {
  seatId: number;
  rehearsal: EventInstanceForGig;
  multi: boolean;
}) {
  const classes = useStyles();

  const bandId = useAppSelector(selectBandId);
  const filterCriteria = useAppSelector(selectFilterState);

  const managerAvailabilityInfo = useAppSelector(
    selectManagerAvailabilities(bandId, filterCriteria),
  );
  const availability = gigAvailability(
    props.seatId,
    getAvailabilitiesByGigIdAndStartTime(
      managerAvailabilityInfo,
      props.rehearsal.gig,
      props.rehearsal.eventInstance.startDatetime,
    ),
  );

  const users = useAppSelector(selectAllUsers(bandId));
  const user = availability ? byId(users)[availability.user] : undefined;

  if (availability == null) {
    return <TableCell />;
  }
  const name = fullName(user);
  const availabilitySummary = new AvailabilitySummary(availability.events);

  return (
    <TableCell align={"center"} className={classes.otherCell}>
      {availabilitySummary ? (
        <AvailabilitySummaryIcon
          availabilitySummary={availabilitySummary}
          gigCollecting={false}
          player={false}
          dim={true}
          tooltip={(summary) =>
            props.multi ? `${summary} (${name})` : summary
          }
        />
      ) : null}
    </TableCell>
  );
}

/**
 * Returns an Availability for a given gig and seat, or null if no player is playing on that seat. Deps are filtered
 * out.
 */
function gigAvailability(
  seatId: number,
  availabilities: Availability[],
): Availability | null {
  return (
    availabilities.filter(
      (availability) => availability.seat === seatId && !availability.dep,
    )[0] || null
  );
}
