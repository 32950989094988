import {
  Box,
  Button,
  Fade,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useMemo } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { RequiredStatus } from "domain/availabilityDomain";
import { AvailabilitySummaryIcon } from "features/misc/AvailablitySummaryIcon";
import { Gig } from "dtos/Gig";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { fullName } from "features/users/userUtils";
import { Availability } from "dtos/Availability";
import { selectFilterState } from "features/filter/filterSlice";
import { selectBandId } from "features/users/usersSlice";
import {
  PlayerManagement,
  usePlayerManagement,
} from "features/manager/playerManagement";
import {
  ModifyAvailabilityMenu,
  OPTION_AVAILABLE,
  OPTION_REQUIRED,
  OPTION_UNAVAILABLE,
  OPTION_WILL_NOT_FILL,
} from "features/manager/ModifyAvailabilityMenu";
import { PreventNavigation } from "features/manager/PreventNavigation";
import { useAppSelector } from "app/store";
import { Seat } from "dtos/Seat";
import { useNavigate } from "react-router-dom";
import {
  apiSlice,
  useGetGigsQuery,
  useGetManagerAvailabilitiesQuery,
  useGetSeatsQuery,
  useGetUsersQuery,
} from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { byId } from "utils/utils";
import { createSelector } from "@reduxjs/toolkit";
import { User } from "dtos/User";
import { usePermissions } from "auth/usePermissions";
import {
  FilterCriteria,
  filterFromCriteria,
} from "features/filter/filterCriteria";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 1000,
    },
    header: {
      position: "sticky",
      top: "56px",
      background: "white",
      zIndex: 2,
    },
    seatCell: {
      position: "sticky",
      left: "264px",
      [theme.breakpoints.down("sm")]: {
        left: theme.spacing(3),
      },
      background: theme.palette.background.default,
      zIndex: 1,
    },
    otherCell: {
      position: "sticky",
      left: "264px",
      [theme.breakpoints.down("sm")]: {
        left: theme.spacing(3),
      },
    },
    modifiedCell: {
      background: "#eee",
    },
    selectedCell: {
      background: "#fdf",
    },
    selectedAndModifiedCell: {
      background: "#ece",
    },
    headerButton: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    },
    date: {
      color: "#777",
    },
    confirm: {
      marginTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(2),
      height: theme.spacing(8),
      position: "sticky",
      bottom: 0,
      background: theme.palette.background.default,
      zIndex: 1,
      outline: `5px solid ${theme.palette.background.default}`,
    },
  }),
);

/**
 * Shows availability for multiple gigs as a grid.
 */
export function MultiGig() {
  const bandId = useAppSelector(selectBandId);
  const filterCriteria = useAppSelector(selectFilterState);
  const filter = filterFromCriteria(filterCriteria);
  const permissions = usePermissions();

  const managesSeats = permissions.managesSeats();
  const canModifyAvailability = permissions.hasPermission(
    "UPDATE_AVAILABILITY",
  );

  const { data: allGigs = [], isLoading: isGigsLoading } = useGetGigsQuery(
    { bandId: bandId === undefined ? 0 : bandId, filterCriteria },
    { skip: bandId === undefined },
  );
  const gigs = allGigs
    .filter(filter)
    .sort((a, b) => a.startDatetime - b.startDatetime);
  // TODO: should probably look at the gigs and include seats that were used but have since been deleted.
  const { data: allSeats = [] } = useGetSeatsQuery(bandId ?? skipToken);
  const seats = useMemo(
    () =>
      allSeats
        .filter((s) => !s.deleted)
        .filter((s) =>
          // User isn't allowed to manage any seats. They probably shouldn't be on this page to begin with...
          managesSeats === undefined
            ? false
            : // User can manage any seat (they're a proper "band manager").
              managesSeats.length === 0
              ? true
              : managesSeats.includes(s.id),
        )
        .sort((a, b) => a.order - b.order),
    [allSeats],
  );

  const { isLoading: isAvailabilityLoading } = useGetManagerAvailabilitiesQuery(
    {
      bandId: bandId === undefined ? 0 : bandId,
      filterCriteria,
    },
    { skip: bandId === null },
  );

  useGetUsersQuery(bandId ?? skipToken);

  const playerManagement = usePlayerManagement(
    gigs.map((gig) => {
      return {
        gigId: gig.id,
        startDatetime: gig.startDatetime,
      };
    }),
    false,
  );
  const { someChanges, cancelChanges, saveChanges, saving } = playerManagement;

  const classes = useStyles();

  return isGigsLoading || isAvailabilityLoading ? (
    <Fade in={true} unmountOnExit style={{ transitionDelay: "800ms" }}>
      <LinearProgress />
    </Fade>
  ) : (
    <div className={classes.root}>
      <Table>
        <TableHead className={classes.header}>
          <TableRow>
            <TableCell colSpan={3}>
              {canModifyAvailability && (
                <ModifyAvailabilityMenu
                  playerManagement={playerManagement}
                  menuItems={[
                    OPTION_AVAILABLE(playerManagement.setEventForSelected),
                    OPTION_UNAVAILABLE(playerManagement.setEventForSelected),
                    OPTION_REQUIRED(playerManagement.setEventForSelected),
                    OPTION_WILL_NOT_FILL(playerManagement.setEventForSelected),
                  ]}
                />
              )}
            </TableCell>
            {gigs.map((gig) => (
              <MultiGigHeader key={gig.id} gig={gig} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {seats.map((seat) => (
            <MultiGigRow
              key={seat.id}
              seat={seat}
              gigs={gigs}
              playerManagement={playerManagement}
            />
          ))}
        </TableBody>
      </Table>

      {someChanges() ? (
        <Grid
          container
          className={classes.confirm}
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Box pr={1}>
            <Grid item>
              <Button onClick={cancelChanges}>Cancel</Button>
            </Grid>
          </Box>
          <Grid item>
            <PreventNavigation
              confirmText="Confirm changes"
              confirmChanges={saveChanges}
              beforeNavigate={cancelChanges}
              saving={saving}
              disabled={false}
            />
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
}

function MultiGigHeader(props: { gig: Gig }) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <TableCell>
      <Button onClick={() => navigate("/manager/gigs/" + props.gig.id)}>
        <div className={classes.headerButton}>
          <span>{props.gig ? props.gig.name : ""}</span>
          <span className={classes.date}>
            {format(new Date(props.gig.startDatetime), "PP", { locale: enGB })}
          </span>
        </div>
      </Button>
    </TableCell>
  );
}

function MultiGigRow(props: {
  seat: Seat;
  gigs: Gig[];
  playerManagement: PlayerManagement;
}) {
  const classes = useStyles();
  const bandId = useAppSelector(selectBandId);
  const filterCriteria = useAppSelector(selectFilterState);

  const managerAvailabilityInfo = useAppSelector(
    selectManagerAvailabilities(bandId, filterCriteria),
  );
  const userId = useMemo(
    () =>
      getUserForSeat(
        props.gigs,
        props.seat.id,
        managerAvailabilityInfo.byGigId,
      ),
    [props.gigs, props.seat.id, managerAvailabilityInfo],
  );
  const count = getCountForSeat(
    props.gigs,
    props.seat.id,
    props.playerManagement,
    managerAvailabilityInfo.byGigId,
  );

  const users = useAppSelector(selectAllUsers(bandId));

  const user = byId(users)[userId];

  return (
    <TableRow>
      <TableCell className={classes.seatCell}>{props.seat.name}</TableCell>
      <TableCell className={classes.otherCell}>
        {userId === NO_USER
          ? ""
          : userId === MULTIPLE_USERS
            ? "(Multiple)"
            : fullName(user)}
      </TableCell>
      <TableCell className={classes.otherCell}>{count}</TableCell>
      {props.gigs.map((gig) => (
        <MultiGigCell
          key={gig.id}
          seatId={props.seat.id}
          gig={gig}
          multi={userId === MULTIPLE_USERS}
          playerManagement={props.playerManagement}
        />
      ))}
    </TableRow>
  );
}

const selectManagerAvailabilitiesResult = (
  bandId: number | undefined,
  filterCriteria: FilterCriteria,
) =>
  apiSlice.endpoints.getManagerAvailabilities.select({
    bandId: bandId === undefined ? 0 : bandId,
    filterCriteria,
  });

const emptyManagerAvailability = { byId: [], byGigId: [] };

const selectManagerAvailabilities = (
  bandId: number | undefined,
  filterCriteria: FilterCriteria,
) =>
  createSelector(
    selectManagerAvailabilitiesResult(bandId, filterCriteria),
    (usersResult) => usersResult?.data ?? emptyManagerAvailability,
  );

const selectUsersResult = (bandId: number | undefined) =>
  apiSlice.endpoints.getUsers.select(bandId === undefined ? 0 : bandId);

const emptyUsers: User[] = [];

const selectAllUsers = (bandId: number | undefined) =>
  createSelector(
    selectUsersResult(bandId),
    (usersResult) => usersResult?.data ?? emptyUsers,
  );

function MultiGigCell(props: {
  seatId: number;
  gig: Gig;
  multi: boolean;
  playerManagement: PlayerManagement;
}) {
  const classes = useStyles();
  const { selected, selectById, getModifiedAvailabilitySummary, isModified } =
    props.playerManagement;

  const bandId = useAppSelector(selectBandId);
  const filterCriteria = useAppSelector(selectFilterState);

  const managerAvailabilityInfo = useAppSelector(
    selectManagerAvailabilities(bandId, filterCriteria),
  );
  const availability = gigAvailability(
    props.seatId,
    managerAvailabilityInfo.byGigId[props.gig.id] || [],
  );

  const users = useAppSelector(selectAllUsers(bandId));
  const user = availability ? byId(users)[availability.user] : undefined;

  if (availability == null) {
    return <TableCell />;
  }
  const name = fullName(user);
  const availabilitySummary = getModifiedAvailabilitySummary(availability);

  let className = classes.otherCell;
  if (isModified(availability) && selected[availability.id]) {
    className += " " + classes.selectedAndModifiedCell;
  } else if (isModified(availability)) {
    className += " " + classes.modifiedCell;
  } else if (selected[availability.id]) {
    className += " " + classes.selectedCell;
  }

  return (
    <TableCell
      align={"center"}
      className={className}
      onClick={() => selectById(availability.id, !selected[availability.id])}
    >
      {availabilitySummary ? (
        <AvailabilitySummaryIcon
          availabilitySummary={availabilitySummary}
          gigCollecting={props.gig.collecting}
          player={false}
          dim={true}
          tooltip={(summary) =>
            props.multi ? `${summary} (${name})` : summary
          }
        />
      ) : null}
    </TableCell>
  );
}

const NO_USER = -1;
const MULTIPLE_USERS = -2;

/**
 * Returns the id of the user playing the given gigs on the given seat, or NO_USER if no user is playing on that seat
 * for any of the gigs, or MULTIPLE_USERS if more than one user is playing on that seat for those gigs. Deps are
 * filtered out.
 */
function getUserForSeat(
  gigs: Gig[],
  seatId: number,
  availabilitiesByGigId: { [gigId: number]: Availability[] },
): number {
  return (
    gigs
      // For each gig, look up the userIds playing on that seat. This could be empty if the data's not loaded yet
      // or nobody is playing on that seat, otherwise, most of the time, it will be a single player because we
      // filter out deps. It could be multiple players if core band members have changed.
      .flatMap((gig) => {
        return (availabilitiesByGigId[gig.id] || [])
          .filter(
            (availability) => availability.seat === seatId && !availability.dep,
          )
          .map((availability) => availability.user);
      })
      // Now we have an array of user ids playing on the seat for all the gigs we're displaying. Collapse to
      // either a single user id, -1 if nobody is playing on that seat for any gigs (or more likely, the data
      // isn't loaded yet), and -2 if multiple players are playing on that seat.
      .reduce((prev, current) => {
        if (prev !== NO_USER && prev !== current) {
          return MULTIPLE_USERS;
        } else {
          return current;
        }
      }, NO_USER)
  );
}

/**
 * Returns the number of times the given seat (and therefore player if the core band member stays the same) is required
 * for the given gigs, excluding deps.
 */
function getCountForSeat(
  gigs: Gig[],
  seatId: number,
  playerManagement: PlayerManagement,
  availabilitiesByGigId: { [gigId: number]: Availability[] },
): number {
  const { getModifiedAvailabilitySummary } = playerManagement;
  return gigs.flatMap((gig) => {
    return (availabilitiesByGigId[gig.id] || [])
      .filter(
        (availability) => availability.seat === seatId && !availability.dep,
      )
      .filter(
        (availability) =>
          getModifiedAvailabilitySummary(availability).required ===
          RequiredStatus.REQUIRED,
      );
  }).length;
}

/**
 * Returns an Availability for a given gig and seat, or null if no player is playing on that seat. Deps are filtered
 * out.
 */
function gigAvailability(
  seatId: number,
  availabilities: Availability[],
): Availability | null {
  return (
    availabilities.filter(
      (availability) => availability.seat === seatId && !availability.dep,
    )[0] || null
  );
}
