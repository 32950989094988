import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { PlayerAvailabilitySummary } from "dtos/PlayerAvailabilitySummary";
import { Gig, UnsavedGig } from "dtos/Gig";
import { Availability, UnsavedAvailability } from "dtos/Availability";
import { FullUser, User } from "dtos/User";
import { RootState } from "app/rootReducer";
import { AvailabilityEvent } from "dtos/AvailabilityEvent";
import { Instrument } from "dtos/Instrument";
import { Seat } from "dtos/Seat";
import { Player } from "dtos/Player";
import { Dep, UnsavedDep } from "dtos/Dep";
import { Tag, UnsavedTag } from "dtos/Tag";
import { byId, groupBy, keys, mapValues } from "utils/utils";
import { Invitation } from "dtos/Invitation";
import { Rank } from "dtos/Rank";
import { Role } from "dtos/Role";
import { Noticeboard } from "dtos/Noticeboard";
import { EventInstance, EventInstanceForGig } from "dtos/EventInstance";
import {
  FilterCriteria,
  paramsFromFilterCriteria,
} from "features/filter/filterCriteria";
import { PlayerMove } from "api/playerMove";

type ServerResponse<T, PropertyName extends string> = {
  _embedded: undefined | { [P in PropertyName]: T[] };
};

/**
 * Handles the responses of requests for multiple entities. If there are no entities, _embedded doesn't exist (rather
 * than being an empty list).
 */
function handleResponse<T, PropertyName extends string>(
  response: ServerResponse<T, PropertyName>,
  name: PropertyName,
): T[] {
  if (
    response._embedded === undefined ||
    response._embedded[name] === undefined
  ) {
    return [];
  } else {
    return response._embedded[name];
  }
}

/**
 * Add authorization header to all requests.
 */
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL || "/api",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  baseQuery,
  tagTypes: [
    "User",
    "Tag",
    "Player",
    "Dep",
    "Gig",
    "EventInstance",
    "Availability",
    "Invitation",
    "AvailabilityRequestDatetime",
  ],

  endpoints: (builder) => ({
    getRoles: builder.query<Role[], void>({
      query: () => "/roles",
      transformResponse: (rawResult: ServerResponse<Role, "roles">) =>
        handleResponse(rawResult, "roles"),
    }),

    getMe: builder.query<FullUser, void>({
      query: () => "/users/me",
      providesTags: (result) =>
        result ? [{ type: "User", id: result.id }] : [],
    }),

    editMe: builder.mutation<
      FullUser,
      {
        givenName: string;
        familyName: string;
        emailAddress: string;
      }
    >({
      query: (update) => ({
        url: "/users/me",
        method: "PUT",
        body: update,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "User", id: result.id }] : [],
    }),

    deleteMe: builder.mutation<void, void>({
      query: () => ({
        url: "/users/me",
        method: "DELETE",
      }),
    }),

    getUser: builder.query<User, number>({
      query: (userId) => `/users/${userId}`,
      providesTags: (result, error, userId) => [{ type: "User", id: userId }],
    }),

    getUsers: builder.query<User[], number>({
      query: (bandId) => `/bands/${bandId}/users`,
      transformResponse: (rawResult: ServerResponse<User, "minimalUsers">) =>
        handleResponse(rawResult, "minimalUsers"),
      providesTags: (result: User[] = []) => [
        ...result.map(({ id }) => ({ type: "User" as const, id })),
        { type: "User", id: "LIST" },
      ],
    }),

    addUser: builder.mutation<
      User,
      {
        givenName: string;
        familyName: string;
      }
    >({
      query: (unsavedUser) => ({
        url: "/users",
        method: "POST",
        body: unsavedUser,
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),

    editUser: builder.mutation<
      User,
      {
        id: number;
        givenName: string;
        familyName: string;
      }
    >({
      query: (update) => ({
        url: `/users/${update.id}`,
        method: "PUT",
        body: update,
      }),
      invalidatesTags: (result, error, update) => [
        { type: "User", id: update.id },
      ],
    }),

    joinBand: builder.mutation<Player, string>({
      query: (token) => ({
        url: `/bands/join/${token}`,
        method: "POST",
      }),
      invalidatesTags: [
        { type: "Tag", id: "LIST" },
        { type: "Player", id: "LIST" },
        { type: "Dep", id: "LIST" },
        { type: "Gig", id: "LIST" },
        { type: "EventInstance", id: "LIST" },
        { type: "Availability", id: "LIST" },
      ],
    }),

    getInstruments: builder.query<Instrument[], void>({
      query: () => "/instruments",
      transformResponse: (
        rawResult: ServerResponse<Instrument, "instruments">,
      ) => handleResponse(rawResult, "instruments"),
    }),

    getTags: builder.query<Tag[], number>({
      query: (bandId) => `/bands/${bandId}/tags`,
      transformResponse: (rawResult: ServerResponse<Tag, "tags">) =>
        handleResponse(rawResult, "tags"),
      providesTags: (result: Tag[] = []) => [
        ...result.map(({ id }) => ({ type: "Tag" as const, id })),
        { type: "Tag", id: "LIST" },
      ],
    }),

    addTag: builder.mutation<Tag, UnsavedTag>({
      query: (unsavedTag) => ({
        url: "/tags",
        method: "POST",
        body: unsavedTag,
      }),
      invalidatesTags: [{ type: "Tag", id: "LIST" }],
    }),

    getSeats: builder.query<Seat[], number>({
      query: (bandId) => `/bands/${bandId}/seats`,
      transformResponse: (rawResult: ServerResponse<Seat, "seats">) =>
        handleResponse(rawResult, "seats"),
    }),

    getPlayers: builder.query<Player[], number>({
      query: (bandId) => `/bands/${bandId}/players`,
      transformResponse: (rawResult: ServerResponse<Player, "players">) =>
        handleResponse(rawResult, "players"),
      providesTags: (result: Player[] = []) => [
        ...result.map(({ id }) => ({ type: "Player" as const, id })),
        { type: "Player", id: "LIST" },
      ],
    }),

    getMyPlayers: builder.query<Player[], void>({
      query: () => "/players/me",
      transformResponse: (rawResult: ServerResponse<Player, "players">) =>
        handleResponse(rawResult, "players"),
      providesTags: (result: Player[] = []) => [
        ...result.map(({ id }) => ({ type: "Player" as const, id })),
        { type: "Player", id: "LIST" },
      ],
    }),

    editPlayer: builder.mutation<Player, Player>({
      query: (player) => ({
        url: `/players/${player.id}`,
        method: "PUT",
        body: player,
      }),
      invalidatesTags: (result, error, player) => [
        { type: "Player", id: player.id },
      ],
    }),

    deletePlayer: builder.mutation<number, number>({
      query: (playerId) => ({
        url: `/players/${playerId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, playerId) => [
        { type: "Player", id: playerId },
        { type: "Availability", id: "LIST" },
      ],
    }),

    updatePlayerSeat: builder.mutation<
      Player,
      { player: Player; updateFuture: boolean }
    >({
      query: ({ player, updateFuture }) => ({
        url: `/players/${player.id}/seat?updateFuture=${updateFuture}`,
        method: "PUT",
        body: player,
      }),
      invalidatesTags: (result, error, { player }) => [
        { type: "Player", id: player.id },
        { type: "Availability", id: "LIST" },
      ],
    }),

    getDeps: builder.query<Dep[], number>({
      query: (bandId) => `/bands/${bandId}/deps`,
      transformResponse: (rawResult: ServerResponse<Dep, "deps">) =>
        handleResponse(rawResult, "deps"),
      providesTags: (result: Dep[] = []) => [
        ...result.map(({ id }) => ({ type: "Dep" as const, id })),
        { type: "Dep", id: "LIST" },
      ],
    }),

    addDep: builder.mutation<Dep, UnsavedDep>({
      query: (unsavedDep) => ({
        url: "/deps",
        method: "POST",
        body: unsavedDep,
      }),
      invalidatesTags: [
        { type: "Dep", id: "LIST" },
        { type: "User", id: "LIST" },
      ],
    }),

    editDep: builder.mutation<Dep, Dep>({
      query: (dep) => ({
        url: `/deps/${dep.id}`,
        method: "PUT",
        body: dep,
      }),
      invalidatesTags: (result, error, dep) => [
        { type: "Dep", id: dep.id },
        { type: "User", id: dep.user },
      ],
    }),

    getGigs: builder.query<
      Gig[],
      {
        bandId: number;
        filterCriteria: FilterCriteria;
      }
    >({
      query: (gigsRequest) => {
        return {
          url: `/bands/${gigsRequest.bandId}/gigs`,
          params: paramsFromFilterCriteria(gigsRequest.filterCriteria),
        };
      },
      transformResponse: (rawResult: ServerResponse<Gig, "gigs">) =>
        handleResponse(rawResult, "gigs"),
      providesTags: (result: Gig[] = []) => [
        ...result.map(({ id }) => ({ type: "Gig" as const, id })),
        { type: "Gig", id: "LIST" },
      ],
    }),

    getGig: builder.query<Gig, number>({
      query: (gigId) => `/gigs/${gigId}`,
      providesTags: (result, error, gigId) => [{ type: "Gig", id: gigId }],
    }),

    addGig: builder.mutation<Gig, UnsavedGig>({
      async queryFn(unsavedGig, _queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: `/gigs`,
          method: "POST",
          body: unsavedGig,
        });
        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }
        const gig = result.data as Gig;
        if (!gig.rehearsal) {
          // Add default availabilities to the gig. If this is a rehearsal, availabilities are implicit, so don't do
          // this.
          await fetchWithBQ({
            url: `/gigs/${gig.id}/availabilities/add-default`,
            method: "POST",
          });
        }
        return { data: gig };
      },
      invalidatesTags: [
        { type: "Gig", id: "LIST" },
        { type: "Availability", id: "LIST" },
        { type: "EventInstance", id: "LIST" },
      ],
    }),

    editGig: builder.mutation<Gig, Gig>({
      query: (gig) => ({
        url: `/gigs/${gig.id}`,
        method: "PUT",
        body: gig,
      }),
      invalidatesTags: (result, error, gig) => [
        { type: "Gig", id: gig.id },
        { type: "Availability", id: "LIST" },
      ],
    }),

    deleteGig: builder.mutation<number, number>({
      query: (gigId) => ({
        url: `/gigs/${gigId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, gigId) => [{ type: "Gig", id: gigId }],
    }),

    getEventInstances: builder.query<
      EventInstanceForGig[],
      {
        bandId: number;
        filterCriteria: FilterCriteria;
      }
    >({
      query: (request) => {
        return {
          url: `/bands/${request.bandId}/event-instances`,
          params: paramsFromFilterCriteria(request.filterCriteria),
        };
      },
      transformResponse: (
        rawResult: ServerResponse<EventInstanceForGig, "eventInstanceForGigs">,
      ) => handleResponse(rawResult, "eventInstanceForGigs"),
      providesTags: (result: EventInstanceForGig[] = []) => [
        ...result.map(({ gig, eventInstance }) => ({
          type: "EventInstance" as const,
          id: `${gig}/${eventInstance.startDatetime}`,
        })),
        { type: "EventInstance", id: "LIST" },
      ],
    }),

    editEventInstance: builder.mutation<
      EventInstance,
      { modifyStartDatetime: number; eventInstanceForGig: EventInstanceForGig }
    >({
      query: (details) => ({
        url: `/gigs/${details.eventInstanceForGig.gig}/${details.modifyStartDatetime}`,
        method: "PUT",
        body: details.eventInstanceForGig.eventInstance,
      }),
      invalidatesTags: (result, error, details) => [
        { type: "Gig", id: details.eventInstanceForGig.gig },
        { type: "Availability", id: "LIST" },
        {
          type: "EventInstance",
          id: `${details.eventInstanceForGig.gig}/${details.eventInstanceForGig.eventInstance.startDatetime}`,
        },
        { type: "EventInstance", id: "LIST" },
      ],
    }),

    deleteEventInstance: builder.mutation<EventInstance, EventInstanceForGig>({
      query: (eventInstanceForGig) => ({
        url: `/gigs/${eventInstanceForGig.gig}/${eventInstanceForGig.eventInstance.startDatetime}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, eventInstanceForGig) => [
        { type: "Gig", id: eventInstanceForGig.gig },
        { type: "Availability", id: "LIST" },
        {
          type: "EventInstance",
          id: `${eventInstanceForGig.gig}/${eventInstanceForGig.eventInstance.startDatetime}`,
        },
        { type: "EventInstance", id: "LIST" },
      ],
    }),

    setCollecting: builder.mutation<
      Gig,
      { gigId: number; collecting: boolean; immediately: boolean }
    >({
      query: ({ gigId, collecting, immediately }) => ({
        url: `/gigs/${gigId}/set-collecting`,
        method: "POST",
        params: {
          collecting,
          immediately,
        },
      }),
      invalidatesTags: (result, error, { gigId }) => [
        { type: "Gig", id: gigId },
        { type: "AvailabilityRequestDatetime", id: gigId },
      ],
    }),

    getAvailabilityRequestDatetime: builder.query<number, number>({
      query: (gigId) => `/gigs/${gigId}/availability-request-time`,
      providesTags: (result, error, arg) => [
        { type: "AvailabilityRequestDatetime", id: arg },
      ],
    }),

    getEarliestGigDatetime: builder.query<number, number>({
      query: (userId) => `/users/${userId}/earliest-gig`,
    }),

    getRanking: builder.query<Rank | null, number>({
      query: (bandId) => `/bands/${bandId}/ranking`,
    }),

    getPlayerAvailabilities: builder.query<
      Availability[],
      {
        userId: number;
        filterCriteria: FilterCriteria;
      }
    >({
      query: (playerAvailabilitiesRequest) => {
        return {
          url: `/users/${playerAvailabilitiesRequest.userId}/availabilities`,
          params: paramsFromFilterCriteria(
            playerAvailabilitiesRequest.filterCriteria,
          ),
        };
      },
      transformResponse: (
        rawResult: ServerResponse<Availability, "availabilities">,
      ) => handleResponse(rawResult, "availabilities"),
      providesTags: (result: Availability[] = []) => [
        ...result.map(({ id }) => ({ type: "Availability" as const, id })),
        { type: "Availability", id: "LIST" },
      ],
    }),

    getAvailabilitySummaries: builder.query<
      PlayerAvailabilitySummary[],
      { gigId: number; startDatetime: number }
    >({
      query: ({ gigId, startDatetime }) =>
        `/gigs/${gigId}/${startDatetime}/availabilitySummaries`,
      transformResponse: (
        rawResult: ServerResponse<
          PlayerAvailabilitySummary,
          "availabilitySummaries"
        >,
      ) => handleResponse(rawResult, "availabilitySummaries"),
    }),

    getManagerAvailabilities: builder.query<
      ManagerAvailabilityInfo,
      {
        bandId: number;
        filterCriteria: FilterCriteria;
      }
    >({
      query: (managerAvailabilitiesRequest) => {
        return {
          url: `/bands/${managerAvailabilitiesRequest.bandId}/availabilities`,
          params: paramsFromFilterCriteria(
            managerAvailabilitiesRequest.filterCriteria,
          ),
        };
      },
      transformResponse: (
        rawResult: ServerResponse<Availability, "availabilities">,
      ): ManagerAvailabilityInfo => {
        const availabilities = handleResponse(rawResult, "availabilities");
        return {
          byId: byId(availabilities),
          byGigId: groupBy(availabilities, (a) => a.gig),
          byGigIdAndStartTime: mapValues(
            groupBy(availabilities, (a) => a.gig),
            (availabilities) => groupBy(availabilities, (a) => a.startDatetime),
          ),
        };
      },
      providesTags: (
        result = {
          byId: {},
          byGigId: {},
          byGigIdAndStartTime: {},
        },
      ) => [
        ...keys(result.byId).map((id) => ({
          type: "Availability" as const,
          id,
        })),
        { type: "Availability", id: "LIST" },
      ],
    }),

    getGigAvailabilities: builder.query<Availability[], number>({
      query: (gigId) => `/gigs/${gigId}/availabilities`,
      transformResponse: (
        rawResult: ServerResponse<Availability, "availabilities">,
      ) => handleResponse(rawResult, "availabilities"),
      providesTags: (result: Availability[] = []) => [
        ...result.map(({ id }) => ({ type: "Availability" as const, id })),
        { type: "Availability", id: "LIST" },
      ],
    }),

    addAvailability: builder.mutation<Availability, UnsavedAvailability>({
      query: (unsavedAvailability) => ({
        url: "/availabilities",
        method: "POST",
        body: unsavedAvailability,
      }),
      invalidatesTags: [{ type: "Availability", id: "LIST" }],
    }),

    moveUserToSeat: builder.mutation<void, PlayerMove>({
      query: (playerMove) => ({
        url: `/gigs/${playerMove.gig}/${playerMove.startDatetime}/move/${playerMove.user}`,
        method: "PUT",
        body: { targetSeat: playerMove.targetSeat },
      }),
      invalidatesTags: [{ type: "Availability", id: "LIST" }],
    }),

    deleteAvailability: builder.mutation<number, number>({
      query: (availabilityId) => ({
        url: `/availabilities/${availabilityId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, availabilityId) => [
        { type: "Availability", id: availabilityId },
      ],
    }),

    // TODO: Just use the multiple one?
    addNewAvailabilityEvent: builder.mutation<
      Availability,
      { availabilityId: number; event: AvailabilityEvent }
    >({
      query: ({ availabilityId, event }) => ({
        url: `/availabilities/${availabilityId}/events`,
        method: "POST",
        body: event,
      }),
      invalidatesTags: (result, error, { availabilityId }) => [
        { type: "Availability", id: availabilityId },
      ],
    }),

    addNewAvailabilityEvents: builder.mutation<
      Availability[],
      { [availabilityId: number]: AvailabilityEvent }
    >({
      query: (events) => ({
        url: `/availabilities/events`,
        method: "POST",
        body: events,
      }),
      invalidatesTags: (result, error, events) => [
        ...keys(events).map((id) => ({ type: "Availability" as const, id })),
      ],
    }),

    addNewAvailabilityEventForAssumedAvailability: builder.mutation<
      Availability,
      {
        gigId: number;
        startDatetime: number;
        event: AvailabilityEvent;
      }
    >({
      query: ({ gigId, startDatetime, event }) => ({
        url: `/gigs/${gigId}/${startDatetime}/events`,
        method: "POST",
        body: event,
      }),
      invalidatesTags: [{ type: "Availability", id: "LIST" }],
    }),

    getInvitations: builder.query<Invitation[], number>({
      query: (bandId) => `/bands/${bandId}/invitations`,
      transformResponse: (
        rawResult: ServerResponse<Invitation, "invitations">,
      ) => handleResponse(rawResult, "invitations"),
      providesTags: (result: Invitation[] = []) => [
        ...result.map(({ id }) => ({ type: "Invitation" as const, id })),
        { type: "Invitation", id: "LIST" },
      ],
    }),

    addInvitation: builder.mutation<string, { bandId: number; seatId: number }>(
      {
        query: ({ bandId, seatId }) => ({
          url: `/bands/${bandId}/invitation?seatId=${seatId}`,
          method: "POST",
        }),
        transformResponse: (rawResult: { url: string }) => rawResult.url,
        invalidatesTags: [{ type: "Invitation", id: "LIST" }],
      },
    ),

    deleteInvitation: builder.mutation<number, number>({
      query: (invitationId) => ({
        url: `/invitations/${invitationId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, invitationId) => [
        { type: "Invitation", id: invitationId },
      ],
    }),

    getLastCalendarRequest: builder.query<number | null, number>({
      query: (bandId) => `/bands/${bandId}/calendar/last-request`,
      transformResponse: (lastRequest: { datetime: number | null }) =>
        lastRequest.datetime,
    }),

    getNoticeboard: builder.query<Noticeboard, number>({
      query: (bandId) => `/bands/${bandId}/noticeboard`,
    }),
  }),
});

export interface ManagerAvailabilityInfo {
  byId: { [id: number]: Availability };
  byGigId: { [gigId: number]: Availability[] };
  byGigIdAndStartTime: {
    [gigId: number]: { [startTime: number]: Availability[] };
  };
}

export const getAvailabilitiesByGigIdAndStartTime = (
  managerAvailabilityInfo: ManagerAvailabilityInfo,
  gigId: number,
  startDatetime: number,
): Availability[] =>
  (managerAvailabilityInfo.byGigIdAndStartTime[gigId] ?? {})[startDatetime] ??
  [];

export const {
  useGetRolesQuery,
  useGetMeQuery,
  useEditMeMutation,
  useDeleteMeMutation,
  useGetUserQuery,
  useGetUsersQuery,
  useAddUserMutation,
  useEditUserMutation,
  useJoinBandMutation,
  useGetInstrumentsQuery,
  useGetTagsQuery,
  useAddTagMutation,
  useGetSeatsQuery,
  useGetPlayersQuery,
  useGetMyPlayersQuery,
  useEditPlayerMutation,
  useDeletePlayerMutation,
  useUpdatePlayerSeatMutation,
  useGetDepsQuery,
  useAddDepMutation,
  useEditDepMutation,
  useGetGigsQuery,
  useGetGigQuery,
  useAddGigMutation,
  useEditGigMutation,
  useDeleteGigMutation,
  /**
   * Returns instances of rehearsals matching the given filtering criteria, ordered by instance start time.
   */
  useGetEventInstancesQuery,
  /**
   * Edits a particular instance of a repeating event. If a new recurrence deviation is required, one will be created.
   * If this attempts to modify an event already caused by a recurrence deviation, that deviation will be updated.
   *
   * @param modifyStartDatetime Date/time of the event being modified. For an event whose date/time already depends on
   *        a recurrence deviation, this is the date/time of the result of that deviation.
   * @param eventInstance The new event instance.
   */
  useEditEventInstanceMutation,
  /**
   * Deletes a particular instance of a repeating event. Either a recurrence deviation is added that deletes the event,
   * or, if one already exists, it is updated to delete the event.
   *
   * @param eventInstance The event instance to delete.
   */
  useDeleteEventInstanceMutation,
  useSetCollectingMutation,
  useGetAvailabilityRequestDatetimeQuery,
  useGetEarliestGigDatetimeQuery,
  useGetRankingQuery,
  useGetPlayerAvailabilitiesQuery,
  useGetAvailabilitySummariesQuery,
  useGetManagerAvailabilitiesQuery,
  useGetGigAvailabilitiesQuery,
  useAddAvailabilityMutation,
  /**
   * Moves a user to a new seat for a given gig or rehearsal. Updates or creates a new Availability as necessary in
   * order to do this.
   */
  useMoveUserToSeatMutation,
  useDeleteAvailabilityMutation,
  useAddNewAvailabilityEventMutation,
  useAddNewAvailabilityEventsMutation,
  useAddNewAvailabilityEventForAssumedAvailabilityMutation,
  useGetInvitationsQuery,
  useAddInvitationMutation,
  useDeleteInvitationMutation,
  useGetLastCalendarRequestQuery,
  useGetNoticeboardQuery,
} = apiSlice;
