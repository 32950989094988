import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { Tag } from "dtos/Tag";
import { RawTagChip, TagChip } from "features/tags/TagChip";
import React, { ChangeEvent, FormEvent, useMemo, useState } from "react";
import { selectBandId } from "features/users/usersSlice";
import {
  amber,
  blue,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from "@mui/material/colors";
import { useAppSelector } from "app/store";
import { useAddTagMutation, useGetTagsQuery } from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";

export interface TagAutocompleteProps {
  tags: Tag[];
  setTags: (tags: Tag[]) => void;
  addNew: boolean;
}

interface TagOptionType extends Tag {
  inputValue?: string;
}

const filter = createFilterOptions<TagOptionType>();

const colourChoices1: string[] = [
  red[500],
  pink[500],
  purple[500],
  deepPurple[500],
  indigo[500],
  blue[500],
  lightBlue[500],
  cyan[500],
  teal[500],
  green[500],
  lightGreen[500],
  lime[500],
  yellow[500],
  amber[500],
  orange[500],
  deepOrange[500],
  brown[500],
  grey[500],
];

/**
 * Lets the user select tags by autocompleting them as they type and/or letting them choose them from a dropdown, as
 * well as adding new tags by just typing something that doesn't exist yet.
 */
export function TagAutocomplete(props: TagAutocompleteProps) {
  const { tags, setTags, addNew } = props;
  const bandId = useAppSelector(selectBandId);
  const { data: allTags = [] } = useGetTagsQuery(bandId ?? skipToken);
  const [addTag] = useAddTagMutation();
  const sortedTags = useMemo(
    () => allTags.slice().sort((a, b) => a.name.localeCompare(b.name)),
    [allTags],
  );

  const autocomplete = (
    <Autocomplete
      multiple
      options={sortedTags as TagOptionType[]}
      getOptionLabel={(option) => option.name}
      value={tags as TagOptionType[]}
      onChange={(_, newValue) => {
        if (newValue && newValue.filter((v) => v.inputValue).length > 0) {
          toggleNewTagDialogueOpen(true);
          setNewTagName(
            newValue.filter((v) => v.inputValue)[0].inputValue || "",
          );
        } else {
          setTags(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label="Tags" />
      )}
      renderTags={(value: readonly Tag[], getTagProps) =>
        value.map((option: Tag, index: number) => (
          // eslint-disable-next-line react/jsx-key
          <TagChip tag={option} {...getTagProps({ index })} />
        ))
      }
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (
          addNew &&
          params.inputValue !== "" &&
          !invalidTagName(params.inputValue)
        ) {
          filtered.push({
            inputValue: params.inputValue,
            name: `Add "${params.inputValue}"...`,
            band: 0,
            colour: "",
            id: 0,
          });
        }

        return filtered;
      }}
      autoHighlight={true}
    />
  );

  const [newTagDialogueOpen, toggleNewTagDialogueOpen] = useState(false);
  const [newTagName, setNewTagName] = useState("");
  const [newTagColour, setNewTagColour] = useState(colourChoices1[0]);

  const trimmedTagName = newTagName.trim();

  const handleCloseNewTagDialogue = () => {
    setNewTagName("");
    toggleNewTagDialogueOpen(false);
  };

  const handleNewTagSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (bandId !== undefined) {
      const newTag = await addTag({
        name: trimmedTagName,
        band: bandId,
        colour: newTagColour.substring(1), // Strip off the hash
      }).unwrap();
      setTags([...tags, newTag as unknown as Tag]);
      handleCloseNewTagDialogue();
    }
  };

  const colourRadioControlProps = (item: string) => ({
    checked: newTagColour === item,
    onChange: (event: ChangeEvent<HTMLInputElement>) => {
      setNewTagColour(event.target.value);
    },
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const invalidTagName = (name: string) => {
    const trimmedName = name.trim();
    return (
      trimmedName.length === 0 ||
      sortedTags.find(
        (tag) => tag.name.toLowerCase() === trimmedName.toLowerCase(),
      ) !== undefined
    );
  };

  const addTagDialogue = (
    <Dialog open={newTagDialogueOpen} onClose={handleCloseNewTagDialogue}>
      <form onSubmit={handleNewTagSubmit}>
        <DialogTitle>Add new tag</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={newTagName}
              onChange={(event) => setNewTagName(event.target.value)}
              label="Name"
              type="text"
              variant="standard"
            />
            <div>
              <FormLabel component="legend">Colour</FormLabel>
              <RadioGroup
                aria-label="colour"
                defaultValue=""
                name="colour-group"
              >
                <Stack>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxWidth: "380px",
                    }}
                  >
                    {colourChoices1.map((colour) => (
                      <Radio
                        key={colour}
                        {...colourRadioControlProps(colour)}
                        sx={{
                          flexBasis: "11%",
                          color: colour,
                          "&.Mui-checked": {
                            color: colour,
                          },
                        }}
                      />
                    ))}
                  </Box>
                </Stack>
              </RadioGroup>
            </div>
            <div>
              <RawTagChip
                name={newTagName}
                chipColour={newTagColour.substring(1)}
              />
            </div>
            <div>
              <Stack spacing={1}>
                <div>Existing tags: </div>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {sortedTags.map((tag) => (
                    <TagChip key={tag.id} tag={tag} />
                  ))}
                </Box>
              </Stack>
            </div>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewTagDialogue}>Cancel</Button>
          <Button type="submit" disabled={invalidTagName(trimmedTagName)}>
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return (
    <>
      {autocomplete}
      {addTagDialogue}
    </>
  );
}
