import React, { useState } from "react";
import { AppBar, Box, IconButton, Theme, Toolbar } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import CssBaseline from "@mui/material/CssBaseline";
import { User } from "dtos/User";
import { useAuth0 } from "@auth0/auth0-react";
import { Page, UserMenu } from "features/misc/UserMenu";
import { selectMe } from "features/users/usersSlice";
import { useAppSelector } from "app/store";
import { Outlet } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { ListItemLink } from "features/misc/ListItemLink";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Loop from "@mui/icons-material/Loop";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    homeIcon: {
      color: "#fff",
      marginLeft: "-20px",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
    },
    body: {
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(3),
      },
    },
  }),
);

/**
 * Players' UI.
 */
export function PlayerUI() {
  const classes = useStyles();
  const me: User | null = useAppSelector(selectMe);
  const { logout } = useAuth0();
  const [open, setOpen] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(open);
    };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <IconButton
            aria-label="home"
            edge="start"
            className={classes.homeIcon}
            onClick={toggleDrawer(true)}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <UserMenu me={me} page={Page.PLAYER} logout={logout} />
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItemLink
              primary="Dashboard"
              to="/player/dashboard"
              icon={<DashboardIcon />}
            />
            <ListItemLink
              primary="Rehearsals"
              to="/player/rehearsals"
              icon={<Loop />}
            />
            <ListItemLink
              primary="Gig list"
              to="/player/gigs"
              icon={<QueueMusicIcon />}
            />
            <ListItemLink
              primary="Band noticeboard"
              to="/player/noticeboard"
              icon={<ContentPasteIcon />}
            />
            <ListItemLink
              primary="Calendar"
              to="/player/calendar"
              icon={<CalendarMonthIcon />}
            />
          </List>
        </Box>
      </Drawer>

      <div className={classes.body}>
        <Outlet />
      </div>
    </div>
  );
}
