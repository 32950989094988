import { endOfYear, startOfYear, subDays } from "date-fns";
import { Gig } from "dtos/Gig";

export interface FilterCriteria {
  year?: number | "future" | "last90days" | "yearSoFar";
  tags?: number[];
}

/**
 * Returns a function that will filter gigs according to the given filter state.
 */
export function filterFromCriteria(
  filterCriteria: FilterCriteria,
): (gig: Gig) => boolean {
  const [start, end] = getStartAndEnd(filterCriteria);
  return (gig: Gig) => {
    const dateOk =
      (start === undefined || start <= gig.startDatetime) &&
      (end === undefined || gig.startDatetime <= end);
    const tagsOk = (filterCriteria.tags ?? []).every((tagId) =>
      gig.tags.map((gt) => gt.tag).includes(tagId),
    );
    return dateOk && tagsOk;
  };
}

/**
 * Returns the parameters to send to the backend to fetch content according ot the given filter criteria.
 */
export function paramsFromFilterCriteria(
  filterCriteria: FilterCriteria,
): URLSearchParams {
  const [start, end] = getStartAndEnd(filterCriteria);
  const params = new URLSearchParams();
  if (start) {
    params.append("start", start.toString());
  }
  if (end) {
    params.append("end", end.toString());
  }
  (filterCriteria.tags ?? []).forEach((tagId) => {
    params.append("tag", tagId.toString());
  });
  return params;
}

/**
 * Returns a time range in milliseconds since epoch based on the given filter criteria.
 */
function getStartAndEnd(
  filterCriteria: FilterCriteria,
): [number | undefined, number | undefined] {
  let start: number | undefined;
  let end: number | undefined;

  if (filterCriteria.year === undefined) {
    start = undefined;
    end = undefined;
  } else if (filterCriteria.year === "future") {
    start = subDays(new Date(), 1).getTime();
    end = undefined;
  } else if (filterCriteria.year === "last90days") {
    const now = new Date();
    start = subDays(now, 90).getTime();
    end = now.getTime();
  } else if (filterCriteria.year === "yearSoFar") {
    const now = new Date();
    start = startOfYear(new Date()).getTime();
    end = now.getTime();
  } else {
    const date = new Date(filterCriteria.year, 1, 1);
    start = startOfYear(date).getTime();
    end = endOfYear(date).getTime();
  }

  return [start, end];
}
