import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "auth/usePermissions";

/**
 * The settings menu that gives access to dep management and player invitations.
 */
export function SettingsMenu() {
  const navigate = useNavigate();
  const permissions = usePermissions();

  const canViewPlayersUi = permissions.hasPermission("RETRIEVE_PLAYER");
  const canViewDepsUi = permissions.hasPermission("RETRIEVE_DEP");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="Settings menu"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        <SettingsIcon />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {canViewPlayersUi && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              navigate("/manager/settings/players");
            }}
          >
            Manage players
          </MenuItem>
        )}
        {canViewDepsUi && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              navigate("/manager/deps");
            }}
          >
            Manage deps
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
