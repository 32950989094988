import { AvailabilityEvent } from "dtos/AvailabilityEvent";

/**
 * Returns summary information about the given availability by scanning the list of events. For example, if the user
 * changes his availability, the latest will be returned.
 */
export class AvailabilitySummary {
  private readonly availabilityEvents: AvailabilityEvent[];
  public readonly playerAvailability: AvailabilityStatus;
  public readonly required: RequiredStatus;
  public readonly waitingForAvailability: boolean;
  public readonly noEvents: boolean;
  public readonly askAgain: number;
  public readonly lastChased: number | null;
  public readonly remove: boolean;

  constructor(
    availabilityEvents: AvailabilityEvent[],
    availabilityAssumed: boolean = false,
    remove: boolean = false,
  ) {
    this.availabilityEvents = availabilityEvents.slice();

    const availabilityStringToEnum: { [key: string]: AvailabilityStatus } = {
      AVAILABLE: AvailabilityStatus.AVAILABLE,
      UNAVAILABLE: AvailabilityStatus.UNAVAILABLE,
      WILL_KNOW_LATER: AvailabilityStatus.WILL_KNOW_LATER,
    };

    const requiredStringToEnum: { [key: string]: RequiredStatus } = {
      REQUIRED: RequiredStatus.REQUIRED,
      REPLACED_WITH_DEP: RequiredStatus.REPLACED_WITH_DEP,
      WILL_NOT_FILL: RequiredStatus.WILL_NOT_FILL,
    };

    let latestYesNo = getLatestEvent(
      availabilityEvents.filter(
        (event) =>
          ["AVAILABLE", "UNAVAILABLE", "WILL_KNOW_LATER"].indexOf(
            event.type,
          ) !== -1,
      ),
    );
    if (
      latestYesNo !== null &&
      latestYesNo.type === "WILL_KNOW_LATER" &&
      latestYesNo.askAgain <= Date.now()
    ) {
      // If "will know later" is the latest event, and the time has passed, act as though no availability has been
      // entered.
      latestYesNo = null;
    }

    const latestRequired = getLatestEvent(
      availabilityEvents.filter(
        (event) =>
          ["REQUIRED", "REPLACED_WITH_DEP", "WILL_NOT_FILL"].indexOf(
            event.type,
          ) !== -1,
      ),
    );

    const latestChase = getLatestEvent(
      availabilityEvents.filter(
        (event) => event.type === "AVAILABILITY_REQUEST",
      ),
    );

    // TODO: potentially change all this over to functions.
    this.playerAvailability =
      latestYesNo === null
        ? availabilityAssumed
          ? AvailabilityStatus.AVAILABLE
          : AvailabilityStatus.NOT_KNOWN
        : availabilityStringToEnum[latestYesNo.type];
    this.required =
      latestRequired == null
        ? RequiredStatus.NOT_DECIDED
        : requiredStringToEnum[latestRequired.type];
    this.waitingForAvailability = latestChase !== null && latestYesNo == null; // TODO: unclear what this should be
    this.noEvents = availabilityEvents.length === 0;
    this.askAgain =
      latestYesNo !== null && latestYesNo.type === "WILL_KNOW_LATER"
        ? latestYesNo.askAgain
        : 0;
    this.lastChased = latestChase === null ? null : latestChase.datetime;
    this.remove = remove;
  }

  /**
   * True if the player is allowed to give their availability. This is if they've had an email or if they have the dummy
   * "CAN_GIVE_AVAILABILITY" event because they joined after emails went out; and also if their availability is known.
   * The latter condition is primarily for implicit availability. For a rehearsal, the player is assumed available,
   * therefore their availability is "known", and therefore they need to be able to change it.
   */
  canGiveAvailability(): boolean {
    return (
      this.playerAvailability !== AvailabilityStatus.NOT_KNOWN ||
      this.availabilityEvents.some(
        (event) =>
          event.type === "AVAILABILITY_REQUEST" ||
          event.type === "CAN_GIVE_AVAILABILITY",
      )
    );
  }

  /**
   * True if the player should give their availability. This is if we don't yet know their availability. Note that they
   * might not be _able_ to give their availability; see {@link canGiveAvailability()}.
   */
  shouldGiveAvailability(): boolean {
    return this.playerAvailability === AvailabilityStatus.NOT_KNOWN;
  }
}

export enum AvailabilityStatus {
  AVAILABLE,
  UNAVAILABLE,
  WILL_KNOW_LATER,
  NOT_KNOWN,
}

export enum RequiredStatus {
  REQUIRED,
  REPLACED_WITH_DEP,
  WILL_NOT_FILL,
  NOT_DECIDED,
}

/**
 * Returns the most recent event for the given availability object.
 */
export const getLatestEvent = (events: AvailabilityEvent[]) =>
  events.reduce<AvailabilityEvent | null>((mostRecentEvent, event) => {
    if (mostRecentEvent === null || event.datetime > mostRecentEvent.datetime) {
      return event;
    } else {
      return mostRecentEvent;
    }
  }, null);
