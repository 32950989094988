import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {
  Fade,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import {
  useGetEventInstancesQuery,
  useGetPlayerAvailabilitiesQuery,
} from "api/apiSlice";
import { useAppSelector } from "app/store";
import { selectBandId, selectMe } from "features/users/usersSlice";
import { useNavigate } from "react-router-dom";
import { EventInstanceForGig } from "dtos/EventInstance";
import { DatetimeSpan } from "features/misc/DatetimeSpan";
import { User } from "dtos/User";
import { selectPlayerFilterState } from "features/filter/playerFilterSlice";
import { AvailabilitySummaryIcon } from "features/misc/AvailablitySummaryIcon";
import {
  AvailabilityStatus,
  AvailabilitySummary,
} from "domain/availabilityDomain";
import CheckIcon from "@mui/icons-material/Check";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

const useStyles = makeStyles(() =>
  createStyles({
    key: {
      display: "flex",
      justifyContent: "flex-end",
      flexWrap: "wrap",
      paddingRight: "0.2em",
      paddingTop: "1em",
      fontSize: "80%",
      color: "#666",
    },
    keySet: {
      display: "flex",
      paddingBottom: "0.5em",
    },
    keyItem: {
      paddingRight: "10px",
      display: "flex",
      alignItems: "center",
    },
  }),
);

/**
 * List of upcoming rehearsals.
 */
export function RehearsalList({
  limit,
  allUnavailable,
}: {
  limit: number;
  allUnavailable: boolean;
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const bandId = useAppSelector(selectBandId);
  const me: User | null = useAppSelector(selectMe);
  const filterCriteria = useAppSelector(selectPlayerFilterState);
  // TODO: don't want to just get the next 100 events over the next year or whatever, need to get the actual definition
  // so we can fill the calendar in 2035 if we like for example.
  const { data: rehearsals = [], isLoading } = useGetEventInstancesQuery(
    {
      bandId: bandId === undefined ? 0 : bandId,
      filterCriteria: { year: "future" },
    },
    { skip: bandId === undefined },
  );
  const { data: availabilities = [] } = useGetPlayerAvailabilitiesQuery(
    { userId: me === null ? 0 : me.id, filterCriteria },
    { skip: me === null },
  );

  const handleClick = (eventInstanceForGig: EventInstanceForGig) => {
    navigate(
      `/player/rehearsals/${eventInstanceForGig.gig}/${eventInstanceForGig.eventInstance.startDatetime}`,
    );
  };

  if (isLoading) {
    return (
      <Fade in={true} unmountOnExit style={{ transitionDelay: "800ms" }}>
        <LinearProgress />
      </Fade>
    );
  }

  if (rehearsals.length === 0) {
    return <p>No upcoming rehearsals</p>;
  }

  const getSummary = (rehearsal: EventInstanceForGig): AvailabilitySummary => {
    const availability = availabilities.find(
      (a) =>
        a.gig === rehearsal.gig &&
        a.startDatetime === rehearsal.eventInstance.startDatetime,
    );
    return new AvailabilitySummary(availability?.events || [], true);
  };

  return (
    <>
      <Paper>
        <Table>
          <TableBody>
            {[
              // Upcoming rehearsals up to the limit.
              ...rehearsals.slice(0, limit),
              // Optionally all rehearsals beyond that where the user is unavailable.
              ...(allUnavailable
                ? rehearsals.slice(limit).filter(
                    (r) =>
                      // TODO: this is super-inefficient.
                      getSummary(r).playerAvailability ===
                      AvailabilityStatus.UNAVAILABLE,
                  )
                : []),
            ].map((rehearsal) => (
              <TableRow
                key={rehearsal.eventInstance.startDatetime}
                hover={true}
                onClick={() => handleClick(rehearsal)}
              >
                <TableCell>
                  <DatetimeSpan datetime={rehearsal.eventInstance} />
                </TableCell>
                <TableCell>{rehearsal.eventInstance.place.name}</TableCell>
                <TableCell>
                  <AvailabilitySummaryIcon
                    availabilitySummary={getSummary(rehearsal)}
                    gigCollecting={false}
                    player={true}
                    dim={false}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {rehearsals.length > 0 && (
        <div className={classes.key}>
          <span className={classes.keySet}>
            <span className={classes.keyItem}>
              <CheckIcon fontSize="small" />
              &nbsp;Available
            </span>
            <span className={classes.keyItem}>
              <ClearRoundedIcon fontSize="small" />
              &nbsp;Not available
            </span>
          </span>
        </div>
      )}
    </>
  );
}
