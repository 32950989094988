import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import { useAppSelector } from "app/store";
import { th } from "utils/utils";
import { useGetRankingQuery } from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { selectBandId } from "features/users/usersSlice";
import { formatDuration, intervalToDuration } from "date-fns";
import {
  Collapse,
  Link,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(3),
    },
    medal: {
      paddingRight: theme.spacing(2),
    },
    expander: {
      paddingLeft: theme.spacing(1),
    },
    details: {
      paddingTop: theme.spacing(3),
    },
    arrow: {
      paddingRight: theme.spacing(0.5),
    },
    closedDetailsIcon: {
      fontSize: "0.8rem",
      transitionProperty: "transform",
      transitionDuration: "0.3s",
    },
    openDetailsIcon: {
      fontSize: "0.8rem",
      transform: "rotate(90deg)",
      transitionProperty: "transform",
      transitionDuration: "0.3s",
    },
    explanation: {
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(2),
      width: "fit-content",
    },
    explanationIcon: {
      marginRight: theme.spacing(0.5),
    },
  }),
);

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    border: "1px solid #dadde9",
    padding: theme.spacing(1),
    paddingLeft: 0,
    fontSize: "0.8em",
  },
}));

function formatMillisAsDuration(duration: number) {
  if (duration < 1000 * 60) {
    // Response time is less than a minute, show to seconds granularity.
    return formatDuration(intervalToDuration({ start: 0, end: duration }), {
      delimiter: ", ",
    });
  }
  if (duration < 1000 * 60 * 60) {
    // Response time is less than an hour, show to minutes granularity.
    return formatDuration(intervalToDuration({ start: 0, end: duration }), {
      format: ["years", "months", "weeks", "days", "hours", "minutes"],
      delimiter: ", ",
    });
  }
  if (duration < 1000 * 60 * 60 * 24 * 14) {
    // Response time is less than two weeks, show to hours granularity.
    return formatDuration(intervalToDuration({ start: 0, end: duration }), {
      format: ["years", "months", "weeks", "days", "hours"],
      delimiter: ", ",
    });
  }
  // Response time is more than two weeks, show to days granularity.
  return formatDuration(intervalToDuration({ start: 0, end: duration }), {
    format: ["years", "months", "weeks", "days"],
    delimiter: ", ",
  });
}

/**
 * Shows the player's response rank, as well as a medal if they're in the top three.
 */
export function ResponseRank() {
  const classes = useStyles();
  const bandId = useAppSelector(selectBandId);
  const { data: rank } = useGetRankingQuery(bandId ?? skipToken);
  const [detailsOpen, setDetailsOpen] = useState(false);

  const handleClick = () => {
    setDetailsOpen(!detailsOpen);
  };

  return rank === null || rank === undefined ? null : (
    <div className={classes.main}>
      {rank.rank === 0 ? (
        <div className={classes.medal}>
          <img
            src="/images/1x/medals/1st.png"
            srcSet="/images/2x/medals/1st.png 2x"
            alt="First place medal"
          />
        </div>
      ) : rank.rank === 1 ? (
        <div className={classes.medal}>
          <img
            src="/images/1x/medals/2nd.png"
            srcSet="/images/2x/medals/2nd.png 2x"
            alt="Second place medal"
          />
        </div>
      ) : rank.rank === 2 ? (
        <div className={classes.medal}>
          <img
            src="/images/1x/medals/3rd.png"
            srcSet="/images/2x/medals/3rd.png 2x"
            alt="Third place medal"
          />
        </div>
      ) : null}
      <div>
        <div>
          {rank.rank === 0 ? (
            <span>
              Congratulations! You&apos;re the quickest responder in the whole
              band!
            </span>
          ) : rank.rank === 1 ? (
            <span>
              Amazing! You&apos;re the second quickest responder in the whole
              band!
            </span>
          ) : rank.rank === 2 ? (
            <span>
              Great! You&apos;re the third quickest responder in the whole band!
            </span>
          ) : rank.rank < 5 ? (
            <span>
              Good work, you&apos;re the {th(rank.rank + 1)} quickest responder
              in the band!
            </span>
          ) : rank.rank < 10 ? (
            <span>
              Not bad, you&apos;re currently the {th(rank.rank + 1)} quickest
              responder in the band!
            </span>
          ) : (
            <span>
              You&apos;re currently the {th(rank.rank + 1)} quickest responder
              in the band.
            </span>
          )}
          <span className={classes.expander} onClick={handleClick}>
            <span className={classes.arrow}>
              <ArrowForwardIosSharpIcon
                className={
                  detailsOpen
                    ? classes.openDetailsIcon
                    : classes.closedDetailsIcon
                }
              />
            </span>
            <Link href="#" underline="hover">
              Details&hellip;
            </Link>
          </span>
        </div>
        <Collapse in={detailsOpen}>
          <div className={classes.details}>
            <Typography>
              You have an average weighted response time of{" "}
              <strong>
                {formatMillisAsDuration(rank.weightedResponseTime)}
              </strong>
              :
            </Typography>
            <ul>
              {rank.gigData
                .slice()
                .sort((a, b) => a.gigStartDatetime - b.gigStartDatetime)
                .map((g) => (
                  <li key={g.gigId}>
                    <Link href={`/player/gigs/${g.gigId}`} underline="hover">
                      {g.gigName}:{" "}
                    </Link>
                    {formatMillisAsDuration(g.responseTime)}
                    {g.stillWaiting && " and counting"}
                  </li>
                ))}
            </ul>
            {rank.distance ? (
              <Typography>
                You need to improve your average by{" "}
                {formatMillisAsDuration(rank.distance)} to improve your ranking!
              </Typography>
            ) : null}
            <HtmlTooltip
              disableFocusListener
              followCursor
              enterTouchDelay={0}
              title={
                <>
                  <ul>
                    <li>
                      Only gigs that you respond to after an availability
                      request is sent out are included. This is so that we know
                      when to start timing.
                    </li>
                    <li>
                      Recent past gigs are included, but count for less, to help
                      you improve your ranking.
                    </li>
                  </ul>
                </>
              }
            >
              <Typography className={classes.explanation}>
                <InfoIcon
                  className={classes.explanationIcon}
                  color="secondary"
                  fontSize="small"
                />
                How does this work?
              </Typography>
            </HtmlTooltip>
          </div>
        </Collapse>
      </div>
    </div>
  );
}
