import { Action, configureStore, Middleware } from "@reduxjs/toolkit";
import { rootReducer, RootState } from "app/rootReducer";
import { ThunkAction } from "redux-thunk";
import { middleware as flashMiddleware } from "redux-flash";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { apiSlice } from "api/apiSlice";

export const storeWithState = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .prepend(
          // TODO: set proper type and/or replace this entirely because it's abandoned.
          flashMiddleware({ timeout: 5000 }) as Middleware<
            (action: Action<"specialAction">) => number,
            RootState
          >,
        )
        .concat(apiSlice.middleware),
  });

/**
 * Returns the store, set up with the default initial state.
 */
export const store = storeWithState();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducer", () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const newRootReducer = require("./rootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
