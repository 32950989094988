import React from "react";
import { Box, Link, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { PlayerEventDetails } from "features/player/PlayerEventDetails";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1000,
      padding: theme.spacing(2),
    },
  }),
);

/**
 * Shows the details of a given gig to a player, and allows them to either provide or change their availability.
 */
export function PlayerGigDetailsPage() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box sx={{ pb: 2 }}>
        <Link
          href="/player/gigs"
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <ChevronLeftIcon sx={{ marginLeft: -1 }} />{" "}
          <span>Back to gig list</span>
        </Link>
      </Box>
      <PlayerEventDetails />
    </div>
  );
}
