import React from "react";
import { Chip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import UpdateIcon from "@mui/icons-material/Update";
import { AvailabilityStatus } from "domain/availabilityDomain";
import { blue, green, red } from "@mui/material/colors";

/**
 * A coloured chip with an icon that represents availability: available, unavailable, or don't know yet.
 */
export function AvailabilityChip(props: {
  availabilityStatus: AvailabilityStatus;
}) {
  const label =
    props.availabilityStatus === AvailabilityStatus.AVAILABLE
      ? "Available"
      : props.availabilityStatus === AvailabilityStatus.UNAVAILABLE
        ? "Unavailable"
        : "I don't know yet";

  const icon =
    props.availabilityStatus === AvailabilityStatus.AVAILABLE ? (
      <CheckIcon style={{ color: green[50] }} />
    ) : props.availabilityStatus === AvailabilityStatus.UNAVAILABLE ? (
      <ClearRoundedIcon style={{ color: red[50] }} />
    ) : (
      <UpdateIcon style={{ color: blue[50] }} />
    );

  const style =
    props.availabilityStatus === AvailabilityStatus.AVAILABLE
      ? {
          backgroundColor: green[500],
          color: green[50],
        }
      : props.availabilityStatus === AvailabilityStatus.UNAVAILABLE
        ? {
            backgroundColor: red[500],
            color: red[50],
          }
        : {
            backgroundColor: blue[500],
            color: blue[50],
          };

  // We use component=span so this can be used inside a <p> (otherwise we get errors about nesting a p inside a p).
  return <Chip label={label} icon={icon} sx={style} component={"span"} />;
}
