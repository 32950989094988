import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { ButtonProps } from "@mui/material/Button/Button";

/**
 * A button that's disabled and shows an internal spinner if props.saving is true.
 */
export const ConfirmButton = (props: ButtonProps & { saving: boolean }) => {
  const { children, disabled, saving, ...rest } = props;
  return (
    <Button aria-label="confirm" disabled={disabled || saving} {...rest}>
      {children}
      {saving && <CircularProgress style={{ marginLeft: 8 }} size={20} />}
    </Button>
  );
};
