import React, { PropsWithChildren } from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import { Provider } from "react-redux";
import { store } from "app/store";
import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
} from "@auth0/auth0-react";
import { AUTH0_CONFIG } from "auth/auth0Config";
import { App } from "app/App";
import * as Sentry from "@sentry/browser";
import { BrowserRouter, useNavigate } from "react-router-dom";

const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: PropsWithChildren<Auth0ProviderOptions>) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.targetUrl) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const render = () => {
  if (process.env.ENVIRONMENT === "Production") {
    Sentry.init({
      dsn: "https://f3fa0d54d6864e0697c4761639978597@sentry.io/4027507",
      environment: process.env.ENVIRONMENT || "Development",
    });
  }

  const root = createRoot(document.getElementById("root")!);
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <Auth0ProviderWithRedirectCallback
          domain={AUTH0_CONFIG.domain}
          clientId={AUTH0_CONFIG.client_id}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: AUTH0_CONFIG.audience,
          }}
          useRefreshTokens={true}
        >
          <App />
        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
    </Provider>,
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./app/App", render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
