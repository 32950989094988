import React, { useMemo, useState } from "react";
import { Player } from "dtos/Player";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { fullName } from "features/users/userUtils";
import { selectBandId } from "features/users/usersSlice";
import { TooltippedComponent } from "utils/TooltippedComponent";
import { useAppSelector } from "app/store";
import { byId, groupBy } from "utils/utils";
import {
  getAvailabilitiesByGigIdAndStartTime,
  useGetEventInstancesQuery,
  useGetGigsQuery,
  useGetManagerAvailabilitiesQuery,
  useGetPlayersQuery,
  useGetSeatsQuery,
  useGetUsersQuery,
} from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  AvailabilityStatus,
  AvailabilitySummary,
} from "domain/availabilityDomain";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Theme } from "@mui/material/styles";
import { EventInstanceForGig } from "dtos/EventInstance";
import { selectFilterState } from "features/filter/filterSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    availabilityOverview: {
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    availabilityBlob: {
      display: "inline-block",
      width: "8px",
      height: "15px",
      marginRight: "1px",
    },
    available: {
      backgroundColor: "#0a0",
    },
    unavailable: {
      backgroundColor: "#d00",
    },
    unknown: {
      backgroundColor: "#bbb",
    },
    instrumentColumn: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  }),
);

const dateFormat = new Intl.DateTimeFormat(undefined, {
  weekday: "long",
  day: "numeric",
  month: "long",
  year: "numeric",
});

/**
 * Table of the current players in the band, along with controls to remove them and upgrade them to band managers.
 */
export function PlayerStats() {
  const [eventType, setEventType] = useState(0);
  const bandId = useAppSelector(selectBandId);
  const { data: players = [] } = useGetPlayersQuery(bandId ?? skipToken);
  const playersById = byId(players);
  const { data: allSeats = [] } = useGetSeatsQuery(bandId ?? skipToken);
  const seats = useMemo(
    () => allSeats.filter((s) => !s.deleted).sort((a, b) => a.order - b.order),
    [allSeats],
  );

  const recentAvailability = useRecentAvailability(
    bandId,
    players,
    eventType === 1,
  );

  const playerIdsBySeat = Object.values(playersById).reduce(
    (playersBySeat, player) => {
      const val = playersBySeat[player.seat] || [];
      val.push(player.id);
      playersBySeat[player.seat] = val;
      return playersBySeat;
    },
    {} as { [seatId: number]: number[] },
  );

  return (
    <>
      <FormControl variant="standard">
        <InputLabel>Event type</InputLabel>
        <Select
          labelId="event-type-selector"
          value={eventType}
          onChange={(e) => setEventType(e.target.value as number)}
          label="Event type"
        >
          <MenuItem key={0} value={0}>
            Gigs
          </MenuItem>
          <MenuItem key={1} value={1}>
            Rehearsals
          </MenuItem>
        </Select>
      </FormControl>

      <TableContainer
        component={Paper}
        sx={{ maxWidth: 1000, marginTop: "20px" }}
      >
        <Table>
          <TableBody>
            {seats
              .flatMap((seat) => playerIdsBySeat[seat.id])
              .filter((playerId) => playerId)
              .map((playerId) => playersById[playerId])
              .map((player) => (
                <PlayerTableRow
                  key={player.id}
                  player={player}
                  recentAvailability={recentAvailability}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

interface PlayerTableRowProps {
  player: Player;
  recentAvailability: RecentAvailability;
}

function PlayerTableRow({ player, recentAvailability }: PlayerTableRowProps) {
  const bandId = useAppSelector(selectBandId);
  const { data: users = [] } = useGetUsersQuery(bandId ?? skipToken);
  const user = byId(users)[player.user];
  const { data: seats = [] } = useGetSeatsQuery(bandId ?? skipToken);
  const seat = byId(seats)[player.seat];
  const classes = useStyles();

  const availabilityForPlayer =
    recentAvailability.availability.get(player.user) ?? [];
  const missedCount = availabilityForPlayer.filter((a) => !a).length;

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell className={classes.availabilityOverview}>
        <span role="group" aria-label="Availability summary">
          {availabilityForPlayer.map((available, index) => (
            <TooltippedComponent
              key={index}
              tooltip={
                recentAvailability.events[index].eventInstance.name +
                " - " +
                dateFormat.format(
                  recentAvailability.events[index].eventInstance.startDatetime,
                )
              }
              component={
                <span
                  aria-label={available ? "Available" : "Unavailable"}
                  key={index}
                  className={`${classes.availabilityBlob} ${
                    available === undefined
                      ? classes.unknown
                      : available
                        ? classes.available
                        : classes.unavailable
                  }`}
                ></span>
              }
            />
          ))}
        </span>
      </TableCell>
      <TableCell>
        {availabilityForPlayer.length > 0
          ? Math.round(
              ((availabilityForPlayer.length - missedCount) /
                availabilityForPlayer.length) *
                100,
            ) + "%"
          : null}
      </TableCell>
      <TableCell component="th" scope="row">
        {fullName(user)}
      </TableCell>
      <TableCell className={classes.instrumentColumn}>{seat?.name}</TableCell>
    </TableRow>
  );
}

interface RecentAvailability {
  availability: Map<number, (boolean | undefined)[]>;
  events: EventInstanceForGig[];
}

/**
 * Returns Map<number, boolean[]>, mapping player id to an array of boolean indicating recent event availability.
 */
function useRecentAvailability(
  bandId: number | undefined,
  players: Player[],
  showRehearsals: boolean,
): RecentAvailability {
  const filterCriteria = useAppSelector(selectFilterState);

  const { data: rehearsals = [] } = useGetEventInstancesQuery(
    {
      bandId: bandId === undefined ? 0 : bandId,
      filterCriteria,
    },
    { skip: bandId === undefined },
  );

  const { data: gigs = [] } = useGetGigsQuery(
    {
      bandId: bandId === undefined ? 0 : bandId,
      filterCriteria,
    },
    { skip: bandId === undefined },
  );

  const eventInstances: EventInstanceForGig[] = showRehearsals
    ? rehearsals
    : gigs
        .slice()
        .sort((g1, g2) => g1.startDatetime - g2.startDatetime)
        .map((gig) => {
          return {
            gig: gig.id,
            eventInstance: gig,
          };
        });

  const {
    data: managerAvailabilityInfo = {
      byId: {},
      byGigId: {},
      byGigIdAndStartTime: {},
    },
  } = useGetManagerAvailabilitiesQuery(
    {
      bandId: bandId === undefined ? 0 : bandId,
      filterCriteria,
    },
    { skip: bandId === null },
  );

  return useMemo(() => {
    // Initialise a map from player id to an empty array of booleans indicating event availability.
    const availabilitiesByUserId: Map<number, (boolean | undefined)[]> =
      new Map();
    for (const player of players) {
      availabilitiesByUserId.set(player.user, []);
    }

    for (const event of eventInstances) {
      const availabilities = getAvailabilitiesByGigIdAndStartTime(
        managerAvailabilityInfo,
        event.gig,
        event.eventInstance.startDatetime,
      );
      const availabilitiesByUserIdForEvent = groupBy(
        availabilities,
        (a) => a.user,
      );
      availabilitiesByUserId.forEach((availabilitySummaries, userId) => {
        const availabilitiesForUserForEvent =
          availabilitiesByUserIdForEvent[userId];
        if (availabilitiesForUserForEvent === undefined) {
          if (showRehearsals) {
            // Players are assumed to be available.
            availabilitySummaries.push(true);
          } else {
            availabilitySummaries.push(undefined);
          }
        } else {
          // There should be exactly one availability per user, so take that and look up their availability.
          const availabilitySummary = new AvailabilitySummary(
            availabilitiesForUserForEvent[0].events,
            showRehearsals,
          );
          availabilitySummaries.push(
            availabilitySummary.playerAvailability ===
              AvailabilityStatus.AVAILABLE
              ? true
              : availabilitySummary.playerAvailability ===
                  AvailabilityStatus.UNAVAILABLE
                ? false
                : undefined,
          );
        }
      });
    }
    return { availability: availabilitiesByUserId, events: eventInstances };
  }, [eventInstances, managerAvailabilityInfo, showRehearsals]);
}
