import React from "react";
import { Button } from "@mui/material";
import { MenuItemDefinition, PopperMenu } from "features/misc/PopperMenu";
import { PlayerManagement } from "features/manager/playerManagement";
import { EventType } from "dtos/AvailabilityEvent";

export const OPTION_AVAILABLE = (
  setEventForSelected: (eventType: EventType) => void,
) => ({
  label: "Available",
  disabled: false,
  onClick: () => setEventForSelected("AVAILABLE"),
});
export const OPTION_UNAVAILABLE = (
  setEventForSelected: (eventType: EventType) => void,
) => ({
  label: "Unavailable",
  disabled: false,
  onClick: () => setEventForSelected("UNAVAILABLE"),
});
export const OPTION_REQUIRED = (
  setEventForSelected: (eventType: EventType) => void,
) => ({
  label: "Required",
  disabled: false,
  onClick: () => setEventForSelected("REQUIRED"),
});
export const OPTION_WILL_NOT_FILL = (
  setEventForSelected: (eventType: EventType) => void,
) => ({
  label: "Not required",
  disabled: false,
  onClick: () => setEventForSelected("WILL_NOT_FILL"),
});

interface ModifyAvailabilityMenuProps {
  playerManagement: PlayerManagement;
  menuItems: MenuItemDefinition[];
}

/**
 * Shows the 'Choose' menu on the gig details page and the multi gig page when one or more items are selected, allowing
 * the band manager to change availability or required/not required state.
 */
export function ModifyAvailabilityMenu({
  playerManagement,
  menuItems,
}: ModifyAvailabilityMenuProps) {
  const { someSelected } = playerManagement;

  const chooseRef = React.useRef<HTMLButtonElement>(null);
  const [chooseMenuOpen, chooseMenuSetOpen] = React.useState(false);
  const handleChooseMenuToggle = () =>
    chooseMenuSetOpen((prevOpen) => !prevOpen);

  return (
    <>
      {someSelected ? (
        <Button onClick={handleChooseMenuToggle} ref={chooseRef}>
          Choose
        </Button>
      ) : null}

      <PopperMenu
        menuOptions={menuItems}
        open={chooseMenuOpen}
        setOpen={chooseMenuSetOpen}
        reference={chooseRef}
      />
    </>
  );
}
