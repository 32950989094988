import React from "react";
import { getMonthArray } from "features/calendar/calendarUtils";
import { Day } from "features/calendar/Day";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Theme } from "@mui/material/styles";
import { format, startOfDay } from "date-fns";
import { enGB } from "date-fns/locale";
import { useGetEventInstancesQuery } from "api/apiSlice";
import { useAppSelector } from "app/store";
import { selectBandId } from "features/users/usersSlice";
import { groupBy } from "utils/utils";
import { useNavigate } from "react-router-dom";

interface CalendarGridProps {
  date: Date;
  today: Date;
}

/**
 * The actual grid of a calendar, showing a particular month.
 *
 * @param date A date within the month that is to be displayed.
 * @param today Midnight local time today (for highlighting the current day).
 */
export function CalendarGrid({ date, today }: CalendarGridProps) {
  const navigate = useNavigate();
  const bandId = useAppSelector(selectBandId);

  const { data: rehearsals = [] } = useGetEventInstancesQuery(
    {
      bandId: bandId === undefined ? 0 : bandId,
      filterCriteria: { year: date.getUTCFullYear() },
    },
    { skip: bandId === undefined },
  );

  // TODO (516): there could be more than one rehearsal on a given date. For now, we assume there's only one.
  const rehearsalsByStartTime = groupBy(rehearsals, (r) =>
    startOfDay(new Date(r.eventInstance.startDatetime)).getTime(),
  );

  const classes = useStyles();
  const days = getMonthArray(date);
  const rows =
    days.length === 4
      ? classes.grid4Rows
      : days.length === 5
        ? classes.grid5rows
        : classes.grid6rows;
  return (
    <div className={`${classes.grid} ${rows} ${classes.responsiveGrid}`}>
      {days.map((row, rowIndex) =>
        row.map((day, colIndex) => {
          const rehearsalOnDay =
            rehearsalsByStartTime[day.getTime()]?.length > 0;
          return (
            <div
              key={day.getTime()}
              style={{
                gridColumn: `${colIndex + 1} / span 1`,
                gridRow: `${rowIndex + 1} / span 1`,
                borderBottom: "1px solid #dfdfdf",
                borderRight: "1px solid #dfdfdf",
                padding: "10px",
              }}
              role="button"
              onClick={() => {
                if (!rehearsalOnDay) {
                  // Only allow a rehearsal to be added if there isn't already a rehearsal that day (because we don't
                  // currently support more than one rehearsal per day properly).
                  navigate(`/manager/rehearsals/new/${day.getTime()}`);
                }
              }}
              aria-label={format(day, "d LLL y", { locale: enGB })}
            >
              <Day
                day={day}
                includeWayOfWeek={rowIndex === 0}
                // Include the month on the 1st to make it clearer what the user's looking at, but if today is the 1st,
                // don't do this because it makes the circle around today look terrible. This is what Google Calendar does
                // too...
                includeMonth={day.getDate() === 1 && today.getDate() !== 1}
                today={day.getTime() === today.getTime()}
                events={rehearsalsByStartTime[day.getTime()] ?? []}
              />
            </div>
          );
        }),
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
      borderTop: "1px solid #dfdfdf",
      borderLeft: "1px solid #dfdfdf",
    },
    grid4rows: {
      gridTemplateRows: "1fr 1fr 1fr 1fr",
    },
    grid5rows: {
      gridTemplateRows: "1fr 1fr 1fr 1fr 1fr",
    },
    grid6rows: {
      gridTemplateRows: "1fr 1fr 1fr 1fr 1fr 1fr",
    },
    [theme.breakpoints.down("md")]: {
      // Make the grid go right to the edges of the screen when small.
      responsiveGrid: {
        marginLeft: "-24px",
        marginRight: "-24px",
        marginBottom: "-24px",
        height: "calc(100vh - 152px)",
      },
    },
    [theme.breakpoints.up("md")]: {
      responsiveGrid: {
        height: "calc(100vh - 176px)",
      },
    },
  }),
);
