import { createSelector } from "@reduxjs/toolkit";
import { Player } from "dtos/Player";
import { apiSlice } from "api/apiSlice";
import { Role } from "dtos/Role";
import { byId } from "utils/utils";

export const selectMeResult = apiSlice.endpoints.getMe.select();

export const selectMe = createSelector(
  selectMeResult,
  (result) => result?.data ?? null,
);

const selectMyPlayersResult = apiSlice.endpoints.getMyPlayers.select();

/**
 * @return The Player, or null if the player is not assigned to any bands (they're new), or undefined if we haven't yet
 *   loaded the player information.
 */
export const selectMyPlayer = createSelector(
  selectMyPlayersResult,
  (result) => {
    if (result === undefined) {
      // My players not loaded yet.
      return undefined;
    }
    const data: { [id: number]: Player } = result.data ?? {};
    if (Object.values(data).length === 0) {
      // Player belongs to no bands yet.
      return null;
    }
    // Just pick the first band. TODO: support a player belonging to more than one band.
    return Object.values(data)[0];
  },
);

export const selectMyPlayerLoaded = createSelector(
  selectMyPlayersResult,
  (result) => result?.isSuccess,
);

export const selectBandId = createSelector(selectMyPlayersResult, (result) => {
  if (result === undefined) {
    // My players not loaded yet.
    return undefined;
  }
  const data: { [id: number]: Player } = result.data ?? {};
  if (Object.values(data).length === 0) {
    // Player belongs to no bands yet.
    return undefined;
  }
  // Just pick the first band. TODO: support a player belonging to more than one band.
  return Object.values(data)[0].band;
});

const selectRolesResult = apiSlice.endpoints.getRoles.select();

export const selectRoles = createSelector(selectRolesResult, (result) => {
  if (result === undefined) {
    // Roles not loaded yet.
    return undefined;
  }
  const data: Role[] = result.data ?? [];
  return byId(data);
});

export const selectRolesLoaded = createSelector(
  selectRolesResult,
  (result) => result?.isSuccess,
);
