import { Tooltip } from "@mui/material";
import React from "react";

interface TooltippedComponentProps {
  tooltip: string;
  component: React.ReactElement;
}

export const TooltippedComponent = (props: TooltippedComponentProps) => {
  const { tooltip, component } = props;
  return (
    <Tooltip title={tooltip}>
      {/* Span needed to make disabled tooltips work */}
      <span>{component}</span>
    </Tooltip>
  );
};
