import React, { useEffect, useState } from "react";
import { ManagerUI } from "features/manager/ManagerUI";
import { useAuth0 } from "@auth0/auth0-react";
import { Home } from "features/home/Home";
import {
  adaptV4Theme,
  createTheme,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from "@mui/material/styles";
import { Alert, CircularProgress, Fade, Snackbar } from "@mui/material";
import { RootState } from "app/rootReducer";
import { Account } from "features/account/Account";
import { getLatestMessage, removeMessage } from "redux-flash";
import Typography from "@mui/material/Typography";
import { Privacy } from "features/home/Privacy";
import { HowToDeleteYourAccount } from "features/home/HowToDeleteYourAccount";

import { purple, teal } from "@mui/material/colors";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { enGB } from "date-fns/locale";
import {
  selectMe,
  selectMeResult,
  selectMyPlayerLoaded,
  selectRolesLoaded,
} from "features/users/usersSlice";
import { useAppDispatch, useAppSelector } from "app/store";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ManagerEventDetails } from "features/manager/ManagerEventDetails";
import { MultiGig } from "features/manager/MultiGig";
import { PlayerGigDetailsPage } from "features/player/PlayerGigDetailsPage";
import { JoinBand } from "features/player/JoinBand";
import { Dashboard } from "features/manager/Dashboard";
import { PlayerUI } from "features/player/PlayerUI";
import { LinkProps } from "@mui/material/Link";
import { PlayerManagement } from "features/playermanagement/PlayerManagement";
import { DepManagement } from "features/deps/DepManagement";
import { CalendarIntegration } from "features/player/CalendarIntegration";
import { tokenReceived } from "auth/authSlice";
import {
  useGetMeQuery,
  useGetMyPlayersQuery,
  useGetRolesQuery,
} from "api/apiSlice";
import { FullUser } from "dtos/User";
import { CurrentPlayers } from "features/playermanagement/CurrentPlayers";
import { InvitePlayer } from "features/playermanagement/InvitePlayer";
import { WpCalendar } from "features/manager/WpCalendar";
import { usePermissions } from "auth/usePermissions";
import { Noticeboard } from "features/player/Noticeboard";
import { PlayerDashboard } from "features/player/PlayerDashboard";
import { PlayerAvailabilitySummaryPage } from "features/player/PlayerAvailabilitySummaryPage";
// noinspection ES6UnusedImports
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { renderTimeViewClock } from "@mui/x-date-pickers";
import { Rehearsals } from "features/player/Rehearsals";
import { ManagerRehearsals } from "features/manager/Rehearsals";
import { Calendar } from "features/calendar/Calendar";
import { PlayerStats } from "features/playermanagement/PlayerStats";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const LinkBehavior = React.forwardRef<
  never,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
  );
});
LinkBehavior.displayName = "LinkBehaviour";

const theme = createTheme({
  ...adaptV4Theme({
    palette: {
      primary: teal,
      secondary: purple,
    },
  }),
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    // Render a clock on desktop for time picking. At the moment, there's no alternative widget. See if there's a better
    // option in the future.
    MuiDateTimePicker: {
      defaultProps: {
        viewRenderers: {
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        },
      },
    },
  },
});

/**
 * Entry point to the entire app.
 */
export function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <Typography component="div">
            <ThemedApp />
          </Typography>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

function ThemedApp() {
  // Wait for Auth0 to authenticate (or not), then, if authenticated, fetch the token. Once that's done, fetch the user,
  // and once we have the user (or Auth0 initialised and told us we're not authenticated), we can render the UI.

  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [gotToken, setGotToken] = useState(false);
  const dispatch = useAppDispatch();

  const env = { ...process.env, ...window.process?.env };
  const assumeAuthenticated = env.REACT_APP_CYPRESS_TESTS === "true";

  const actualIsAuthenticated = isAuthenticated || assumeAuthenticated;
  const actualGotToken = gotToken || assumeAuthenticated;
  const actualIsLoading = isLoading && !assumeAuthenticated;

  async function configureAccessToken() {
    const token = await getAccessTokenSilently();
    dispatch(tokenReceived(token));
    setGotToken(true);
  }

  if (actualIsAuthenticated && !actualGotToken) {
    // Auth0 says we're authenticated. Fetch the token and set it in the store so all future requests to the backend
    // include it.
    configureAccessToken();
  }

  const me = useAppSelector(selectMe);
  const meLoaded = me !== undefined && me !== null;
  const myPlayerLoaded = useAppSelector(selectMyPlayerLoaded);
  const rolesLoaded = useAppSelector(selectRolesLoaded);

  let showSpinner = false;
  if (actualIsLoading) {
    showSpinner = true;
  }
  if (actualIsAuthenticated && (!myPlayerLoaded || !meLoaded || !rolesLoaded)) {
    showSpinner = true;
  }

  if (showSpinner) {
    // Show a spinner if Auth0 is loading or if we're authenticated but we're still fetching the token and the user.
    return (
      <>
        {actualIsAuthenticated && actualGotToken && <LoadStuff />}
        <Fade in={true} unmountOnExit style={{ transitionDelay: "800ms" }}>
          <CircularProgress />
        </Fade>
      </>
    );
  }

  return <AllViews isAuthenticated={actualIsAuthenticated && actualGotToken} />;
}

function LoadStuff() {
  useGetMeQuery();
  useGetMyPlayersQuery();
  useGetRolesQuery();
  return <></>;
}

interface AllViewsProps {
  isAuthenticated: boolean;
}

function AllViews(props: AllViewsProps) {
  // We have to reference these again because <LoadStuff/> dismounts after the spinner goes away, causing RTK Query to
  // delete the data after 60 seconds.
  useGetMeQuery();
  useGetMyPlayersQuery();
  useGetRolesQuery();

  return (
    <Routes>
      <Route path="/privacy" element={<Privacy />} />
      <Route
        path="/how-to-delete-your-account"
        element={<HowToDeleteYourAccount />}
      />
      <Route
        path="*"
        element={
          props.isAuthenticated ? (
            <AuthenticatedView />
          ) : (
            <UnauthenticatedView />
          )
        }
      />
    </Routes>
  );
}

function AuthenticatedView() {
  const me: FullUser | null = useAppSelector(selectMe);
  const meData = useAppSelector(selectMeResult);
  const permissions = usePermissions();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const flashMessage = useAppSelector((state: RootState) =>
    getLatestMessage(state),
  );

  useEffect(() => {
    if (
      !permissions.canViewManagerUi() &&
      location.pathname.startsWith("/manager")
    ) {
      // If the user doesn't have permission to view the manager UI, but has a link to it, try to translate it to the
      // equivalent place in the player UI.
      let redirect = "/player/dashboard";
      if (/\/manager\/gigs\/\d+/.test(location.pathname)) {
        // If an actual gig is linked to, redirect to the player version of that.
        redirect =
          "/player/gigs/" +
          location.pathname.substring("/manager/gigs/".length);
      }
      navigate(redirect, { replace: true });
    }
  }, [location]);

  if (
    me !== undefined &&
    me !== null &&
    (me.givenName === "" || me.emailAddress === "") &&
    !searchParams.get("redirect") &&
    !meData.isLoading
  ) {
    // This is a new user that signed up via username/password rather than Social, or a user that signed up via
    // a Social provider that doesn't have their email address (eg. Facebook with a phone number only). We need
    // to get their name and/or email address. Remember where they were going so we can redirect later (it's
    // almost certainly to the "join" page where we'll join the user to a band).
    // Note we check for meData.isLoading above so that once the new user's been to the account page and entered their
    // data, and been redirected wherever they were going, we don't redirect them immediately back again because
    // RTK Query hasn't yet reloaded the user.
    const url = "/account?redirect=" + encodeURIComponent(location.pathname);
    return (
      <Routes>
        <Route path="*" element={<Navigate replace to={url} />} />
      </Routes>
    );
  }

  return (
    <>
      {flashMessage ? (
        <Snackbar
          open={true}
          onClose={() => {
            removeMessage(flashMessage.id);
          }}
        >
          <Alert severity={flashMessage.isError ? "error" : "success"}>
            {flashMessage.message}
          </Alert>
        </Snackbar>
      ) : null}

      <Routes>
        <Route path="/manager" element={<ManagerUI />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="gigs/new" element={<ManagerEventDetails />} />
          <Route path="gigs/multi" element={<MultiGig />} />
          <Route path="rehearsals" element={<ManagerRehearsals />} />
          <Route path="gigs/:gigId" element={<ManagerEventDetails />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="calendar/:year/:month" element={<Calendar />} />
          <Route
            path="rehearsals/new/:startDatetime"
            element={<ManagerEventDetails />}
          />
          <Route
            path="rehearsals/:gigId/:startDatetime"
            element={<ManagerEventDetails />}
          />
          <Route path="settings" element={<PlayerManagement />}>
            <Route path="players" element={<CurrentPlayers />} />
            <Route path="invitations" element={<InvitePlayer />} />
            <Route path="stats" element={<PlayerStats />} />
          </Route>
          <Route path="invitations" element={<PlayerManagement />} />
          <Route path="deps" element={<DepManagement />} />
          <Route path="wpcalendar" element={<WpCalendar />} />
          <Route path="" element={<Navigate replace to="dashboard" />} />
        </Route>
        <Route path="/player" element={<PlayerUI />}>
          <Route path="dashboard" element={<PlayerDashboard />} />
          <Route path="gigs" element={<PlayerAvailabilitySummaryPage />} />
          <Route path="gigs/:gigId" element={<PlayerGigDetailsPage />} />
          <Route path="rehearsals" element={<Rehearsals />} />
          <Route
            path="rehearsals/:gigId/:startDatetime"
            element={<Rehearsals />}
          />
          <Route path="join/:token" element={<JoinBand />} />
          <Route path="calendar" element={<CalendarIntegration />} />
          <Route path="noticeboard" element={<Noticeboard />} />
          <Route path="" element={<Navigate replace to="gigs" />} />
        </Route>
        <Route path="/account" element={<Account />} />
        <Route path="/" element={<Navigate replace to="/player/dashboard" />} />
      </Routes>
    </>
  );
}

function UnauthenticatedView() {
  // Redirect to /home if somewhere else; remember where we were attempting to go.
  if (window.location.pathname !== "/home") {
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname === "/"
        ? "/home"
        : "/home?redirect=" + encodeURIComponent(window.location.pathname),
    );
  }

  const redirect =
    new URL(window.location.href).searchParams.get("redirect") ||
    "/player/dashboard";

  return <Home redirect={redirect} />;
}
