import { Box, Grid } from "@mui/material";
import { AvailabilityEventIcon } from "features/misc/AvailablityEventIcon";
import { format, formatDistanceToNow } from "date-fns";
import { enGB } from "date-fns/locale";
import { fullNameOrYou } from "features/users/userUtils";
import React from "react";
import { User } from "dtos/User";
import { selectMe } from "features/users/usersSlice";
import { AvailabilityEvent } from "dtos/AvailabilityEvent";
import { useAppSelector } from "app/store";
import { TooltippedComponent } from "utils/TooltippedComponent";
import { useGetUserQuery } from "api/apiSlice";

interface AvailabilityEventHistoryProps {
  availabilityEvents: AvailabilityEvent[];
  availabilityUserId: number;
  playerView: boolean;
}

/**
 * Writes the event log the band manager sees when he expands a seat in the gig details view, or a player sees if they
 * expand their availability summary in their gig details view.
 */
export function AvailabilityEventHistory({
  availabilityEvents,
  availabilityUserId,
  playerView,
}: AvailabilityEventHistoryProps) {
  return (
    <>
      {availabilityEvents
        .slice()
        // Filter out the hidden CAN_GIVE_AVAILABILITY "event".
        .filter((event) => event.type !== "CAN_GIVE_AVAILABILITY")
        // Newest item first.
        .sort((event1, event2) =>
          playerView
            ? event1.datetime - event2.datetime
            : event2.datetime - event1.datetime,
        )
        .map((event) => (
          <HistoryItem
            key={event.datetime}
            event={event}
            availabilityUserId={availabilityUserId}
            playerView={playerView}
          />
        ))}
    </>
  );
}

function HistoryItem({
  event,
  availabilityUserId,
  playerView,
}: {
  event: AvailabilityEvent;
  availabilityUserId: number;
  playerView: boolean;
}) {
  const { data: user } = useGetUserQuery(event.user, {
    skip: event.user === -1,
  });
  const me: User | null = useAppSelector(selectMe);

  return (
    <Grid container alignItems="center" flexWrap="nowrap">
      <Grid item>
        <Box pr={1} pt={0.5}>
          <AvailabilityEventIcon
            eventType={event.type}
            playerView={playerView}
          />
        </Box>
      </Grid>
      <Grid item>
        <TooltippedComponent
          tooltip={format(new Date(event.datetime), "PPPPp", {
            locale: enGB,
          })}
          component={
            <span>{formatDistanceToNow(new Date(event.datetime))} ago: </span>
          }
        />
        <span>
          {event.type === "AVAILABILITY_REQUEST"
            ? "Availability request sent"
            : event.type === "AVAILABLE"
              ? "Available"
              : event.type === "UNAVAILABLE"
                ? "Unavailable"
                : event.type === "WILL_KNOW_LATER"
                  ? "Won't know until " +
                    format(new Date(event.askAgain), "PP", { locale: enGB })
                  : event.type === "REQUIRED"
                    ? "Required"
                    : event.type === "REPLACED_WITH_DEP"
                      ? playerView
                        ? "Not required"
                        : "Replace with dep"
                      : event.type === "WILL_NOT_FILL"
                        ? "Not required"
                        : "Unknown"}{" "}
          {event.comments ? '("' + event.comments + '")' : ""}
          {event.user !== -1 && event.user !== availabilityUserId
            ? "— " + fullNameOrYou(user, me)
            : ""}
        </span>
      </Grid>
    </Grid>
  );
}
