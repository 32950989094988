import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Theme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { AvailabilityChip } from "features/misc/AvailablityChip";
import {
  AvailabilityStatus,
  AvailabilitySummary,
} from "domain/availabilityDomain";
import { format, isValid } from "date-fns";
import { enGB } from "date-fns/locale";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import {
  useAddNewAvailabilityEventForAssumedAvailabilityMutation,
  useAddNewAvailabilityEventMutation,
} from "api/apiSlice";
import { AvailabilityEvent } from "dtos/AvailabilityEvent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacing: {
      marginTop: theme.spacing(2),
    },
    confirmAvailability: {
      marginTop: theme.spacing(2),
    },
    horizontalSpacing: {
      marginLeft: theme.spacing(1),
    },
  }),
);

interface SetAvailabilityProps {
  availabilityId?: number;
  gigId?: number;
  startDatetime?: number;
  availabilityEvents: AvailabilityEvent[];
  availabilityAssumed: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

/**
 * Lets the user set and update their availability.
 */
export function SetAvailability({
  availabilityId,
  gigId,
  startDatetime,
  availabilityEvents,
  availabilityAssumed,
  onCancel,
  onConfirm,
}: SetAvailabilityProps) {
  const classes = useStyles();
  const navigate = useNavigate();

  const summary = new AvailabilitySummary(availabilityEvents);

  const actualAvailability =
    availabilityAssumed &&
    summary.playerAvailability === AvailabilityStatus.NOT_KNOWN
      ? AvailabilityStatus.AVAILABLE
      : summary.playerAvailability;

  const availabilityKnown = actualAvailability !== AvailabilityStatus.NOT_KNOWN;

  const [modalOpen, setModalOpen] = useState(false);
  const [comments, setComments] = useState("");
  const [availabilityStatus, setAvailabilityStatus] =
    React.useState(actualAvailability);
  const [askAgainDatetime, setAskAgainDateTime] = useState<Date | null>(
    summary.askAgain === 0 ? null : new Date(summary.askAgain),
  );
  const [dateError, setDateError] = useState(false);

  const handleSetAvailability = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = (event.target as HTMLInputElement).value;
    const availability =
      value === "AVAILABLE"
        ? AvailabilityStatus.AVAILABLE
        : value === "UNAVAILABLE"
          ? AvailabilityStatus.UNAVAILABLE
          : AvailabilityStatus.WILL_KNOW_LATER;
    setAvailabilityStatus(availability);
  };

  const [addNewAvailabilityEvent] = useAddNewAvailabilityEventMutation();
  const [addNewAvailabilityEventForAssumedAvailability] =
    useAddNewAvailabilityEventForAssumedAvailabilityMutation();

  const handleConfirmAvailability = async () => {
    if (!availabilityKnown || modalOpen) {
      if (availabilityId === undefined && gigId && startDatetime) {
        await addNewAvailabilityEventForAssumedAvailability({
          gigId,
          startDatetime,
          event: {
            user: 0,
            datetime: 0,
            type:
              availabilityStatus === AvailabilityStatus.AVAILABLE
                ? "AVAILABLE"
                : availabilityStatus === AvailabilityStatus.UNAVAILABLE
                  ? "UNAVAILABLE"
                  : "WILL_KNOW_LATER",
            askAgain:
              askAgainDatetime === null ? 0 : askAgainDatetime.getTime(),
            comments,
          },
        });
      } else if (availabilityId !== undefined) {
        await addNewAvailabilityEvent({
          availabilityId: availabilityId || 0,
          event: {
            user: 0,
            datetime: 0,
            type:
              availabilityStatus === AvailabilityStatus.AVAILABLE
                ? "AVAILABLE"
                : availabilityStatus === AvailabilityStatus.UNAVAILABLE
                  ? "UNAVAILABLE"
                  : "WILL_KNOW_LATER",
            askAgain:
              askAgainDatetime === null ? 0 : askAgainDatetime.getTime(),
            comments,
          },
        });
      }
      onConfirm();
    } else {
      setModalOpen(true);
    }
  };

  const handleCancel = () => {
    if (availabilityKnown) {
      setComments("");
      setAvailabilityStatus(summary.playerAvailability);
      onCancel();
    } else {
      // Only happens for gigs. Availability is always "known" for rehearsals.
      navigate("/player/gigs");
    }
  };

  // Button should be disabled unless availability has changed. We don't allow the user to change a "will know later" to
  // a different "will know later"... should we?
  const availabilityUnchanged = availabilityStatus === actualAvailability;

  // If this is a modification to a previous availability, we need a reason, unless this is setting the intiial
  // availability of a gig.
  const needsComment =
    availabilityStatus !== actualAvailability &&
    !(
      actualAvailability === AvailabilityStatus.NOT_KNOWN &&
      !availabilityAssumed
    );

  // Form is incomplete if it needs a comment and doesn't have one, of it the user's said "will know later" and hasn't
  // entered a date.
  const formIncomplete =
    (needsComment && comments === "") ||
    (availabilityStatus === AvailabilityStatus.WILL_KNOW_LATER &&
      askAgainDatetime === null);

  const buttonDisabled = availabilityUnchanged || formIncomplete || dateError;

  const setAvailabilityUI = (
    <>
      <div>
        <RadioGroup
          aria-label="availability"
          name="availability"
          value={
            availabilityStatus === AvailabilityStatus.AVAILABLE ||
            (availabilityAssumed &&
              availabilityStatus === AvailabilityStatus.NOT_KNOWN)
              ? "AVAILABLE"
              : availabilityStatus === AvailabilityStatus.UNAVAILABLE
                ? "UNAVAILABLE"
                : availabilityStatus === AvailabilityStatus.WILL_KNOW_LATER
                  ? "WILL_KNOW_LATER"
                  : ""
          }
          onChange={handleSetAvailability}
        >
          <FormControlLabel
            value="AVAILABLE"
            control={<Radio />}
            label="I'm available"
          />
          <FormControlLabel
            value="UNAVAILABLE"
            control={<Radio />}
            label="I'm unavailable"
          />
          {!availabilityAssumed && (
            <FormControlLabel
              value="WILL_KNOW_LATER"
              control={<Radio />}
              label="I don't know yet"
            />
          )}
        </RadioGroup>

        {availabilityStatus === AvailabilityStatus.WILL_KNOW_LATER ? (
          <DatePicker
            label="Ask again"
            value={askAgainDatetime}
            onChange={setAskAgainDateTime}
            disablePast={true}
            onError={(reason) => setDateError(reason !== null)}
          />
        ) : null}

        {needsComment ? (
          <div>
            <TextField
              className={classes.spacing}
              variant="filled"
              fullWidth={true}
              multiline={true}
              label="Please provide a brief explanation"
              value={comments}
              onChange={(event) => setComments(event.target.value)}
            />
          </div>
        ) : null}

        <div className={classes.spacing} />

        <Button
          variant="contained"
          color="primary"
          disabled={buttonDisabled}
          onClick={handleConfirmAvailability}
        >
          {availabilityKnown ? "Change availability" : "Confirm availability"}
        </Button>

        <Button className={classes.horizontalSpacing} onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </>
  );

  const dialog = (
    <ConfirmChangeAvailabilityDialog
      modalOpen={modalOpen}
      closeModal={() => setModalOpen(false)}
      originalAvailabilityStatus={actualAvailability}
      newAvailabilityStatus={availabilityStatus}
      originalAskAgainDatetime={new Date(summary.askAgain)}
      newAskAgainDatetime={askAgainDatetime}
      handleConfirmAvailability={handleConfirmAvailability}
    />
  );

  return (
    <div>
      {setAvailabilityUI}
      {dialog}
    </div>
  );
}

function ConfirmChangeAvailabilityDialog(props: {
  modalOpen: boolean;
  closeModal: () => void;
  originalAvailabilityStatus: AvailabilityStatus;
  newAvailabilityStatus: AvailabilityStatus;
  originalAskAgainDatetime: Date;
  newAskAgainDatetime: Date | null;
  handleConfirmAvailability: () => void;
}) {
  return (
    <Dialog
      open={props.modalOpen}
      onClose={() => props.closeModal()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Change availability?</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <DialogContentText id="alert-dialog-description">
            {props.originalAvailabilityStatus ===
            props.newAvailabilityStatus ? (
              <>
                You&apos;re updating when you&apos;ll know your availability
                from{" "}
                <strong>
                  {format(props.originalAskAgainDatetime, "PPPP", {
                    locale: enGB,
                  })}
                </strong>{" "}
                to{" "}
                <strong>
                  {format(
                    props.newAskAgainDatetime &&
                      isValid(props.newAskAgainDatetime)
                      ? props.newAskAgainDatetime
                      : new Date(),
                    "PPPP",
                    { locale: enGB },
                  )}
                </strong>
              </>
            ) : (
              <>
                You&apos;re changing your availability from{" "}
                <AvailabilityChip
                  availabilityStatus={props.originalAvailabilityStatus}
                />{" "}
                to{" "}
                <AvailabilityChip
                  availabilityStatus={props.newAvailabilityStatus}
                />
              </>
            )}
          </DialogContentText>
          <DialogContentText>
            This will notify the band manager that you&apos;ve changed your
            availability.
          </DialogContentText>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.closeModal()} color="primary">
          Cancel
        </Button>
        <Button
          onClick={props.handleConfirmAvailability}
          color="primary"
          variant={"contained"}
          autoFocus
        >
          Change availability
        </Button>
      </DialogActions>
    </Dialog>
  );
}
