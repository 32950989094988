import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { User } from "dtos/User";
import { AUTH0_CONFIG } from "auth/auth0Config";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "auth/usePermissions";
import { LogoutOptions } from "@auth0/auth0-spa-js";

export enum Page {
  PLAYER,
  MANAGER,
  ACCOUNT,
}

interface UserMenuProps {
  me: User | null;
  page: Page;
  logout: (logoutOptions: LogoutOptions) => void;
}

/**
 * The menu at the top right that lets users log out and band managers switch between the manager and player views.
 */
export function UserMenu(props: UserMenuProps) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const permissions = usePermissions();
  const canViewManagerUi = permissions.canViewManagerUi();

  const showPlayerItem = props.page !== Page.PLAYER;
  const showManagerItem = props.page !== Page.MANAGER && canViewManagerUi;
  const showAccountItem = props.page !== Page.ACCOUNT;

  const playerItemName = canViewManagerUi ? "Player view" : "Gig list";

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="Main menu"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        <PersonIcon />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {showPlayerItem ? (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              navigate("/player/dashboard");
            }}
          >
            {playerItemName}
          </MenuItem>
        ) : null}
        {showManagerItem ? (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              navigate("/manager");
            }}
          >
            Manager view
          </MenuItem>
        ) : null}
        {showAccountItem ? (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              navigate("/account");
            }}
          >
            Account
          </MenuItem>
        ) : null}

        <MenuItem
          onClick={() => {
            handleMenuClose();
            props.logout({
              clientId: AUTH0_CONFIG.client_id,
              logoutParams: {
                returnTo: `${window.location.protocol}//${window.location.host}`,
              },
            });
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
