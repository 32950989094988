import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Grid, Link, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(5),
    },
    centred: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("md")]: {
      title: {
        fontSize: "18vw",
      },
      logo: {
        width: "100%",
      },
      logoContainer: {
        flex: "0 1 40%",
      },
    },
    [theme.breakpoints.up("md")]: {
      title: {
        fontSize: "8em",
      },
      logo: {
        width: "380px",
      },
      login: {
        fontSize: "1.5vw",
      },
    },
    logo: {
      marginTop: theme.spacing(5),
    },
    login: {
      marginTop: theme.spacing(5),
    },
  }),
);

/**
 * Maybe home's a bad name, but the page shown if the user is logged out.
 */
export function Home(props: { redirect: string }) {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0();

  return (
    <div className={classes.root}>
      <Grid container className={classes.centred}>
        <Grid item>
          <Typography
            color="primary"
            variant="h1"
            display="inline"
            className={classes.title}
          >
            Can You{" "}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.centred}>
        <Grid item>
          <Typography variant="h1" display="inline" className={classes.title}>
            Come
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.centred}>
        <Grid item>
          <Typography
            color="primary"
            variant="h1"
            display="inline"
            className={classes.title}
          >
            To Band
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.centred}>
        <Grid item className={classes.logoContainer}>
          <img src="/cornet.png" alt="Logo" className={classes.logo} />
        </Grid>
      </Grid>
      <Grid container className={classes.centred}>
        <Grid item>
          <Button
            className={classes.login}
            color="primary"
            variant="contained"
            onClick={() =>
              loginWithRedirect({
                appState: {
                  targetUrl: props.redirect,
                },
              })
            }
          >
            Log in
          </Button>
        </Grid>
      </Grid>
      <Box pt={10} />
      <Grid container className={classes.centred}>
        <Grid item>
          <Link href="/privacy" underline="hover">
            Privacy policy
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
