import React from "react";
import { Button, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  AvailabilityStatus,
  AvailabilitySummary,
  RequiredStatus,
} from "domain/availabilityDomain";
import { red } from "@mui/material/colors";
import { AvailabilityEventHistory } from "features/availability/AvailabilityEventHistory";
import { styled } from "@mui/styles";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import { AvailabilityEvent } from "dtos/AvailabilityEvent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacing: {
      marginTop: theme.spacing(2),
    },
    confirmAvailability: {
      marginTop: theme.spacing(2),
    },
    horizontalSpacing: {
      marginLeft: theme.spacing(1),
    },
  }),
);

interface ShowAvailabilityProps {
  availabilityEvents: AvailabilityEvent[];
  availabilityUserId: number;
  editable: boolean;
  availabilityAssumed: boolean;
  onChangeAvailability: () => void;
}

/**
 * Display a summary of the user's availability history, with a button to change it.
 */
export function ShowAvailability({
  availabilityEvents,
  availabilityUserId,
  editable,
  availabilityAssumed,
  onChangeAvailability,
}: ShowAvailabilityProps) {
  const classes = useStyles();

  const summary = new AvailabilitySummary(availabilityEvents);

  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
  }));

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  return (
    <>
      <Accordion>
        <AccordionSummary
          id="availability-summary-accordion"
          aria-controls="availability-history"
          aria-label="Show availability history"
        >
          {
            availabilityAssumed &&
            summary.playerAvailability === AvailabilityStatus.NOT_KNOWN ? (
              <div id="availability-summary">
                You&apos;re assumed to be <strong>available</strong>.
              </div>
            ) : summary.playerAvailability === AvailabilityStatus.AVAILABLE &&
              summary.required === RequiredStatus.REQUIRED ? (
              <div id="availability-summary">
                You&apos;ve said you&apos;re <strong>available</strong>, and
                you&apos;re <strong>required</strong> for this gig.
              </div>
            ) : summary.playerAvailability === AvailabilityStatus.AVAILABLE &&
              notRequired(summary.required) ? (
              <div id="availability-summary">
                You&apos;ve said you&apos;re <strong>available</strong>, but
                you&apos;re <strong>not required</strong> for this gig.
              </div>
            ) : summary.playerAvailability === AvailabilityStatus.UNAVAILABLE &&
              summary.required === RequiredStatus.REQUIRED ? (
              <div id="availability-summary" style={{ color: red[500] }}>
                You&apos;ve said you&apos;re <strong>unavailable</strong>, but
                you&apos;re <strong>required</strong> for this gig!
              </div>
            ) : summary.playerAvailability === AvailabilityStatus.UNAVAILABLE &&
              notRequired(summary.required) ? (
              <div id="availability-summary">
                You&apos;ve said you&apos;re <strong>unavailable</strong>, and
                you&apos;re <strong>not required</strong> for this gig.
              </div>
            ) : summary.playerAvailability ===
                AvailabilityStatus.WILL_KNOW_LATER &&
              summary.required === RequiredStatus.REQUIRED ? (
              <div id="availability-summary" style={{ color: red[500] }}>
                You&apos;ve said <strong>you&apos;ll know later</strong>, but
                you&apos;re <strong>required</strong> for this gig!
              </div>
            ) : summary.playerAvailability ===
                AvailabilityStatus.WILL_KNOW_LATER &&
              notRequired(summary.required) ? (
              <div id="availability-summary">
                You&apos;ve said <strong>you&apos;ll know later</strong>, and
                you&apos;re <strong>not required</strong> for this gig.
              </div>
            ) : summary.playerAvailability === AvailabilityStatus.AVAILABLE ? (
              <div id="availability-summary">
                You&apos;ve said you&apos;re <strong>available</strong>
              </div>
            ) : summary.playerAvailability ===
              AvailabilityStatus.UNAVAILABLE ? (
              <div id="availability-summary">
                You&apos;ve said you&apos;re <strong>unavailable</strong>
              </div>
            ) : summary.playerAvailability ===
              AvailabilityStatus.WILL_KNOW_LATER ? (
              <div id="availability-summary">
                You&apos;ve said <strong>you&apos;ll know later</strong>
              </div>
            ) : (
              <></>
            ) // Shouldn't happen.
          }
        </AccordionSummary>
        <AccordionDetails id="availability-history">
          <AvailabilityEventHistory
            availabilityEvents={availabilityEvents}
            availabilityUserId={availabilityUserId}
            playerView={true}
          />
        </AccordionDetails>
      </Accordion>

      <div>
        {editable ? (
          <Button
            className={classes.spacing}
            variant="contained"
            color="primary"
            onClick={onChangeAvailability}
          >
            Change availability...
          </Button>
        ) : null}
      </div>
    </>
  );

  function notRequired(requiredStatus: RequiredStatus) {
    return (
      requiredStatus === RequiredStatus.REPLACED_WITH_DEP ||
      requiredStatus === RequiredStatus.WILL_NOT_FILL
    );
  }
}
