export function getHtmlInputElement(
  formControl: HTMLDivElement,
): HTMLInputElement {
  // TODO: how to get the actual input HTML element? If Material-UI changes, this will break.
  if (formControl.children[0].children[0] instanceof HTMLInputElement) {
    return formControl.children[0].children[0];
  } else if (formControl.children[1].children[0] instanceof HTMLInputElement) {
    return formControl.children[1].children[0];
  } else {
    throw Error("Couldn't find HTMLInputElement");
  }
}
