import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/rootReducer";

interface Auth {
  token: string | null;
}

const initialState: Auth = {
  token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    tokenReceived(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
  },
});

export const selectToken = (state: RootState) => state.auth.token;

export const { tokenReceived } = authSlice.actions;
export const reducer = authSlice.reducer;
