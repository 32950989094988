export interface Role {
  id: number;
  name: string;
  description: string;
  permissions: RolePermission[];
}

export interface RolePermission {
  permission: Permission;
}

export type Permission =
  | "UPDATE_GIG"
  | "DELETE_GIG"
  | "RETRIEVE_DRAFT_GIG"
  | "RETRIEVE_AVAILABILITY"
  | "UPDATE_AVAILABILITY"
  | "DELETE_AVAILABILITY"
  | "RETRIEVE_DEP"
  | "UPDATE_DEP"
  | "DELETE_DEP"
  | "RETRIEVE_INVITATION"
  | "UPDATE_INVITATION"
  | "DELETE_INVITATION"
  | "RETRIEVE_TAG"
  | "UPDATE_TAG"
  | "DELETE_TAG"
  | "UPDATE_PLAYER"
  | "RETRIEVE_PLAYER"
  | "DELETE_PLAYER"
  | "RETRIEVE_SEAT"
  | "UPDATE_SEAT"
  | "DELETE_SEAT"
  | "GETS_REGULAR_EMAIL_UPDATE"
  | "GETS_URGENT_EMAIL_UPDATE";

// TODO: bit of a hack.
export const PLAYER_ROLE = 1;
export const MANAGER_ROLE = 2;
export const AVAILABILITY_MANAGER_ROLE = 3;
