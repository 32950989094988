import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { fullName } from "features/users/userUtils";
import { Dep } from "dtos/Dep";
import { bannerCheckboxStylesHook } from "features/deps/BannerCheckbox";
import { useAppSelector } from "app/store";
import { byId } from "utils/utils";
import {
  useGetDepsQuery,
  useGetInstrumentsQuery,
  useGetSeatsQuery,
  useGetUsersQuery,
} from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { selectBandId } from "features/users/usersSlice";

interface ChooseDepsProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  seat: number;
  confirmSelection: (depIds: number[]) => void;
  userBlacklist: number[];
}

/**
 * Modal dialogue that lets a band manager choose the deps they want to use for a seat.
 */
export function ChooseDeps(props: ChooseDepsProps) {
  const bandId = useAppSelector(selectBandId);
  const { data: deps = [], isLoading: isDepsLoading } = useGetDepsQuery(
    bandId ?? skipToken,
  );
  const { data: allSeats = [] } = useGetSeatsQuery(bandId ?? skipToken);
  const seat = byId(allSeats)[props.seat];
  const { data: allInstruments = [] } = useGetInstrumentsQuery();
  const instrumentsById = byId(allInstruments);
  const instrument = seat && instrumentsById[seat.instrument];
  const { data: users = [], isLoading: isUsersLoading } = useGetUsersQuery(
    bandId ?? skipToken,
  );
  const usersById = byId(users);
  const loading = isDepsLoading || isUsersLoading;

  const filteredDeps = deps
    .filter((dep) =>
      dep.instruments.some(
        (depInstrument) => depInstrument.instrument === instrument?.id,
      ),
    )
    .sort((a, b) =>
      fullName(usersById[a.user]).localeCompare(fullName(usersById[b.user])),
    );

  const [selected, setSelected] = useState<{ [depId: number]: boolean }>({});
  const selectedDepIds = Object.keys(selected)
    .map((depId) => parseInt(depId))
    .filter((depId) => selected[depId]);

  const updateSelected = (depId: number, isSelected: boolean) => {
    setSelected({
      ...selected,
      [depId]: isSelected,
    });
  };
  const doConfirm = () => {
    close();
    props.confirmSelection(selectedDepIds);
  };
  const doCancel = () => {
    close();
  };
  const close = () => {
    props.setOpen(false);
    setSelected({});
  };

  return loading ? null : (
    <Dialog
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="choose-deps-title"
      aria-describedby="choose-deps-description"
    >
      <DialogTitle id="choose-deps-title">
        Choose deps for this seat
      </DialogTitle>
      <DialogContent>
        {filteredDeps.length === 0 ? (
          <DialogContentText id="choose-deps-description">
            You have no deps that can fill this seat.
          </DialogContentText>
        ) : (
          <>
            <DialogContentText id="choose-deps-description">
              Select the deps you want to use to fill this seat:
            </DialogContentText>
            <FormGroup>
              {filteredDeps.map((dep) => (
                <DepRow
                  key={dep.id}
                  dep={dep}
                  selected={selected[dep.id] || false}
                  updateSelected={updateSelected}
                />
              ))}
            </FormGroup>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={doCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={doConfirm}
          color="primary"
          variant="contained"
          autoFocus
        >
          {filteredDeps.length === 0 ? "Replace anyway" : "Choose"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface DepRowProps {
  dep: Dep;
  selected: boolean;
  updateSelected: (depId: number, selected: boolean) => void;
}

function DepRow(props: DepRowProps) {
  const { dep, selected, updateSelected } = props;

  const checkboxStyles = bannerCheckboxStylesHook.useCheckbox();
  const formControlLabelStyles = bannerCheckboxStylesHook.useFormControlLabel(
    {},
  );
  const subtitleStyles = bannerCheckboxStylesHook.useSubtitle();
  const bandId = useAppSelector(selectBandId);
  const { data: users = [] } = useGetUsersQuery(bandId ?? skipToken);
  const user = byId(users)[dep.user];

  return (
    <FormControlLabel
      classes={formControlLabelStyles}
      control={
        <Checkbox
          name={dep.id.toString()}
          checked={selected}
          onChange={(event) => updateSelected(dep.id, event.target.checked)}
          classes={checkboxStyles}
        />
      }
      label={
        <>
          {fullName(user)}
          <Typography component="span" classes={subtitleStyles}>
            {dep.notes}
          </Typography>
        </>
      }
    />
  );
}
