import { Permission, Role } from "dtos/Role";
import { Player } from "dtos/Player";
import { useAppSelector } from "app/store";
import { selectMyPlayer, selectRoles } from "features/users/usersSlice";

export interface Permissions {
  hasPermission: (
    permission: Permission,
    player?: Player | null | undefined,
  ) => boolean;
  // TODO: probably temporary while we convert everything over?
  //  We decide a player is what once was a "manager" if they are allowed to update players (meaning they have the power
  //  to make another player a "manager").
  isManager: (player?: Player | null | undefined) => boolean;
  /**
   * True if the user has at least one permission that needs the manager UI to use.
   */
  canViewManagerUi: (player?: Player | null | undefined) => boolean;
  /**
   * The seats the user manages, or undefined if the user isn't allowed to manage seats at all, or empty array if the
   * user can manage any seat (i.e. they're a full "band manager").
   */
  managesSeats: (player?: Player | null | undefined) => number[] | undefined;
}

export function usePermissions(): Permissions {
  const myPlayer: Player | null | undefined = useAppSelector(selectMyPlayer);
  const roles = useAppSelector(selectRoles);
  return {
    hasPermission: (
      permission: Permission,
      player: Player | null | undefined = myPlayer,
    ) => hasPermission(permission, player, roles),
    isManager: (player: Player | null | undefined = myPlayer) =>
      hasPermission("UPDATE_PLAYER", player, roles),
    canViewManagerUi: (player: Player | null | undefined = myPlayer) =>
      canViewManagerUi(player, roles),
    managesSeats: (player: Player | null | undefined = myPlayer) =>
      managesSeats(player),
  };
}

const hasPermission = (
  permission: Permission,
  player: Player | null | undefined,
  roles: { [id: number]: Role } | undefined,
) => {
  if (player === undefined || player === null || roles === undefined) {
    return false;
  }
  const role = roles[player.role];
  if (role === undefined) {
    return false;
  }
  return role.permissions.some((p) => p.permission === permission);
};

const canViewManagerUi = (
  player: Player | null | undefined,
  roles: { [id: number]: Role } | undefined,
) => {
  return MANAGER_UI_PERMISSIONS.some((permission) =>
    hasPermission(permission, player, roles),
  );
};

const managesSeats = (
  player: Player | null | undefined,
): number[] | undefined => {
  if (player === undefined || player === null) {
    return undefined;
  }
  return JSON.parse(player.roleMetadata).manages_seats;
};

// Potentially some other permissions needed here as we add new roles.
const MANAGER_UI_PERMISSIONS: Permission[] = [
  "UPDATE_GIG",
  "DELETE_GIG",
  "UPDATE_AVAILABILITY",
  "DELETE_AVAILABILITY",
  "UPDATE_DEP",
  "DELETE_DEP",
  "UPDATE_INVITATION",
  "DELETE_INVITATION",
  "UPDATE_TAG",
  "DELETE_TAG",
  "UPDATE_PLAYER",
  "DELETE_PLAYER",
  "UPDATE_SEAT",
  "DELETE_SEAT",
  "RETRIEVE_DRAFT_GIG", // Needed for read-only manager
];
