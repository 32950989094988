// Adapted from mui-treasury for MUI 5

import makeStyles from "@mui/styles/makeStyles";
import {
  bannerCheckboxStyles,
  bannerFormControlLabelStyles,
  bannerSubtitleStyles,
} from "features/deps/BannerCheckboxStyle";

const bannerCheckboxStylesHook = {
  useCheckbox: makeStyles(bannerCheckboxStyles, {
    name: "BannerCheckbox",
  }),
  useFormControlLabel: makeStyles(bannerFormControlLabelStyles, {
    name: "BannerCheckbox",
  }),
  useSubtitle: makeStyles(bannerSubtitleStyles, {
    name: "BannerCheckbox",
  }),
};

export {
  bannerCheckboxStyles,
  bannerFormControlLabelStyles,
  bannerSubtitleStyles,
  bannerCheckboxStylesHook,
};
