import React from "react";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { EventInstanceForGig } from "dtos/EventInstance";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

interface DayProps {
  day: Date;
  includeWayOfWeek: boolean;
  includeMonth: boolean;
  today: boolean;
  events: EventInstanceForGig[];
}

export function Day({
  day,
  includeWayOfWeek,
  includeMonth,
  today,
  events,
}: DayProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dateClass = today ? classes.today : "";
  return (
    <div className={classes.day}>
      <div className={classes.title}>
        <div style={{ textTransform: "uppercase" }}>
          {includeWayOfWeek && format(day, "E", { locale: enGB })}
        </div>
        <div className={dateClass}>
          <div>
            {format(day, includeMonth ? "d LLL" : "d", { locale: enGB })}
          </div>
        </div>
      </div>
      <div>
        {events.map((event) => (
          <Button
            key={`${event.gig}/${event.eventInstance.startDatetime}`}
            variant="contained"
            color="secondary"
            className={classes.event}
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                `/manager/rehearsals/${event.gig}/${event.eventInstance.startDatetime}`,
              );
            }}
          >
            {event.eventInstance.name}
          </Button>
        ))}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    day: {
      fontSize: "70%",
      fontWeight: "bold",
      color: "#666",
    },
    title: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: "8px",
    },
    today: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      color: "#fff",
      height: "24px",
      width: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    event: {
      width: "100%",
      padding: "4px 8px",
      fontSize: "100%",
      fontWeight: "bold",
      textTransform: "none",
      justifyContent: "flex-start",
    },
  }),
);
