import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useState } from "react";
import { Availability } from "dtos/Availability";
import { useGetSeatsQuery, useGetUsersQuery } from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { byId } from "utils/utils";
import { fullName } from "features/users/userUtils";
import { useAppSelector } from "app/store";
import { selectBandId } from "features/users/usersSlice";

interface ChangeSeatProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  availability: Availability;
  confirmSelection: (availability: Availability, seatId: number) => void;
}

/**
 * Modal dialogue that lets a band manager change the seat a user is playing in for a given gig or rehearsal.
 */
export function ChangeSeat({
  open,
  setOpen,
  availability,
  confirmSelection,
}: ChangeSeatProps) {
  const bandId = useAppSelector(selectBandId);
  const { data: users = [] } = useGetUsersQuery(bandId ?? skipToken);
  const usersById = byId(users);
  const { data: allSeats = [] } = useGetSeatsQuery(bandId ?? skipToken);
  const name = fullName(usersById[availability.user]);
  const [seat, setSeat] = useState(availability.seat);

  const doConfirm = () => {
    close();
    confirmSelection(availability, seat);
  };
  const doCancel = () => {
    close();
  };
  const close = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="choose-seat-title"
    >
      <DialogTitle id="choose-seat-title">Change seat for {name}</DialogTitle>
      <DialogContent>
        <InputLabel id="seat-select-label">New seat</InputLabel>
        <Select
          labelId="seat-select-label"
          id="seat-select"
          value={seat}
          label="Seat"
          onChange={(event: SelectChangeEvent<number>) =>
            setSeat(event.target.value as number)
          }
        >
          {allSeats
            .filter((seat) => !seat.deleted)
            .map((seat) => (
              <MenuItem key={seat.id} value={seat.id}>
                {seat.name}
              </MenuItem>
            ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={doCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={doConfirm}
          color="primary"
          variant="contained"
          disabled={seat === availability.seat}
          autoFocus
        >
          Change seat
        </Button>
      </DialogActions>
    </Dialog>
  );
}
