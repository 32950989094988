import List from "@mui/material/List";
import React from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { GigList } from "features/manager/GigList";
import { GigFilter } from "features/filter/GigFilter";
import { ListItemLink } from "features/misc/ListItemLink";
import { usePermissions } from "auth/usePermissions";
import DashboardIcon from "@mui/icons-material/Dashboard";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import Loop from "@mui/icons-material/Loop";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AddCircle from "@mui/icons-material/AddCircle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
    },
    input: {
      display: "none",
    },
    spacer: {
      marginTop: theme.spacing(3),
    },
  }),
);

/**
 * The sidebar of the manager view.
 */
export function SideBar() {
  const classes = useStyles();
  const permissions = usePermissions();
  const canCreateNewGigs = permissions.hasPermission("UPDATE_GIG");

  return (
    <div>
      <GigFilter />
      <List>
        <ListItemLink
          to="/manager/dashboard"
          primary="Dashboard"
          icon={<DashboardIcon />}
        />
        <ListItemLink
          to="/manager/gigs/multi"
          primary="Gig grid"
          icon={<QueueMusicIcon />}
        />
        <ListItemLink
          to="/manager/rehearsals"
          primary="Rehearsal grid"
          icon={<Loop />}
        />
        <ListItemLink
          to="/manager/calendar"
          primary="Rehearsal calendar"
          urls="/manager/calendar/*"
          icon={<CalendarMonthIcon />}
        />
        {canCreateNewGigs && (
          <ListItemLink
            to="/manager/gigs/new"
            primary="New gig&hellip;"
            icon={<AddCircle />}
          />
        )}

        <div className={classes.spacer} />

        <GigList />
      </List>
    </div>
  );
}
