import { combineReducers } from "@reduxjs/toolkit";
import { reducer as authReducer } from "auth/authSlice";
import { reducer as filterReducer } from "features/filter/filterSlice";
import { reducer as playerFilterReducer } from "features/filter/playerFilterSlice";
import { reducer as flashReducer } from "redux-flash";
import { apiSlice } from "api/apiSlice";

export const rootReducer = combineReducers({
  auth: authReducer,
  filter: filterReducer,
  playerFilter: playerFilterReducer,
  flash: flashReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
