import React from "react";
import { Grid, Theme, Typography } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(5),
    },
    centred: {
      justifyContent: "center",
    },
  }),
);

export function Privacy() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={1} lg={3} />

        <Grid item xs={10} lg={6}>
          <Typography variant="h3">Privacy Policy</Typography>

          <p>Last updated: January 27, 2022</p>

          <p>
            This page informs you of our policies regarding the collection, use
            and disclosure of Personal Information when you use our Service.
          </p>

          <p>
            We will not use or share your information with anyone except as
            described in this Privacy Policy.
          </p>

          <p>
            We use your Personal Information for providing and improving the
            Service. By using the Service, you agree to the collection and use
            of information in accordance with this policy. Unless otherwise
            defined in this Privacy Policy, terms used in this Privacy Policy
            have the same meanings as in our Terms and Conditions, accessible at
            https://canyoucometoband.com
          </p>

          <Typography variant="h5">Information Collection And Use</Typography>

          <p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you. Personally identifiable information may include, but
            is not limited to, your email address, name (&quot;Personal
            Information&quot;).
          </p>

          <Typography variant="h5">Log Data</Typography>

          <p>
            We may also collect information that your browser sends whenever you
            visit our Service (&quot;Log Data&quot;). This Log Data may include
            information such as your computer&apos;s Internet Protocol
            (&quot;IP&quot;) address, browser type, browser version, the pages
            of our Service that you visit, the time and date of your visit, the
            time spent on those pages and other statistics.
          </p>

          <Typography variant="h5">Cookies</Typography>

          <p>
            Cookies are files with a small amount of data, which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            web site and stored on your computer&apos;s hard drive.
          </p>

          <p>
            We use &quot;cookies&quot; to collect information. You can instruct
            your browser to refuse all cookies or to indicate when a cookie is
            being sent. However, if you do not accept cookies, you may not be
            able to use some portions of our Service.
          </p>

          <Typography variant="h5">Service Providers</Typography>

          <p>
            We may employ third party companies and individuals to facilitate
            our Service, to provide the Service on our behalf, to perform
            Service-related services or to assist us in analyzing how our
            Service is used.
          </p>

          <p>
            These third parties have access to your Personal Information only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>

          <Typography variant="h5">Compliance With Laws</Typography>

          <p>
            We will disclose your Personal Information where required to do so
            by law or in accordance with an order of a court of competent
            jurisdiction, or if we believe that such action is necessary to
            comply with the law and the reasonable requests of law enforcement
            or to protect the security or integrity of our Service.
          </p>

          <Typography variant="h5">Security</Typography>

          <p>
            The security of your Personal Information is important to us, but
            remember that no method of transmission over the Internet, or method
            of electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Information,
            we cannot guarantee its absolute security. As such we make no
            warranties as to the level of security afforded to your data, except
            that we will always act in accordance with the relevant UK and EU
            legislation.
          </p>

          <Typography variant="h5">International Transfer</Typography>

          <p>
            Your information, including Personal Information, may be transferred
            to — and maintained on — computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ than those from your jurisdiction.
          </p>

          <p>
            If you are located outside United Kingdom and choose to provide
            information to us, please note that we transfer the information,
            including Personal Information, to United Kingdom and process it
            there.
          </p>

          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>

          <p>
            In the event that a dispute arises with regards to the international
            transfer of data, you agree that the courts of England and Wales
            shall have exclusive jurisdiction over the matter.
          </p>

          <Typography variant="h5">Links To Other Sites</Typography>

          <p>
            Our Service may contain links to other sites that are not operated
            by us. If you click on a third party link, you will be directed to
            that third party&apos;s site. We strongly advise you to review the
            Privacy Policy of every site you visit.
          </p>

          <p>
            We have no control over, and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>

          <Typography variant="h5">Children&apos;s Privacy</Typography>

          <p>
            Our Service does not address anyone under the age of 13
            (&quot;Children&quot;).
          </p>

          <p>
            We do not knowingly collect personally identifiable information from
            children under 13. If you are a parent or guardian and you are aware
            that your Children has provided us with Personal Information, please
            contact us. If we become aware that we have collected Personal
            Information from a children under age 13 without verification of
            parental consent, we take steps to remove that information from our
            servers.
          </p>

          <Typography variant="h5">Deleting your data</Typography>

          <p>
            You can delete all personally identifiable information we hold about
            you by deleting your account, which you can do from the account
            settings page.
          </p>

          <p>
            For more information about deleting your account, see the{" "}
            <a href="/how-to-delete-your-account">
              &quot;How to delete your account&quot; page.
            </a>
          </p>

          <Typography variant="h5">Changes To This Privacy Policy</Typography>

          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>

          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>

          <p>
            If we make any material changes to this Privacy Policy, we will
            notify you either through the email address you have provided us, or
            by placing a prominent notice on our website.
          </p>

          <Typography variant="h5">Jurisdiction</Typography>

          <p>
            This Policy shall be governed and construed in accordance with the
            laws of England and Wales, without regard to its conflict of law
            provisions.
          </p>

          <Typography variant="h5">Contact Us</Typography>

          <p>
            If you have any questions about this Privacy Policy, please contact
            us.
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
