import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/rootReducer";
import { FilterCriteria } from "features/filter/filterCriteria";

const initialState: FilterCriteria = {
  year: "future",
  tags: [],
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setYear(state, action: PayloadAction<number | "future" | "yearSoFar">) {
      state.year = action.payload;
    },
    setTags(state, action: PayloadAction<number[]>) {
      state.tags = action.payload;
    },
  },
});

export const { setYear, setTags } = filterSlice.actions;
export const reducer = filterSlice.reducer;

export const selectFilterState = (state: RootState) => state.filter;
