import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/rootReducer";
import { FilterCriteria } from "features/filter/filterCriteria";

const initialState: FilterCriteria = {
  year: "future",
};

/**
 * Differentiated from the manager filter because we probably want a user who's a player and a manager to be able to use
 * each filter independently.
 */
const filterSlice = createSlice({
  name: "playerFilter",
  initialState,
  reducers: {
    setYear(state, action: PayloadAction<number | "future">) {
      state.year = action.payload;
    },
  },
});

export const { setYear } = filterSlice.actions;
export const reducer = filterSlice.reducer;

export const selectPlayerFilterState = (state: RootState) => state.playerFilter;
