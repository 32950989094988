import React from "react";
import { Box, Tab, Tabs, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useAppDispatch, useAppSelector } from "app/store";
import {
  selectPlayerFilterState,
  setYear,
} from "features/filter/playerFilterSlice";
import { selectBandId, selectMe } from "features/users/usersSlice";
import { ResponseRank } from "features/player/ResponseRank";
import { useGetGigsQuery, useGetPlayerAvailabilitiesQuery } from "api/apiSlice";
import { User } from "dtos/User";
import { useYearsActive } from "features/misc/yearsActive";
import { PlayerAvailabilitySummary } from "features/player/PlayerAvailabilitySummary";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1000,
    },
    paper: {
      margin: theme.spacing(2),
    },
    noGigs: {
      margin: theme.spacing(3),
    },
    key: {
      display: "flex",
      justifyContent: "flex-end",
      flexWrap: "wrap",
      paddingRight: "0.2em",
      fontSize: "80%",
      color: "#666",
    },
    keySet: {
      display: "flex",
      paddingBottom: "0.5em",
    },
    keyItem: {
      paddingRight: "10px",
      display: "flex",
      alignItems: "center",
    },
  }),
);

/**
 * Wraps the player availability summary in tabs to select the year.
 */
export function PlayerAvailabilitySummaryPage() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const filterCriteria = useAppSelector(selectPlayerFilterState);

  const handleDateChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setYear(newValue));
  };

  const bandId = useAppSelector(selectBandId);
  const me: User | null = useAppSelector(selectMe);

  const { data: gigs = [] } = useGetGigsQuery(
    { bandId: bandId === undefined ? 0 : bandId, filterCriteria },
    { skip: bandId === undefined },
  );
  const { data: availabilities = [] } = useGetPlayerAvailabilitiesQuery(
    { userId: me === null ? 0 : me.id, filterCriteria },
    { skip: me === null },
  );

  const gigAvailabilities = availabilities.filter((a) =>
    gigs.some((g) => g.id === a.gig && !g.rehearsal),
  );

  const years = useYearsActive();

  return (
    <div className={classes.root}>
      {gigAvailabilities.length === 0 || (
        <Tabs
          variant="scrollable"
          scrollButtons={false}
          value={filterCriteria.year}
          onChange={handleDateChange}
          aria-label="basic tabs example"
        >
          <Tab label="Future" {...a11yProps("future")} />
          {years.map((year) => {
            return <Tab key={year} label={year} {...a11yProps(year)} />;
          })}
        </Tabs>
      )}

      <Box pb={2} />

      <PlayerAvailabilitySummary />

      <ResponseRank />
    </div>
  );
}

function a11yProps(index: number | "future") {
  return {
    id: `tab-${index}`,
    value: index,
    "aria-controls": `year-${index}`,
  };
}
