import React from "react";
import { Link, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ShowAndSetAvailability } from "features/player/ShowAndSetAvailability";
import { DatetimeSpan } from "features/misc/DatetimeSpan";
import { getEmbedUrl } from "features/misc/googleMapsUtils";
import ReactMarkdown from "react-markdown";
import { GigLineupTable } from "features/player/GigLineupTable";
import { isPastEvent } from "utils/domain";
import { useAppSelector } from "app/store";
import { useNavigate, useParams } from "react-router-dom";
import { useGetGigQuery, useGetPlayerAvailabilitiesQuery } from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { User } from "dtos/User";
import { selectMe } from "features/users/usersSlice";
import { getYear } from "date-fns";
import { EventInstance } from "dtos/EventInstance";
import { useGetEventInstanceQuery } from "api/eventInstances";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1000,
      padding: theme.spacing(2),
    },
    availability: {
      marginTop: theme.spacing(3),
    },
    confirmAvailability: {
      marginTop: theme.spacing(2),
    },
    mapContainer: {
      marginTop: theme.spacing(3),
      position: "relative",
      overflow: "hidden",
      paddingTop: "75%",
      "@media (min-width: 600px)": {
        paddingTop: "450px",
      },
    },
    map: {
      border: 0,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      maxWidth: "600px",
      maxHeight: "450px",
    },
    spacer: {
      paddingTop: theme.spacing(1),
    },
  }),
);

/**
 * Shows the details of a given gig or rehearsal to a player, and allows them to either provide or change their
 * availability.
 */
export function PlayerEventDetails() {
  const params = useParams();
  const gigId = params.gigId === undefined ? null : parseInt(params.gigId);
  // TODO: what if somebody reschedules an event to the same time as another recurrence of it?
  const startDatetime =
    params.startDatetime === undefined ? null : parseInt(params.startDatetime);
  const { data: gig, error } = useGetGigQuery(gigId ?? skipToken);
  const { eventInstanceForGigs: rehearsals } =
    useGetEventInstanceQuery(startDatetime);
  const eventInstance = rehearsals?.find(
    (r) => r.gig === gigId && r.eventInstance.startDatetime === startDatetime,
  )?.eventInstance;

  return error && "status" in error && error.status === 404 ? (
    <NotFound />
  ) : gigId && gig && (startDatetime === null || eventInstance) ? (
    <PlayerEventDetailsInternal
      gigId={gigId}
      eventInstance={eventInstance || gig}
      availabilityAssumed={gig.rehearsal}
    />
  ) : null;
}

function NotFound() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h3">Not found</Typography>
      <Typography sx={{ pt: 2 }}>
        Looks like that gig can&apos;t be found.
      </Typography>
      <Typography sx={{ pt: 2 }}>
        Perhaps it never existed and you have a bad link. Or perhaps your band
        manager has since deleted or hidden the gig.
      </Typography>
      <Typography sx={{ pt: 2 }}>
        <Link href="/player/gigs" underline="hover">
          Return to the gigs overview
        </Link>
      </Typography>
    </div>
  );
}

function PlayerEventDetailsInternal({
  gigId,
  eventInstance,
  availabilityAssumed,
}: {
  gigId: number;
  eventInstance: EventInstance;
  availabilityAssumed: boolean;
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const pastGig = isPastEvent(eventInstance);

  const me: User | null = useAppSelector(selectMe);
  const { data: allAvailabilities = [], isLoading } =
    useGetPlayerAvailabilitiesQuery(
      {
        userId: me === null ? 0 : me.id,
        filterCriteria: { year: getYear(eventInstance.startDatetime) },
      },
      { skip: me === null },
    );

  const existingAvailability = allAvailabilities.find(
    (availabilities) =>
      availabilities.gig === gigId &&
      availabilities.startDatetime === eventInstance.startDatetime,
  );

  const env = { ...process.env, ...window.process?.env };
  const noGoogleMaps = env.REACT_APP_CYPRESS_TESTS === "true";

  return (
    <>
      <Typography variant="h3">{eventInstance.name}</Typography>

      <Typography variant="h4" color="textSecondary">
        <DatetimeSpan datetime={eventInstance} />
      </Typography>

      <div className={classes.spacer} />

      <p>
        {eventInstance.place.name}
        <br />
        {eventInstance.place.address}
      </p>

      {!noGoogleMaps && eventInstance.place.id && (
        <div className={classes.mapContainer}>
          <iframe
            title="Map"
            className={classes.map}
            frameBorder="0"
            src={getEmbedUrl(eventInstance.place.id, 15)}
            allowFullScreen
          />
        </div>
      )}

      <ReactMarkdown>{eventInstance.details}</ReactMarkdown>

      <GigLineupTable
        gigId={gigId}
        startDatetime={eventInstance.startDatetime}
      />

      <div className={classes.availability}>
        {!isLoading && (
          <ShowAndSetAvailability
            availabilityId={existingAvailability?.id}
            gigId={gigId}
            startDatetime={eventInstance.startDatetime}
            availabilityEvents={existingAvailability?.events}
            availabilityUserId={existingAvailability?.user}
            editable={!pastGig}
            availabilityAssumed={availabilityAssumed}
            onConfirm={() => {
              if (availabilityAssumed) {
                navigate("/player/rehearsals");
              } else {
                navigate("/player/gigs");
              }
            }}
          />
        )}
      </div>
    </>
  );
}
