import React from "react";
import { Grid, Theme, Typography } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(5),
    },
    centred: {
      justifyContent: "center",
    },
  }),
);

export function HowToDeleteYourAccount() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={1} lg={3} />

        <Grid item xs={10} lg={6}>
          <Typography variant="h3">How to delete your account</Typography>

          <p>
            You can delete your account from the account settings page. To
            access the account settings page, log in to your account, click your
            name in the top right, and choose &quot;Account&quot;. You can also
            follow <a href="/account">this link</a> (which will ask you to log
            in if you aren&apos;t already).
          </p>
          <p>
            From your account settings page, click the &quot;Delete
            account&quot; button and follow the confirmation instructions.{" "}
            <strong>This action can&apos;t be undone</strong>.
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
