import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useMatch,
} from "react-router-dom";
import { Box, ListItemIcon, ListItemText } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";

interface ListItemLinkProps {
  icon?: React.ReactElement | null;
  primary: string;
  to: string;
  className?: string;
  /**
   * A wildcarded URL to use to match a set of URLs if this list item should be highlighted for all of them.
   */
  urls?: string;
}

export function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to, urls, ...rest } = props;

  const match = useMatch(urls ?? to);

  const forwardRef = React.forwardRef<never, Omit<RouterLinkProps, "to">>(
    (itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />,
  );
  forwardRef.displayName = "RouterLink";
  const renderLink = React.useMemo(() => forwardRef, [to]);

  return (
    <li>
      <ListItemButton
        component={renderLink}
        selected={match !== null}
        {...rest}
      >
        {icon ? (
          <ListItemIcon sx={{ minWidth: "50px" }}>{icon}</ListItemIcon>
        ) : (
          <Box sx={{ minWidth: "50px" }} />
        )}
        <ListItemText primary={primary} />
      </ListItemButton>
    </li>
  );
}
