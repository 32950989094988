import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import {
  AvailabilityStatus,
  AvailabilitySummary,
  RequiredStatus,
} from "domain/availabilityDomain";
import CancelIcon from "@mui/icons-material/Cancel";
import UpdateIcon from "@mui/icons-material/Update";
import { Person } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { grey, teal, red } from "@mui/material/colors";
import { useTheme } from "@mui/material";

interface AvailabilitySummaryProps {
  availabilitySummary: AvailabilitySummary;
  gigCollecting: boolean;
  player: boolean;
  /**
   * True to dim all icons except for "required", to make it easier to pick out required versus everything else at a
   * glance.
   */
  dim: boolean;
  /**
   * A tooltip will show the availability summary in text format. If not present, this will simply be "Required",
   * "Replaced with dep" etc. If present, a function that is passed this summary text and can transform it as
   * required (in order to add additional information, for example).
   */
  tooltip?: (summary: string) => string;
}

const useStyles = makeStyles(() =>
  createStyles({
    double: {
      whiteSpace: "nowrap",
    },
  }),
);

/**
 * Returns an icon to represent the status of a player's availability: a cross, a tick, an hourglass, etc.
 */
export function AvailabilitySummaryIcon(props: AvailabilitySummaryProps) {
  const { availabilitySummary, gigCollecting, player } = props;
  const template: (summary: string) => string =
    props.tooltip !== undefined ? props.tooltip : (summary) => summary;
  const classes = useStyles();
  const theme = useTheme();

  const tealColour = props.dim
    ? { color: teal[200] }
    : { color: theme.palette.primary.main };
  const redColour = props.dim ? { color: red[200] } : { color: red[500] };
  const blackColour = props.dim ? { color: grey[400] } : { color: "#000" };

  return availabilitySummary.required === RequiredStatus.REQUIRED &&
    availabilitySummary.playerAvailability === AvailabilityStatus.AVAILABLE ? (
    <CheckCircleIcon color="primary" titleAccess={template("Required")} />
  ) : availabilitySummary.required === RequiredStatus.REQUIRED &&
    availabilitySummary.playerAvailability !== AvailabilityStatus.AVAILABLE ? (
    <CheckCircleIcon
      style={redColour}
      titleAccess={template("Required but unavailable")}
    />
  ) : player &&
    (availabilitySummary.required === RequiredStatus.REPLACED_WITH_DEP ||
      availabilitySummary.required === RequiredStatus.WILL_NOT_FILL) ? (
    <CancelIcon style={tealColour} titleAccess={template("Not required")} />
  ) : !player &&
    availabilitySummary.required === RequiredStatus.REPLACED_WITH_DEP ? (
    <Person style={tealColour} titleAccess={template("Replaced with dep")} />
  ) : !player &&
    availabilitySummary.required === RequiredStatus.WILL_NOT_FILL ? (
    <span className={classes.double}>
      <DeleteIcon style={tealColour} titleAccess={template("Not required")} />
      &nbsp;
      {availabilitySummary.playerAvailability ===
      AvailabilityStatus.AVAILABLE ? (
        <CheckIcon
          style={blackColour}
          titleAccess={template('Available" fontSize="small" color="disabled')}
        />
      ) : null}
    </span>
  ) : availabilitySummary.playerAvailability ===
    AvailabilityStatus.AVAILABLE ? (
    <CheckIcon style={blackColour} titleAccess={template("Available")} />
  ) : availabilitySummary.playerAvailability ===
    AvailabilityStatus.UNAVAILABLE ? (
    <ClearRoundedIcon
      style={blackColour}
      titleAccess={template("Unavailable")}
    />
  ) : availabilitySummary.playerAvailability ===
    AvailabilityStatus.WILL_KNOW_LATER ? (
    <UpdateIcon style={blackColour} titleAccess={template("Will know later")} />
  ) : availabilitySummary.waitingForAvailability ||
    (availabilitySummary.noEvents && gigCollecting) ? (
    <HourglassEmptyIcon
      style={blackColour}
      titleAccess={template("Waiting for availability")}
    />
  ) : null;
}
