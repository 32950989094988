import React, { useState } from "react";
import { useAppSelector } from "app/store";
import { format, formatDistanceToNow } from "date-fns";
import {
  Fade,
  FormControlLabel,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Skeleton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Gig } from "dtos/Gig";
import { byId, plural } from "utils/utils";
import { selectBandId } from "features/users/usersSlice";
import {
  AvailabilityStatus,
  AvailabilitySummary,
  RequiredStatus,
} from "domain/availabilityDomain";
import { Availability } from "dtos/Availability";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Theme } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { grey, red } from "@mui/material/colors";
import { TooltippedComponent } from "utils/TooltippedComponent";
import { enGB } from "date-fns/locale";
import {
  useGetGigsQuery,
  useGetManagerAvailabilitiesQuery,
  useGetSeatsQuery,
} from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { usePermissions } from "auth/usePermissions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    upcomingGigs: {
      marginTop: theme.spacing(2),
    },
    tableHeader: {
      backgroundColor: theme.palette.primary.light,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    tableHeaderCell: {
      color: theme.palette.primary.contrastText,
    },
    tableRowWide: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    tableRowNarrow: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    dateText: {
      fontSize: "80%",
      color: "#888",
      whiteSpace: "nowrap",
      display: "inline-block",
    },
    detailText: {
      fontSize: "80%",
      whiteSpace: "nowrap",
      display: "inline-block",
    },
    requiredButUnavailable: {
      fontSize: "80%",
      color: "#f88",
    },
    exampleSeats: {
      fontSize: "80%",
      color: "#888",
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    statusIcon: {
      width: theme.spacing(4),
    },
  }),
);

export function Dashboard() {
  const classes = useStyles();
  const bandId = useAppSelector(selectBandId);
  const permissions = usePermissions();
  const canCreateNewGigs = permissions.hasPermission("UPDATE_GIG");

  const [showHiddenGigs, setShowHiddenGigs] = useState(true);

  const now = new Date().getTime();
  const { data: allGigs = [], isLoading } = useGetGigsQuery(
    {
      bandId: bandId === undefined ? 0 : bandId,
      filterCriteria: { year: "future", tags: [] },
    },
    { skip: bandId === undefined },
  );
  const upcomingGigs = allGigs
    .filter((gig) => gig.startDatetime > now)
    .filter((gig) => showHiddenGigs || !gig.draft)
    .sort((a, b) => a.startDatetime - b.startDatetime);

  const upcomingGigInfo = isLoading ? (
    <Fade in={true} unmountOnExit style={{ transitionDelay: "800ms" }}>
      <LinearProgress />
    </Fade>
  ) : upcomingGigs.length === 0 ? (
    <div>You have no upcoming gigs</div>
  ) : (
    <div>
      {canCreateNewGigs && (
        <FormControlLabel
          control={
            <Switch
              checked={showHiddenGigs}
              onChange={(event) => setShowHiddenGigs(event.target.checked)}
              name="showHidden"
            />
          }
          label="Show hidden gigs"
        />
      )}
      <TableContainer className={classes.upcomingGigs} component={Paper}>
        <Table>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell className={classes.tableHeaderCell}>Status</TableCell>
              <TableCell className={classes.tableHeaderCell}>Gig</TableCell>
              <TableCell className={classes.tableHeaderCell}>In</TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Required but unavailable
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Unknown availabilities
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Unhandled seats
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Latest chase
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Next chase
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {upcomingGigs.map((gig) => (
              <UpcomingGig key={gig.id} gig={gig} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  return (
    <Stack spacing={3}>
      <Typography variant="h3">Upcoming gigs</Typography>
      {upcomingGigInfo}
    </Stack>
  );
}

function UpcomingGig(props: { gig: Gig }) {
  const classes = useStyles();
  const gig = props.gig;
  const bandId = useAppSelector(selectBandId);
  const { data: allSeats = [] } = useGetSeatsQuery(bandId ?? skipToken);
  const seatsById = byId(allSeats);
  const {
    data: managerAvailabilityInfo = { byId: [], byGigId: [] },
    isLoading,
  } = useGetManagerAvailabilitiesQuery(
    {
      bandId: bandId === undefined ? 0 : bandId,
      filterCriteria: { year: "future", tags: [] },
    },
    { skip: bandId === null },
  );
  const availabilities = (managerAvailabilityInfo.byGigId[gig.id] || [])
    .slice()
    .sort(
      (availability1, availability2) =>
        availability1.order - availability2.order,
    );

  const requiredButUnavailable = availabilities.filter((availability) => {
    const availabilitySummary = new AvailabilitySummary(availability.events);
    return (
      !availability.dep &&
      availabilitySummary.required === RequiredStatus.REQUIRED &&
      availabilitySummary.playerAvailability !== AvailabilityStatus.AVAILABLE
    );
  });

  const unconfirmedRequired = availabilities.filter(
    (availability) =>
      !availability.dep &&
      new AvailabilitySummary(availability.events).required ===
        RequiredStatus.NOT_DECIDED,
  );

  const unknownAvailabilities = unconfirmedRequired.filter(
    (availability) =>
      [AvailabilityStatus.AVAILABLE, AvailabilityStatus.UNAVAILABLE].indexOf(
        new AvailabilitySummary(availability.events).playerAvailability,
      ) === -1,
  );

  const getExamples = (availabilities: Availability[]) =>
    availabilities.length === 0
      ? ""
      : availabilities
          .slice(0, 3)
          .map(
            (availability) => seatsById[availability.seat]?.name ?? "Loading",
          )
          .join(", ") + (availabilities.length > 3 ? "..." : "");

  const unconfirmedRequiredExamples = getExamples(unconfirmedRequired);
  const unknownAvailabilitiesExamples = getExamples(unknownAvailabilities);
  const requiredButUnavailableExamples = getExamples(requiredButUnavailable);

  const latestChase = unconfirmedRequired
    .map(
      (availability) =>
        new AvailabilitySummary(availability.events).lastChased || 0,
    )
    .reduce((a, b) => Math.max(a, b), 0);

  // We use Number.MAX_SAFE_INTEGER because we can't actually exactly represent Kotlin's Long.MAX_VALUE.
  const nextChase = gig.collecting
    ? unconfirmedRequired
        .map((availability) => availability.nextCheck)
        .reduce((a, b) => Math.min(a, b), Number.MAX_SAFE_INTEGER)
    : Number.MAX_SAFE_INTEGER;

  const statusIcon = isLoading ? (
    <Fade in={true} unmountOnExit style={{ transitionDelay: "800ms" }}>
      <Skeleton variant="circular" width={20} height={20} />
    </Fade>
  ) : gig.draft ? (
    <TooltippedComponent
      tooltip="Hidden, not visible to players"
      component={<VisibilityOffIcon style={{ color: grey[500] }} />}
    />
  ) : requiredButUnavailable.length > 0 ? (
    <TooltippedComponent
      tooltip={`${plural(
        requiredButUnavailable.length,
        "required but unavailable player",
      )}`}
      component={<CancelIcon style={{ color: red[500] }} />}
    />
  ) : unconfirmedRequired.length === 0 ? (
    <TooltippedComponent
      tooltip="All good!"
      component={<CheckCircleIcon color="primary" />}
    />
  ) : nextChase < Number.MAX_SAFE_INTEGER ? (
    <TooltippedComponent
      tooltip="Collecting availability"
      component={<HourglassEmptyIcon style={{ color: grey[500] }} />}
    />
  ) : null;

  return (
    <>
      <TableRow className={classes.tableRowWide}>
        <TableCell>{statusIcon}</TableCell>
        <TableCell component="th" scope="row">
          <div>
            <Link href={`/manager/gigs/${gig.id}`} underline="hover">
              {gig.name}
            </Link>
          </div>
        </TableCell>
        <TableCell>
          <TooltippedComponent
            tooltip={format(new Date(props.gig.startDatetime), "PPPP", {
              locale: enGB,
            })}
            component={
              <span>{formatDistanceToNow(new Date(gig.startDatetime))}</span>
            }
          />
        </TableCell>
        <TableCell>
          {isLoading ? (
            <Fade in={true} unmountOnExit style={{ transitionDelay: "800ms" }}>
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </Fade>
          ) : (
            <>
              <div>
                {requiredButUnavailable.length > 0
                  ? requiredButUnavailable.length
                  : null}
              </div>
              <div className={classes.exampleSeats}>
                {requiredButUnavailableExamples}
              </div>
            </>
          )}
        </TableCell>
        <TableCell>
          {isLoading ? (
            <Fade in={true} unmountOnExit style={{ transitionDelay: "800ms" }}>
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </Fade>
          ) : (
            <>
              <div>
                {unknownAvailabilities.length > 0
                  ? unknownAvailabilities.length
                  : null}
              </div>
              <div className={classes.exampleSeats}>
                {unknownAvailabilitiesExamples}
              </div>
            </>
          )}
        </TableCell>
        <TableCell>
          {isLoading ? (
            <Fade in={true} unmountOnExit style={{ transitionDelay: "800ms" }}>
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </Fade>
          ) : (
            <>
              <div>
                {unconfirmedRequired.length > 0
                  ? unconfirmedRequired.length
                  : null}
              </div>
              <div className={classes.exampleSeats}>
                {unconfirmedRequiredExamples}
              </div>
            </>
          )}
        </TableCell>
        <TableCell>
          {isLoading ? (
            <Fade in={true} unmountOnExit style={{ transitionDelay: "800ms" }}>
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </Fade>
          ) : latestChase ? (
            formatDistanceToNow(new Date(latestChase)) + " ago"
          ) : null}
        </TableCell>
        <TableCell>
          {isLoading ? (
            <Fade in={true} unmountOnExit style={{ transitionDelay: "800ms" }}>
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </Fade>
          ) : unconfirmedRequired.length > 0 ? (
            nextChase < Number.MAX_SAFE_INTEGER ? (
              formatDistanceToNow(new Date(nextChase))
            ) : gig.collecting ? (
              "No more chases"
            ) : (
              "Not collecting availability"
            )
          ) : null}
        </TableCell>
      </TableRow>
      <TableRow className={classes.tableRowNarrow}>
        <TableCell>
          <Grid container alignItems="center" flexWrap="nowrap">
            <Grid item className={classes.statusIcon}>
              {statusIcon}
            </Grid>
            <Grid item>
              <div>
                <span>
                  <Link href={`/manager/gigs/${gig.id}`} underline="hover">
                    {gig.name}
                  </Link>{" "}
                </span>
                <span>
                  <TooltippedComponent
                    tooltip={format(new Date(props.gig.startDatetime), "PPPP", {
                      locale: enGB,
                    })}
                    component={
                      <span className={classes.dateText}>
                        in {formatDistanceToNow(new Date(gig.startDatetime))}
                      </span>
                    }
                  />
                </span>
              </div>
              {isLoading ? (
                <Fade
                  in={true}
                  unmountOnExit
                  style={{ transitionDelay: "800ms" }}
                >
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Fade>
              ) : (
                <>
                  <div>
                    <span className={classes.detailText}>
                      {unknownAvailabilities.length} unknown
                      availabilities,&nbsp;
                    </span>
                    <span className={classes.detailText}>
                      {unconfirmedRequired.length} unhandled seats
                    </span>
                  </div>
                  <div className={classes.requiredButUnavailable}>
                    <span>
                      {requiredButUnavailable.length > 0
                        ? requiredButUnavailable.length +
                          " required but unavailable (" +
                          requiredButUnavailableExamples +
                          ")"
                        : null}
                    </span>
                  </div>
                  <div>
                    {latestChase ? (
                      <span className={classes.detailText}>
                        {"Last chased " +
                          formatDistanceToNow(new Date(latestChase)) +
                          " ago" +
                          (unconfirmedRequired.length > 0 ? ",\u00A0" : "")}
                      </span>
                    ) : null}
                    {unconfirmedRequired.length > 0 ? (
                      <span className={classes.detailText}>
                        {nextChase < Number.MAX_SAFE_INTEGER
                          ? "next chase in " +
                            formatDistanceToNow(new Date(nextChase))
                          : "no more chases" +
                            (gig.collecting
                              ? ""
                              : " (not collecting availability)")}
                      </span>
                    ) : null}
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </>
  );
}
