import { apiLink } from "utils/utils";
import { useAppSelector } from "app/store";
import { selectToken } from "auth/authSlice";

export interface UrlSigner {
  signUrl: (url: string, noExpiry?: boolean) => Promise<string>;
}

export function useUrlSigner(): UrlSigner {
  const token = useAppSelector(selectToken);
  return {
    signUrl: (url: string, noExpiry?: boolean) => signUrl(token, url, noExpiry),
  };
}

async function signUrl(
  token: string | null,
  url: string,
  noExpiry?: boolean,
): Promise<string> {
  const fullUrl =
    apiLink("sign") +
    "?" +
    new URLSearchParams({
      url: url,
      noExpiry: String(noExpiry || false),
    });
  const response = await fetch(fullUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const json = await response.json();
  return json.url;
}
