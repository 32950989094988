import { User } from "dtos/User";

/**
 * Returns the given user's full name, or "(Unknown player)" if the user doesn't exist (i.e. we've not been allowed to
 * load them).
 */
export function fullName(user: User | null | undefined): string {
  return user ? `${user.givenName} ${user.familyName}` : "(Unknown player)";
}

/**
 * As for {@link fullName} but returns "You" if user matches me.
 */
export function fullNameOrYou(
  user: User | null | undefined,
  me: User | null | undefined,
): string {
  return user?.id === me?.id ? "You" : fullName(user);
}
