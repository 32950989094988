import { Fade, LinearProgress, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { useAppDispatch } from "app/store";
import { useNavigate, useParams } from "react-router-dom";
import { flashMessage } from "redux-flash";
import { useJoinBandMutation } from "api/apiSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    padding: {
      padding: theme.spacing(3),
    },
  }),
);

/**
 * A player ends up here after they click on an invite email. Call the back end to add the logged in user to the given
 * band on the given seat, and redirect to the gig list.
 */
export function JoinBand() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [joinBand] = useJoinBandMutation();

  useEffect(() => {
    async function join() {
      await joinBand(params.token || "");
      dispatch(
        flashMessage(
          "Your account is set up. Welcome to Can You Come To Band \u{1F60A}",
        ),
      );
      navigate("/player/dashboard", { replace: true });
    }

    join();
  }, [params.token]);

  return (
    <div className={classes.padding}>
      <Fade in={true} unmountOnExit style={{ transitionDelay: "800ms" }}>
        <LinearProgress />
      </Fade>
    </div>
  );
}
