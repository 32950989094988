import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  selectFilterState,
  setTags,
  setYear,
} from "features/filter/filterSlice";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { TagAutocomplete } from "features/tags/TagAutocomplete";
import { Tag } from "dtos/Tag";
import { useAppDispatch, useAppSelector } from "app/store";
import { byId } from "utils/utils";
import { useGetTagsQuery } from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { selectBandId } from "features/users/usersSlice";
import { useYearsActive } from "features/misc/yearsActive";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dateForm: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    tagForm: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    label: {
      marginLeft: theme.spacing(2),
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
  }),
);

/**
 * Controls for filtering which gigs are displayed.
 */
export function GigFilter() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const filterCriteria = useAppSelector(selectFilterState);
  const bandId = useAppSelector(selectBandId);
  const { data: allTags = [] } = useGetTagsQuery(bandId ?? skipToken);
  const tagsById = byId(allTags);

  const years = useYearsActive();

  const handleDateChange = (event: SelectChangeEvent<number>) => {
    const year = event.target.value as number;
    dispatch(setYear(year === 0 ? "future" : year === 1 ? "yearSoFar" : year));
  };

  const setFilterTags = (tags: Tag[]) => {
    dispatch(setTags(tags.map((tag) => tag.id)));
  };

  const tags = (filterCriteria.tags ?? []).map((tagId) => tagsById[tagId]);

  return (
    <>
      <FormControl variant="standard" className={classes.dateForm} fullWidth>
        <InputLabel className={classes.label}>Time range</InputLabel>
        <Select
          labelId="time-range-selector"
          value={
            filterCriteria.year === "future"
              ? 0
              : filterCriteria.year === "yearSoFar"
                ? 1
                : filterCriteria.year === "last90days"
                  ? // Bit of a hack this. It's not selectable anyway so doesn't really matter, but would be nice to do this
                    // better.
                    -1
                  : filterCriteria.year
          }
          onChange={handleDateChange}
          label="Time range"
        >
          <MenuItem key={0} value={0}>
            Future
          </MenuItem>
          <MenuItem key={1} value={1}>
            {years[0]} so far
          </MenuItem>
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant="standard" className={classes.tagForm} fullWidth>
        <TagAutocomplete tags={tags} setTags={setFilterTags} addNew={false} />
      </FormControl>
    </>
  );
}
