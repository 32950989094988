import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, Grid, Typography } from "@mui/material";
import { format, formatDistance } from "date-fns";
import { AvailabilityStatus } from "domain/availabilityDomain";
import { useInterval } from "utils/useInterval";
import Hidden from "@mui/material/Hidden";
import Checkbox from "@mui/material/Checkbox";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { PopperMenu } from "features/misc/PopperMenu";
import { AvailabilitySummaryIcon } from "features/misc/AvailablitySummaryIcon";
import { PreventNavigation } from "features/manager/PreventNavigation";
import { fullName } from "features/users/userUtils";
import { AvailabilityEventHistory } from "features/availability/AvailabilityEventHistory";
import { enGB } from "date-fns/locale";
import { ChooseDeps } from "features/deps/ChooseDeps";
import { Availability } from "dtos/Availability";
import { byId, keys } from "utils/utils";
import {
  PlayerManagement,
  usePlayerManagement,
} from "features/manager/playerManagement";
import {
  ModifyAvailabilityMenu,
  OPTION_AVAILABLE,
  OPTION_REQUIRED,
  OPTION_UNAVAILABLE,
  OPTION_WILL_NOT_FILL,
} from "features/manager/ModifyAvailabilityMenu";
import { useAppSelector } from "app/store";
import { Gig } from "dtos/Gig";
import { selectBandId } from "features/users/usersSlice";
import {
  useMoveUserToSeatMutation,
  useGetDepsQuery,
  useGetGigQuery,
  useGetSeatsQuery,
  useGetUsersQuery,
} from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { EventInstance } from "dtos/EventInstance";
import { usePermissions } from "auth/usePermissions";
import { useGetEventInstanceQuery } from "api/eventInstances";
import { ChangeSeat } from "features/manager/ChangeSeat";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    availability: {
      marginTop: theme.spacing(1),
      overflowX: "auto",
    },
    table: {
      maxWidth: 1000,
    },
    depIcon: {
      marginRight: theme.spacing(1),
    },
    actionButtons: {
      marginTop: theme.spacing(2),
      height: theme.spacing(8),
      position: "sticky",
      top: "56px",
      background: theme.palette.background.default,
      zIndex: 1,
      outline: `5px solid ${theme.palette.background.default}`,
    },
    multiSelect: {
      marginLeft: theme.spacing(2),
    },
    confirm: {
      marginTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(2),
      height: theme.spacing(8),
      position: "sticky",
      bottom: 0,
      background: theme.palette.background.default,
      zIndex: 1,
      outline: `5px solid ${theme.palette.background.default}`,
    },
    deps: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(2),
    },
    modifiedCell: {
      background: "#fef",
    },
    remove: {
      textDecoration: "line-through",
    },
  }),
);

/**
 * Used by {@link GigDetails} to show the list of players for the gig and their availability, along with controls to
 * do stuff with that list of players (at the moment, set whether they've been chosen for a gig or not).
 */
export function GigDetailsPlayerList(props: {
  gigId: number;
  startDatetime: number | null;
}) {
  const { data: gig } = useGetGigQuery(props.gigId);
  const { eventInstanceForGigs: rehearsals } = useGetEventInstanceQuery(
    props.startDatetime,
  );
  const eventInstance = rehearsals?.find(
    (r) => r.eventInstance.startDatetime === props.startDatetime,
  )?.eventInstance;

  return gig ? (
    <GigDetailsPlayerListInternal
      gig={gig}
      eventInstance={eventInstance || gig}
    />
  ) : null;
}

function GigDetailsPlayerListInternal({
  gig,
  eventInstance,
}: {
  gig: Gig;
  eventInstance: EventInstance;
}) {
  const classes = useStyles();
  const bandId = useAppSelector(selectBandId);
  const { data: deps = [] } = useGetDepsQuery(bandId ?? skipToken);
  const depsById = byId(deps);

  const playerManagement = usePlayerManagement(
    [{ gigId: gig.id, startDatetime: eventInstance.startDatetime }],
    gig.rehearsal,
  );

  const [movePlayerToSeat] = useMoveUserToSeatMutation();

  const {
    availabilities,
    numSelected,
    someSelected,
    allSelected,
    depSelected,
    depsExist,
    availabilityIdSelected,
    saving,
    selectNone,
    selectAll,
    selectAvailable,
    setEventForSelected,
    addAvailabilities,
    hasUnsavedAvailabilities,
    someChanges,
    cancelChanges,
    saveChanges,
  } = playerManagement;

  const permissions = usePermissions();
  const canModifyAvailability = permissions.hasPermission(
    "UPDATE_AVAILABILITY",
  );

  // Force a rerender every minute so the "last chased" messages update without a refresh.
  const [count, setCount] = useState(0);
  useInterval(() => {
    setCount(count + 1);
  }, 1000 * 60);

  // Whether a table row is expanded or not, showing availability event history.
  const [expanded, setExpanded] = useState<{
    [availabilityId: number]: boolean;
  }>({});

  const toggleExpanded = (availabilityId: number) => {
    setExpanded({
      ...expanded,
      [availabilityId]: !expanded[availabilityId],
    });
  };

  const handleToggleMultiSelect = () => {
    if (allSelected) {
      selectNone();
    } else {
      selectAll();
    }
  };

  const multiSelectMenuOptions = [
    { label: "All", disabled: false, onClick: () => selectAll() },
    { label: "None", disabled: false, onClick: () => selectNone() },
    { label: "Available", disabled: false, onClick: () => selectAvailable() },
  ];
  const multiSelectRef = React.useRef<HTMLButtonElement>(null);
  const [multiSelectMenuOpen, multiSelectMenuSetOpen] = React.useState(false);
  const handleMultiSelectMenuToggle = () =>
    multiSelectMenuSetOpen((prevOpen) => !prevOpen);

  const removeSelectedAvailabilities = () => {
    setEventForSelected("REMOVE");
    selectNone();
  };

  const [depDialogueOpen, setDepDialogueOpen] = React.useState(false);

  const confirmDepSelection = (depIds: number[]) => {
    let nextAvailabilityId =
      keys(availabilities).reduce((a, b) => Math.max(a, b)) + 1;

    const newAvailabilities: { [availabilityId: number]: Availability } = {};
    depIds.forEach((depId) => {
      const seat = availabilityIdSelected
        ? availabilities[availabilityIdSelected].seat
        : 0;
      // Copy the order from the availability so deps appear (in their own section) in the same order as the main
      // players.
      const order = availabilityIdSelected
        ? availabilities[availabilityIdSelected].order
        : 0;
      const dep = depsById[depId];
      const userId = dep ? dep.user : -1;

      newAvailabilities[nextAvailabilityId] = {
        id: nextAvailabilityId,
        user: userId,
        gig: gig.id,
        startDatetime: eventInstance.startDatetime,
        seat,
        seatRename: null,
        order,
        dep: true,
        events: [],
        nextCheck: 0,
      };

      nextAvailabilityId++;
    });

    addAvailabilities(newAvailabilities);
    setEventForSelected("REPLACED_WITH_DEP");
    selectNone();
  };

  const [changeSeatDialogueOpen, setChangeSeatDialogueOpen] =
    React.useState(false);

  const confirmSeatSelection = async (
    availability: Availability,
    seatId: number,
  ) => {
    await movePlayerToSeat({
      gig: availability.gig,
      startDatetime: availability.startDatetime,
      user: availability.user,
      targetSeat: seatId,
    });
    selectNone();
  };

  return (
    <div>
      {canModifyAvailability && (
        <Grid container className={classes.actionButtons}>
          <Checkbox
            className={classes.multiSelect}
            checked={allSelected}
            indeterminate={someSelected && !allSelected}
            onChange={() => handleToggleMultiSelect()}
            value="confirmed"
            color="primary"
          />
          <Button onClick={handleMultiSelectMenuToggle} ref={multiSelectRef}>
            <ArrowDropDownIcon />
          </Button>

          <PopperMenu
            menuOptions={multiSelectMenuOptions}
            open={multiSelectMenuOpen}
            setOpen={multiSelectMenuSetOpen}
            reference={multiSelectRef}
          />

          <ModifyAvailabilityMenu
            playerManagement={playerManagement}
            menuItems={[
              OPTION_AVAILABLE(playerManagement.setEventForSelected),
              OPTION_UNAVAILABLE(playerManagement.setEventForSelected),
              // Can't set required/not required for rehearsals, we just use available/unavailable.
              ...(gig.rehearsal
                ? []
                : [OPTION_REQUIRED(playerManagement.setEventForSelected)]),
              ...(gig.rehearsal
                ? []
                : [OPTION_WILL_NOT_FILL(playerManagement.setEventForSelected)]),
              {
                label: "Replace with dep",
                disabled: numSelected > 1 || depSelected,
                onClick: () => {
                  setDepDialogueOpen(true);
                },
              },
              {
                label: "Remove",
                disabled: false,
                onClick: () => {
                  removeSelectedAvailabilities();
                },
              },
              {
                label: "Change seat",
                disabled:
                  numSelected > 1 ||
                  availabilityIdSelected === undefined ||
                  availabilities[availabilityIdSelected].user === -1,
                onClick: () => {
                  setChangeSeatDialogueOpen(true);
                },
              },
            ]}
          />
        </Grid>
      )}

      <Paper className={classes.availability}>
        <Table className={classes.table}>
          <TableBody>
            {Object.values(availabilities)
              .slice()
              .sort(
                (availability1, availability2) =>
                  availability1.order - availability2.order,
              )
              .filter((availability) => !availability.dep)
              .map((availability) => (
                <AvailabilityRow
                  key={availability.id}
                  availability={availability}
                  gig={gig}
                  isExpanded={expanded[availability.id]}
                  toggleExpanded={toggleExpanded}
                  playerManagement={playerManagement}
                />
              ))}
          </TableBody>
        </Table>
      </Paper>

      {depsExist ? (
        <>
          <Typography variant="h4" className={classes.deps}>
            Deps
          </Typography>
          <Paper className={classes.availability}>
            <Table className={classes.table}>
              <TableBody>
                {Object.values(availabilities)
                  .slice()
                  .sort(
                    (availability1, availability2) =>
                      availability1.order - availability2.order,
                  )
                  .filter((availability) => availability.dep)
                  .map((availability) => (
                    <AvailabilityRow
                      key={availability.id}
                      availability={availability}
                      gig={gig}
                      isExpanded={expanded[availability.id]}
                      toggleExpanded={toggleExpanded}
                      playerManagement={playerManagement}
                    />
                  ))}
              </TableBody>
            </Table>
          </Paper>
        </>
      ) : null}

      {someChanges() || hasUnsavedAvailabilities() ? (
        <Grid
          container
          className={classes.confirm}
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Box pr={1}>
            <Grid item>
              <Button onClick={cancelChanges}>Cancel</Button>
            </Grid>
          </Box>
          <Grid item>
            <PreventNavigation
              confirmText={gig.id === null ? "Add gig" : "Confirm changes"}
              confirmChanges={saveChanges}
              beforeNavigate={cancelChanges}
              saving={saving}
              disabled={false}
            />
          </Grid>
        </Grid>
      ) : null}

      <ChooseDeps
        open={depDialogueOpen}
        setOpen={setDepDialogueOpen}
        seat={
          availabilityIdSelected && availabilities[availabilityIdSelected]
            ? availabilities[availabilityIdSelected].seat
            : 0
        }
        confirmSelection={confirmDepSelection}
        userBlacklist={keys(availabilities)
          .map((availabilityId) => availabilities[availabilityId])
          .filter((availability) => availability.user !== -1)
          .map((availability) => availability.user)}
      />

      {availabilityIdSelected && (
        <ChangeSeat
          key={availabilityIdSelected}
          open={changeSeatDialogueOpen}
          setOpen={setChangeSeatDialogueOpen}
          availability={availabilities[availabilityIdSelected]}
          confirmSelection={confirmSeatSelection}
        />
      )}
    </div>
  );
}

interface AvailabilityRowProps {
  availability: Availability;
  gig: Gig;
  isExpanded: boolean;
  toggleExpanded: (availabilityId: number) => void;
  playerManagement: PlayerManagement;
}

function AvailabilityRow(props: AvailabilityRowProps) {
  const { availability, gig, isExpanded, toggleExpanded, playerManagement } =
    props;

  const classes = useStyles();

  const { selected, selectById, getModifiedAvailabilitySummary, isModified } =
    playerManagement;

  const bandId = useAppSelector(selectBandId);
  const { data: users = [] } = useGetUsersQuery(bandId ?? skipToken);
  const user = byId(users)[availability.user];

  const availabilitySummary = getModifiedAvailabilitySummary(availability);

  const rowClass = isModified(availability) ? classes.modifiedCell : "";
  const cellClass = availabilitySummary.remove ? classes.remove : "";

  const { data: allSeats = [] } = useGetSeatsQuery(bandId ?? skipToken);
  const seat = byId(allSeats)[availability.seat];

  const permissions = usePermissions();
  const canModifyAvailability = permissions.hasPermission(
    "UPDATE_AVAILABILITY",
  );

  return (
    <React.Fragment key={availability.id}>
      <TableRow
        className={rowClass}
        selected={selected[availability.id]}
        hover
        onClick={(event) => {
          // Only expand if the user hasn't clicked on the checkbox.
          if (!(event.target instanceof HTMLInputElement)) {
            toggleExpanded(availability.id);
          }
        }}
      >
        {canModifyAvailability && (
          <TableCell>
            <Checkbox
              checked={selected[availability.id] || false}
              onChange={(event) =>
                selectById(availability.id, event.target.checked)
              }
              color="primary"
            />
          </TableCell>
        )}
        <TableCell component="th" scope="row" className={cellClass}>
          {availability.seatRename ? availability.seatRename : seat?.name}
        </TableCell>
        <TableCell className={cellClass}>
          {availability.user === -1 ? "" : fullName(user)}
        </TableCell>
        <TableCell>
          <AvailabilitySummaryIcon
            availabilitySummary={availabilitySummary}
            gigCollecting={gig.collecting}
            player={false}
            dim={false}
          />
        </TableCell>
        <Hidden mdDown>
          <TableCell>
            {availabilitySummary.waitingForAvailability &&
            availabilitySummary.lastChased !== null
              ? "Last chased " +
                formatDistance(
                  new Date(availabilitySummary.lastChased),
                  new Date(),
                ) +
                " ago"
              : availabilitySummary.playerAvailability ===
                  AvailabilityStatus.WILL_KNOW_LATER
                ? "Won't know until " +
                  format(new Date(availabilitySummary.askAgain), "PP", {
                    locale: enGB,
                  })
                : null}
          </TableCell>
        </Hidden>
      </TableRow>
      {isExpanded ? (
        <TableRow>
          <TableCell />
          <TableCell colSpan={3}>
            <AvailabilityEventHistory
              availabilityEvents={availability.events}
              availabilityUserId={availability.user}
              playerView={false}
            />
          </TableCell>
          <Hidden mdDown>
            <TableCell />
          </Hidden>
        </TableRow>
      ) : null}
    </React.Fragment>
  );
}
