import React from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import {
  AvailabilityStatus,
  AvailabilitySummary,
} from "domain/availabilityDomain";
import Hidden from "@mui/material/Hidden";
import { AvailabilitySummaryIcon } from "features/misc/AvailablitySummaryIcon";
import CheckIcon from "@mui/icons-material/Check";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAppSelector } from "app/store";
import { useNavigate } from "react-router-dom";
import { selectPlayerFilterState } from "features/filter/playerFilterSlice";
import { selectBandId, selectMe } from "features/users/usersSlice";
import { useGetGigsQuery, useGetPlayerAvailabilitiesQuery } from "api/apiSlice";
import { byId } from "utils/utils";
import { User } from "dtos/User";
import { filterFromCriteria } from "features/filter/filterCriteria";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1000,
    },
    noGigs: {
      margin: theme.spacing(3),
    },
    key: {
      display: "flex",
      justifyContent: "flex-end",
      flexWrap: "wrap",
      paddingRight: "0.2em",
      paddingTop: "1em",
      fontSize: "80%",
      color: "#666",
    },
    keySet: {
      display: "flex",
      paddingBottom: "0.5em",
    },
    keyItem: {
      paddingRight: "10px",
      display: "flex",
      alignItems: "center",
    },
  }),
);

/**
 * Shows a player an overview of gigs and what they've said availability-wise. Allows them to click through to specific
 * gigs for more details.
 */
export function PlayerAvailabilitySummary({
  limit = Number.MAX_VALUE,
}: {
  limit?: number;
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const filterCriteria = useAppSelector(selectPlayerFilterState);
  const filter = filterFromCriteria(filterCriteria);

  const bandId = useAppSelector(selectBandId);
  const me: User | null = useAppSelector(selectMe);

  const { data: gigs = [] } = useGetGigsQuery(
    { bandId: bandId === undefined ? 0 : bandId, filterCriteria },
    { skip: bandId === undefined },
  );
  const { data: availabilities = [] } = useGetPlayerAvailabilitiesQuery(
    { userId: me === null ? 0 : me.id, filterCriteria },
    { skip: me === null },
  );

  const gigAvailabilities = availabilities.filter((a) =>
    gigs.some((g) => g.id === a.gig && !g.rehearsal),
  );

  const gigsById = byId(gigs);

  const handleClick = (gigId: number | undefined) => {
    if (gigId !== undefined) {
      navigate("/player/gigs/" + gigId);
    }
  };

  return (
    <div className={classes.root}>
      {gigAvailabilities.length === 0 ? (
        <div className={classes.noGigs}>
          Gigs you&apos;re playing in will appear here when you&apos;re invited
          to them.
        </div>
      ) : (
        <div>
          <Paper>
            <Table>
              <TableBody>
                {gigAvailabilities
                  .filter((availability) => {
                    const gig = gigsById[availability.gig];
                    return gig === undefined ? true : filter(gig);
                  })
                  .sort((availability1, availability2) => {
                    const gig1 = gigsById[availability1.gig];
                    const gig2 = gigsById[availability2.gig];
                    if (gig1 && gig2) {
                      return gig1.startDatetime - gig2.startDatetime;
                    } else {
                      return 0;
                    }
                  })
                  .filter(
                    (availability, index) =>
                      index < limit ||
                      new AvailabilitySummary(availability.events)
                        .playerAvailability === AvailabilityStatus.NOT_KNOWN,
                  )
                  .map((availability) => {
                    const gig = gigsById[availability.gig];
                    const gigName = gig ? gig.name : "Loading...";
                    const datetime = gig
                      ? format(new Date(gig.startDatetime), "PPp", {
                          locale: enGB,
                        })
                      : "Loading...";
                    const summary = new AvailabilitySummary(
                      availability.events,
                    );
                    const availabilityIcon =
                      summary.canGiveAvailability() &&
                      summary.shouldGiveAvailability() ? (
                        <span>
                          <Hidden smUp implementation="css">
                            <Button variant="contained" color="secondary">
                              Yes/No
                            </Button>
                          </Hidden>
                          <Hidden smDown implementation="css">
                            <Button variant="contained" color="secondary">
                              Provide availability...
                            </Button>
                          </Hidden>
                        </span>
                      ) : (
                        <AvailabilitySummaryIcon
                          availabilitySummary={summary}
                          gigCollecting={gig ? gig.collecting : false}
                          player={true}
                          dim={false}
                        />
                      );
                    return (
                      <TableRow
                        key={availability.id}
                        hover={true}
                        onClick={() => handleClick(gig?.id)}
                      >
                        <TableCell>{gigName}</TableCell>
                        <TableCell>{datetime}</TableCell>
                        <TableCell>{availabilityIcon}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Paper>

          <div className={classes.key}>
            <span className={classes.keySet}>
              <span className={classes.keyItem}>
                <CheckIcon fontSize="small" />
                &nbsp;Available
              </span>
              <span className={classes.keyItem}>
                <ClearRoundedIcon fontSize="small" />
                &nbsp;Not available
              </span>
            </span>
            <span className={classes.keySet}>
              <span className={classes.keyItem}>
                <CheckCircleIcon color="primary" fontSize="small" />
                &nbsp;Required
              </span>
              <span className={classes.keyItem}>
                <CancelIcon color="primary" fontSize="small" />
                &nbsp;Not required
              </span>
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
