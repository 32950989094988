import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";

/**
 * Player management.
 */
export function PlayerManagement() {
  const urls: { [url: string]: number } = {
    "/manager/settings/players": 0,
    "/manager/settings/invitations": 1,
    "/manager/settings/stats": 2,
  };

  const reverseUrls = Object.keys(urls).reduce<{ [index: number]: string }>(
    (dict, url) => {
      dict[urls[url]] = url;
      return dict;
    },
    {},
  );

  const location = useLocation();

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={urls[location.pathname]} aria-label="basic tabs example">
            <Tab label="Players" href={reverseUrls[0]} />
            <Tab label="Invitations" href={reverseUrls[1]} />
            <Tab label="Stats" href={reverseUrls[2]} />
          </Tabs>
        </Box>
        <Box sx={{ pt: 3 }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
