import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { ConfirmButton } from "utils/ConfirmButton";
import { Transition } from "history";
import { useBlocker } from "utils/useBlocker";

interface PreventNavigationProps {
  confirmText: string;
  confirmChanges: () => void;
  beforeNavigate: () => void;
  saving: boolean;
  disabled: boolean;
}

/**
 * Renders a "confirm changes" button that prevents the user navigating elsewhere unless they first confirm they want to
 * discard their changes.
 */
export function PreventNavigation(props: PreventNavigationProps) {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [transition, setTransition] = useState<Transition | null>(null);

  useBlocker((transition) => {
    setConfirmOpen(!confirmOpen);
    setTransition(transition);
    if (confirmOpen) {
      transition.retry();
    }
  });

  const handleOnClose = () => {
    // Deny the navigation and carry on as we were.
    setConfirmOpen(false);
  };

  const handleNavigateAway = () => {
    // Clean up saved state and then navigate away.
    props.beforeNavigate();
    if (transition !== null) {
      transition.retry();
    }
  };

  return (
    <>
      <ConfirmButton
        variant="contained"
        color="primary"
        disabled={props.disabled}
        saving={props.saving}
        onClick={props.confirmChanges}
      >
        {props.confirmText}
      </ConfirmButton>

      <Dialog
        open={confirmOpen}
        onClose={handleOnClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have unsaved changes. Are you sure you want to discard them?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleNavigateAway} color="primary" autoFocus>
            Discard changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
