import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import UpdateIcon from "@mui/icons-material/Update";
import { Person } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";

/**
 * An icon that represents the given availability event type.
 */
export function AvailabilityEventIcon(props: {
  eventType: string;
  playerView: boolean;
}) {
  return props.eventType === "AVAILABILITY_REQUEST" ? (
    <MailOutlineIcon />
  ) : props.eventType === "AVAILABLE" ? (
    <CheckIcon />
  ) : props.eventType === "UNAVAILABLE" ? (
    <ClearRoundedIcon />
  ) : props.eventType === "WILL_KNOW_LATER" ? (
    <UpdateIcon />
  ) : props.eventType === "REQUIRED" ? (
    <CheckCircleIcon color="primary" />
  ) : props.eventType === "REPLACED_WITH_DEP" ? (
    props.playerView ? (
      <CancelIcon color="primary" />
    ) : (
      <Person color="primary" />
    )
  ) : props.eventType === "WILL_NOT_FILL" ? (
    props.playerView ? (
      <CancelIcon color="primary" />
    ) : (
      <DeleteIcon color="primary" />
    )
  ) : (
    <span>Unknown</span>
  );
}
