import { IconButton, InputAdornment, TextFieldProps } from "@mui/material";
import React from "react";
import { getHtmlInputElement } from "features/misc/materialUiUtils";
import TextField from "@mui/material/TextField";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { TooltippedComponent } from "utils/TooltippedComponent";

/**
 * An (uneditable) text field that has a button allowing the user to copy the contents to the clipboard.
 */
export const CopyableTextField = (props: TextFieldProps & { text: string }) => {
  const { name, text, ...rest } = props;

  const textField: React.RefObject<HTMLDivElement> = React.createRef();

  const copyToClipboard = () => {
    if (textField.current !== null) {
      const htmlInputElement = getHtmlInputElement(textField.current);
      htmlInputElement.select();
      navigator.clipboard.writeText(htmlInputElement.value);
    }
  };

  return (
    <TextField
      name={name}
      margin="dense"
      type="text"
      fullWidth
      value={text}
      ref={textField}
      {...rest}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <TooltippedComponent
              tooltip="Copy to clipboard"
              component={
                <IconButton
                  aria-label="Copy to clipboard"
                  onClick={copyToClipboard}
                  size="large"
                >
                  <FileCopyIcon />
                </IconButton>
              }
            />
          </InputAdornment>
        ),
      }}
    />
  );
};
