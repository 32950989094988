import ListItem from "@mui/material/ListItem";
import React from "react";
import { Gig } from "dtos/Gig";
import { Skeleton } from "@mui/material";
import { selectFilterState } from "features/filter/filterSlice";
import { useAppSelector } from "app/store";
import { ListItemLink } from "features/misc/ListItemLink";
import { useGetGigsQuery } from "api/apiSlice";
import { selectBandId } from "features/users/usersSlice";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PublicIcon from "@mui/icons-material/Public";
import { filterFromCriteria } from "features/filter/filterCriteria";

/**
 * The gig list in the sidebar of the manager view.
 */
export function GigList() {
  const bandId = useAppSelector(selectBandId);
  const filterCriteria = useAppSelector(selectFilterState);
  const { data: allGigs = [], isLoading } = useGetGigsQuery(
    { bandId: bandId === undefined ? 0 : bandId, filterCriteria },
    { skip: bandId === undefined },
  );

  const filter = filterFromCriteria(useAppSelector(selectFilterState));

  const dummyGig: Gig = {
    id: 0,
    band: 0,
    name: "Dummy",
    startDatetime: 0,
    endDatetime: 0,
    publishedDatetime: 0,
    place: {
      id: "",
      name: "",
      address: "",
    },
    details: "",
    collecting: false,
    draft: false,
    tags: [],
    rehearsal: false,
    repeatWeekly: false,
    repeatsUntil: 0,
  };

  return (
    <>
      {(isLoading
        ? [dummyGig, dummyGig, dummyGig]
        : allGigs
            .filter(filter)
            .sort((a, b) => a.startDatetime - b.startDatetime)
      ).map((gig, index) => {
        return isLoading ? (
          <ListItem button key={index}>
            <Skeleton variant="rectangular" width={220} height={40} />
          </ListItem>
        ) : (
          <ListItemLink
            key={gig.id}
            to={"/manager/gigs/" + gig.id}
            primary={gig.name}
            icon={
              gig.draft ? (
                <VisibilityOffIcon fontSize="small" />
              ) : gig.publishedDatetime !== 0 ? (
                <PublicIcon fontSize="small" />
              ) : null
            }
          />
        );
      })}
    </>
  );
}
