import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Theme, Typography } from "@mui/material";
import { useGetNoticeboardQuery } from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAppSelector } from "app/store";
import { selectBandId } from "features/users/usersSlice";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      maxWidth: 1000,
      padding: theme.spacing(2),
    },
    status: {
      display: "flex",
      alignItems: "top",
      paddingTop: theme.spacing(2),
    },
    result: {
      width: "100%",
      maxWidth: "388px",
    },
  }),
);

/**
 *
 */
export function Noticeboard() {
  const classes = useStyles();

  const bandId = useAppSelector(selectBandId);
  const {
    data: noticeboard,
    isLoading,
    isError,
  } = useGetNoticeboardQuery(bandId ?? skipToken);

  return (
    <div className={classes.main}>
      <Typography variant="h3" sx={{ pb: 1 }}>
        Noticeboard
      </Typography>

      {isLoading ? (
        <div>Loading</div>
      ) : isError ? (
        <div>Error</div>
      ) : (
        <ReactMarkdown>{noticeboard?.text ?? ""}</ReactMarkdown>
      )}
    </div>
  );
}
