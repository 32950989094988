import React from "react";
import Paper from "@mui/material/Paper";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Popper,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export interface PopperMenuProps {
  menuOptions: MenuItemDefinition[];
  open: boolean;
  setOpen: (open: boolean) => void;
  reference: React.RefObject<HTMLElement>;
}

export interface MenuItemDefinition {
  label: string;
  disabled: boolean;
  onClick: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      zIndex: 3,
    },
  }),
);

/**
 * Implements a "popper" menu, ie. one popping out just under another control.
 */
export function PopperMenu(props: PopperMenuProps) {
  const classes = useStyles();

  const handleMenuClose = (event: MouseEvent | TouchEvent) => {
    if (
      props.reference.current &&
      props.reference.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    props.setOpen(false);
  };

  return (
    <Popper
      open={props.open}
      anchorEl={props.reference.current}
      placement="bottom-start"
      transition
      className={classes.root}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleMenuClose}>
              <MenuList>
                {props.menuOptions.map((option, index) => (
                  <MenuItem
                    key={index}
                    disabled={option.disabled}
                    onClick={() => {
                      option.onClick();
                      props.setOpen(false);
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
