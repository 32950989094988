import { useGetEventInstancesQuery } from "api/apiSlice";
import { useAppSelector } from "app/store";
import { selectBandId } from "features/users/usersSlice";
import { EventInstanceForGig } from "dtos/EventInstance";

export interface GetEventInstanceQueryResult {
  eventInstanceForGigs?: EventInstanceForGig[];
  isLoading: boolean;
}

/**
 * Ensures the event instance corresponding to the given start date/time is loaded. Hack for now while we can't fetch
 * individual events: simply extracts the year of the event and fetches events for the entire year. Not necessarily
 * efficient, but does mean any event later accessed in that same year need not be fetched.
 *
 * TODO (526): remove this hack.
 */
export const useGetEventInstanceQuery = (
  startDatetime: number | null,
): GetEventInstanceQueryResult => {
  const bandId = useAppSelector(selectBandId);
  // We handle startDatetime == null to help out clients, which are possibly rendering a gig and not a rehearsal and
  // will ignore this information. This needs fixing: we shouldn't be fetching rehearsals at all in this case, or
  // actually, we should probably load both rehearsals and gigs via this mechanism and not special case gigs.
  const year = (
    startDatetime === null ? new Date() : new Date(startDatetime)
  ).getUTCFullYear();
  const { data = [], isLoading } = useGetEventInstancesQuery(
    {
      bandId: bandId === undefined ? 0 : bandId,
      filterCriteria: { year },
    },
    { skip: bandId === undefined },
  );
  return {
    eventInstanceForGigs: data,
    isLoading,
  };
};
