import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FormControlLabel,
  Grid,
  LinearProgress,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  AvailabilityStatus,
  AvailabilitySummary,
} from "domain/availabilityDomain";
import { DatetimeSpan } from "features/misc/DatetimeSpan";
import { getEmbedUrl } from "features/misc/googleMapsUtils";
import { PreventNavigation } from "features/manager/PreventNavigation";
import EditIcon from "@mui/icons-material/Edit";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { getHtmlInputElement } from "features/misc/materialUiUtils";
import { GigDetailsPlayerList } from "features/manager/GigDetailsPlayerList";
import { flashMessage } from "redux-flash";
import { useUrlSigner } from "features/misc/signedUrl";
import ReactMarkdown from "react-markdown";
import { apiLink, byId, keys } from "utils/utils";
import { isPastEvent } from "utils/domain";
import { TagChip } from "features/tags/TagChip";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PublicIcon from "@mui/icons-material/Public";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Tag } from "dtos/Tag";
import { TagAutocomplete } from "features/tags/TagAutocomplete";
import { useAppDispatch, useAppSelector } from "app/store";
import { Gig } from "dtos/Gig";
import { selectBandId } from "features/users/usersSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  addHours,
  format,
  formatDistanceToNow,
  setHours,
  setMinutes,
} from "date-fns";
import { TooltippedComponent } from "utils/TooltippedComponent";
import {
  useAddGigMutation,
  useDeleteEventInstanceMutation,
  useDeleteGigMutation,
  useEditEventInstanceMutation,
  useEditGigMutation,
  useGetAvailabilityRequestDatetimeQuery,
  useGetGigAvailabilitiesQuery,
  useGetGigQuery,
  useGetTagsQuery,
  useSetCollectingMutation,
} from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { ConfirmButton } from "utils/ConfirmButton";
import { usePermissions } from "auth/usePermissions";
import { EventInstance } from "dtos/EventInstance";
import { useGetEventInstanceQuery } from "api/eventInstances";
import { enGB } from "date-fns/locale";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 1000,
    },
    confirm: {
      marginTop: theme.spacing(2),
    },
    h3: {
      fontSize: 48,
    },
    spacer: {
      paddingTop: theme.spacing(2),
    },
    bigSpacer: {
      paddingTop: theme.spacing(3),
    },
    tags: {
      paddingTop: theme.spacing(1),
    },
  }),
);

const NEW_GIG = {
  id: -1,
  band: 1,
  name: "",
  startDatetime: -1,
  endDatetime: -1,
  publishedDatetime: -1,
  place: {
    id: "",
    name: "",
    address: "",
  },
  details: "",
  collecting: false,
  draft: true,
  tags: [],
  rehearsal: false,
  repeatWeekly: false,
  repeatsUntil: 0,
};

const publishedTimeFormat = new Intl.DateTimeFormat(undefined, {
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "short",
});

/**
 * The manager's view of a gig or rehearsal. Also handles creating and editing a gig.
 *
 * TODO (529): add tests for this UI (need some in general, not just because of this bug!)
 */
export function ManagerEventDetails() {
  const params = useParams();
  const gigId = params.gigId === undefined ? null : parseInt(params.gigId);
  const startDatetime =
    params.startDatetime === undefined ? null : parseInt(params.startDatetime);
  let { data: gig, isLoading: isGigLoading } = useGetGigQuery(
    gigId ?? skipToken,
  );
  if (gigId === null) {
    gig = NEW_GIG;
    if (startDatetime !== null) {
      gig = { ...gig, rehearsal: true };
    }
  }
  const { isLoading: isAvailabilityLoading } = useGetGigAvailabilitiesQuery(
    gigId ?? skipToken,
  );
  const bandId = useAppSelector(selectBandId);
  const { eventInstanceForGigs: rehearsals } =
    useGetEventInstanceQuery(startDatetime);
  const eventInstance = rehearsals?.find(
    (r) => r.eventInstance.startDatetime === startDatetime,
  )?.eventInstance;

  // TODO: should probably use a selector. Might want to let the page load but stop other things working, e.g. only
  //  disallow new gigs? We use data rather than isLoading here because isLoading seems to be briefly initially false?
  const { data: tags } = useGetTagsQuery(bandId ?? skipToken);
  return isGigLoading || isAvailabilityLoading || tags === undefined ? (
    <Fade in={true} unmountOnExit style={{ transitionDelay: "800ms" }}>
      <LinearProgress />
    </Fade>
  ) : gig ? (
    <GigDetailsInternal
      gig={gig}
      eventInstance={eventInstance || gig}
      modifyStartDatetime={startDatetime}
    />
  ) : null;
}

export function GigDetailsInternal({
  gig,
  eventInstance,
  modifyStartDatetime,
}: {
  gig: Gig;
  eventInstance: EventInstance;
  modifyStartDatetime: number | null;
}) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const urlSigner = useUrlSigner();
  const canEditGig = permissions.hasPermission("UPDATE_GIG");
  const eventType = gig.rehearsal ? "rehearsal" : "gig";

  const pastGig = gig.id !== -1 && isPastEvent(gig);

  const { data: gigAvailabilities = [] } = useGetGigAvailabilitiesQuery(
    gig.id,
    { skip: gig.id === -1 },
  );
  const availabilities = byId(gigAvailabilities);

  const bandId = useAppSelector(selectBandId);
  const { data: allTags = [] } = useGetTagsQuery(bandId ?? skipToken);
  const orderedGigTags = useMemo(
    () =>
      allTags
        .filter(
          (tag) =>
            gig.tags.find((gigTag) => gigTag.tag === tag.id) !== undefined,
        )
        .sort((a, b) => a.name.localeCompare(b.name)),
    [allTags, gig],
  );

  const [confirmCollectModal, setConfirmCollectModal] = useState(false);
  const [confirmHideGigModal, setConfirmHideGigModal] = useState(false);
  const [collectNowModal, setCollectNowModal] = useState(false);

  const [addGig, { isLoading: isAddLoading }] = useAddGigMutation();
  const [editGig, { isLoading: isEditLoading }] = useEditGigMutation();
  const [editRehearsal, { isLoading: isEditRehearsalLoading }] =
    useEditEventInstanceMutation();
  const [deleteGig] = useDeleteGigMutation();
  const [deleteEventInstance] = useDeleteEventInstanceMutation();
  const [setCollecting] = useSetCollectingMutation();
  const { data: availabilityRequestDatetime } =
    useGetAvailabilityRequestDatetimeQuery(gig.id);

  const availabilityCount = Object.keys(availabilities).length;
  const knownAvailabilityCount = keys(availabilities)
    .map((id) => availabilities[id])
    .filter(
      (availability) =>
        [AvailabilityStatus.AVAILABLE, AvailabilityStatus.UNAVAILABLE].indexOf(
          new AvailabilitySummary(availability.events).playerAvailability,
        ) !== -1,
    ).length;
  const percentage = Math.round(
    (knownAvailabilityCount / availabilityCount) * 100,
  );

  const collectingOn = function () {
    setCollecting({ gigId: gig.id, collecting: true, immediately: false });
    setConfirmCollectModal(false);
  };

  const collectingOff = function () {
    setCollecting({ gigId: gig.id, collecting: false, immediately: false });
  };

  const confirmHideGig = async function () {
    gig = { ...gig };
    setPublishGig(false);
    await confirmChanges({ collecting: false, publishedDatetime: 0 });
    setConfirmHideGigModal(false);
  };

  const [editing, setEditing] = useState(false);
  const [newGigId, setNewGigId] = useState(-1);
  const [name, setName] = useState("");
  const [startDatetime, setStartDateTime] = useState<Date | null>(null);
  const [endDatetime, setEndDateTime] = useState<Date | null>(null);
  const [endEdited, setEndEdited] = useState(false);
  const [publishGig, setPublishGig] = useState(false);
  const [publishedDatetime, setPublishedDateTime] = useState<Date | null>(null);
  const [publishedEdited, setPublishedEdited] = useState(false);
  const [tags, setTags] = useState<Tag[]>([]);
  const [placeId, setPlaceId] = useState("");
  const [placeName, setPlaceName] = useState("");
  const [placeAddress, setPlaceAddress] = useState("");
  const [details, setDetails] = useState("");
  const [draft, setDraft] = useState(false);
  const [startDateTimeError, setStartDateTimeError] = useState(false);
  const [endDateTimeError, setEndDateTimeError] = useState(false);
  const [publishedDateTimeError, setPublishedDateTimeError] = useState(false);

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const env = { ...process.env, ...window.process?.env };
  const noGoogleMaps = env.REACT_APP_CYPRESS_TESTS === "true";

  // This is a horrible hack to make redirecting after creating a new gig work. The old way broke with the upgrade to
  // React 18. Interestingly, I used to use this hack back with Hook Router when I upgraded to React 17. React Router
  // has now broken in the same way with the upgrade to React 18. I guess I'm using it wrongly somehow...
  useEffect(() => {
    if (newGigId !== -1) {
      setNewGigId(-1);
      if (newGigId === -2) {
        navigate(`/manager/dashboard`);
      } else {
        if (gig.rehearsal) {
          navigate(
            `/manager/rehearsals/${newGigId}/${startDatetime?.getTime()}`,
          );
        } else {
          navigate(`/manager/gigs/${newGigId}`);
        }
      }
    }
  }, [newGigId]);

  const handleSetStartDateTime = (date: Date | null) => {
    setStartDateTime(date);
    if (date !== null && !endEdited) {
      // Make it easier to set an end date/time by setting end to something similar so the user doesn't have to pick the
      // date all over again.
      setEndDateTime(addHours(date, 1));
    }
    if (date !== null && !publishedEdited) {
      setPublishedDateTime(date);
    }
  };

  const handleSetEndDateTime = (date: Date | null) => {
    setEndDateTime(date);
    setEndEdited(true);
  };

  const handleSetPublishedDateTime = (date: Date | null) => {
    setPublishedDateTime(date);
    setPublishedEdited(true);
  };

  const handleDeleteEvent = async function () {
    if (gig.id !== -1) {
      if (gig.rehearsal) {
        await deleteEventInstance({
          gig: gig.id,
          eventInstance: eventInstance,
        });
        dispatch(
          flashMessage(
            `Deleted rehearsal "${gig.name}" on ${format(
              eventInstance.startDatetime,
              "do LLLL yyyy",
              { locale: enGB },
            )}`,
          ),
        );
      } else {
        await deleteGig(gig.id);
        dispatch(flashMessage(`Deleted gig "${gig.name}"`));
      }
      setConfirmDeleteModal(false);
      setEditing(false);
      navigate("/manager/dashboard");
    }
  };

  const handleCollectNow = async function () {
    await setCollecting({ gigId: gig.id, collecting: true, immediately: true });
    setCollectNowModal(false);
  };

  const confirmChanges = async (overrides: Partial<Gig>) => {
    const gigData = {
      name,
      startDatetime: startDatetime?.getTime() ?? 0,
      endDatetime: endDatetime?.getTime() ?? 0,
      publishedDatetime: publishGig ? publishedDatetime?.getTime() ?? 0 : 0,
      tags: tags.map((tag) => {
        return { tag: tag.id };
      }),
      place: {
        id: placeId,
        name: placeName,
        address: placeAddress,
      },
      details,
      draft,
      rehearsal: gig.rehearsal,
      repeatWeekly: false,
      repeatsUntil: 0,
    };

    if (gig.id === -1) {
      // New gig or rehearsal: create it.
      if (bandId !== undefined) {
        const newGig = await addGig({
          ...gigData,
          band: bandId,
          collecting: false,
        }).unwrap();
        setNewGigId(newGig.id);
      }
    } else if (gig.rehearsal) {
      // Existing rehearsal: update it.
      await editRehearsal({
        modifyStartDatetime: modifyStartDatetime ?? 0,
        eventInstanceForGig: {
          gig: gig.id,
          eventInstance: {
            name: gigData.name,
            startDatetime: gigData.startDatetime,
            endDatetime: gigData.endDatetime,
            publishedDatetime: gigData.publishedDatetime,
            place: gigData.place,
            details: gigData.details,
          },
        },
      });
    } else {
      // Existing gig: update it.
      await editGig({
        ...gig,
        ...gigData,
        ...overrides,
      });
    }
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
    if (gig.id === -1) {
      setNewGigId(-2);
    }
  };

  const startEditing = () => {
    setControlsFromGig();
    setEditing(true);
  };

  const setControlsFromGig = useCallback(() => {
    setName(eventInstance.name);
    setStartDateTime(new Date(eventInstance.startDatetime));
    setEndDateTime(new Date(eventInstance.endDatetime));
    setPublishGig(eventInstance.publishedDatetime !== 0);
    setPublishedDateTime(
      new Date(
        eventInstance.publishedDatetime === 0
          ? eventInstance.startDatetime
          : eventInstance.publishedDatetime,
      ),
    );
    setStartDateTimeError(false);
    setEndDateTimeError(false);
    setPublishedDateTimeError(false);
    setEndEdited(true);
    setPublishedEdited(true);
    setTags(orderedGigTags);
    setPlaceId(eventInstance.place.id);
    setPlaceName(eventInstance.place.name);
    setPlaceAddress(eventInstance.place.address);
    setDetails(eventInstance.details);
    setDraft(gig.draft);
  }, [gig]);

  useEffect(() => {
    if (gig.id !== -1) {
      setControlsFromGig();
    }
    if (gig.id === -1) {
      setEditing(true);
      setName("");
      setPublishGig(false);
      setPublishedDateTime(null);
      setStartDateTimeError(false);
      setEndDateTimeError(false);
      setPublishedDateTimeError(false);
      setEndEdited(false);
      setPublishedEdited(false);
      setTags([]);
      setPlaceId("");
      setPlaceName("");
      setPlaceAddress("");
      setDetails("");
      if (gig.rehearsal) {
        setStartDateTime(
          setMinutes(setHours(new Date(modifyStartDatetime ?? 0), 19), 30),
        );
        setEndDateTime(
          setMinutes(setHours(new Date(modifyStartDatetime ?? 0), 22), 0),
        );
        setDraft(false);
      } else {
        setStartDateTime(null);
        setEndDateTime(null);
        setDraft(true);
      }
    }
  }, [gig, setControlsFromGig]);

  const setPlaceFieldRef = (element: HTMLDivElement) => {
    if (element !== null) {
      const options = {
        componentRestrictions: { country: "GB" },
      };
      // eslint-disable-next-line no-undef
      const autocomplete = new google.maps.places.Autocomplete(
        getHtmlInputElement(element),
        options,
      );
      autocomplete.setFields(["place_id", "name", "formatted_address"]);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.place_id && place.name && place.formatted_address) {
          setPlaceId(place.place_id);
          setPlaceName(place.name);
          setPlaceAddress(place.formatted_address);
        }
      });
    }
  };

  const exportAsCsv = async function () {
    window.location.href = await urlSigner.signUrl(
      apiLink(`gigs/${gig.id}/export`),
    );
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12} md={"auto"}>
          {!editing && (gig.draft || gig.publishedDatetime !== 0) ? (
            <>
              <Grid item>
                <Stack direction="row" spacing={1}>
                  {gig.draft && (
                    <TooltippedComponent
                      tooltip="Neither players nor the public can see this gig"
                      component={
                        <Chip
                          icon={<VisibilityOffIcon />}
                          label="Hidden"
                          color="secondary"
                        />
                      }
                    />
                  )}
                  {gig.publishedDatetime !== 0 && (
                    <TooltippedComponent
                      tooltip="Gig is visible to the public"
                      component={
                        <Chip
                          icon={<PublicIcon />}
                          label="Public"
                          color="primary"
                        />
                      }
                    />
                  )}
                </Stack>
              </Grid>
              <div className={classes.spacer} />
            </>
          ) : null}
          <Grid container>
            <Grid item>
              {editing ? (
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  variant="standard"
                  fullWidth
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  InputProps={{
                    classes: {
                      input: classes.h3,
                    },
                  }}
                />
              ) : (
                <Typography variant="h3">{eventInstance.name}</Typography>
              )}
            </Grid>
            <Grid item>
              {editing || !canEditGig ? null : (
                <TooltippedComponent
                  tooltip={`Edit ${eventType}`}
                  component={
                    <Button onClick={startEditing}>
                      <EditIcon />
                    </Button>
                  }
                />
              )}
            </Grid>
          </Grid>

          {editing ? (
            <>
              <div className={classes.spacer} />
              <DateTimePicker
                label="Start date and time"
                value={startDatetime}
                onChange={handleSetStartDateTime}
                ampm={false}
                onError={(reason) => setStartDateTimeError(reason !== null)}
              />
              <Box component="span" mr={2} />
              <DateTimePicker
                label="End date and time"
                value={endDatetime}
                onChange={handleSetEndDateTime}
                ampm={false}
                minDateTime={startDatetime || new Date(0)}
                openTo="hours"
                onError={(reason) => setEndDateTimeError(reason !== null)}
              />
            </>
          ) : (
            <>
              <Typography variant="h6" color="textSecondary">
                <DatetimeSpan datetime={eventInstance} />
              </Typography>
              {gig.publishedDatetime !== 0 && (
                <Typography>
                  Public gig time:{" "}
                  {publishedTimeFormat.format(gig.publishedDatetime)}
                </Typography>
              )}
            </>
          )}

          {!gig.rehearsal && editing ? (
            <>
              <div className={classes.spacer} />
              <TagAutocomplete tags={tags} setTags={setTags} addNew={true} />
            </>
          ) : (
            <div className={classes.tags}>
              {orderedGigTags.map((tag) => (
                <TagChip key={tag.id} tag={tag} />
              ))}
            </div>
          )}

          {editing ? (
            <>
              <div className={classes.spacer} />
              <TextField
                margin="dense"
                id="location"
                label="Location"
                type="text"
                variant="standard"
                fullWidth
                ref={setPlaceFieldRef}
              />
            </>
          ) : (
            <>
              <p>
                {eventInstance.place.name}
                <br />
                {eventInstance.place.address}
              </p>

              {eventInstance.place.id && !noGoogleMaps && (
                <iframe
                  title="Map"
                  frameBorder="0"
                  src={getEmbedUrl(gig.place.id, 15)}
                  allowFullScreen
                />
              )}
            </>
          )}

          {!gig.rehearsal && editing ? (
            <>
              <div className={classes.spacer} />
              <FormControlLabel
                control={
                  <Switch
                    checked={!draft}
                    onChange={(event) => setDraft(!event.target.checked)}
                    name="draft"
                  />
                }
                label="Visible to players"
              />
            </>
          ) : null}

          {!gig.rehearsal && editing && !draft && (
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={publishGig}
                    onChange={(event) => {
                      setPublishGig(event.target.checked);
                    }}
                    name="publish"
                  />
                }
                label="Visible to the public"
              />
              {publishGig && (
                <DateTimePicker
                  label="Published start time"
                  value={publishedDatetime}
                  onChange={handleSetPublishedDateTime}
                  ampm={false}
                  onError={(reason) =>
                    setPublishedDateTimeError(reason !== null)
                  }
                />
              )}
            </div>
          )}
        </Grid>

        {editing || gig.rehearsal ? null : (
          <Grid item xs={12} md="auto">
            <Grid
              container
              spacing={2}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              {knownAvailabilityCount === availabilityCount ? (
                <Grid item>All availability collected</Grid>
              ) : gig.collecting ? (
                <>
                  <Grid key={0} item>
                    Collecting availability&hellip; ({percentage}%)
                  </Grid>
                  {canEditGig && (
                    <Grid key={1} item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={pastGig}
                        onClick={() => collectingOff()}
                      >
                        Stop
                      </Button>
                    </Grid>
                  )}
                </>
              ) : (
                canEditGig && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={pastGig}
                      onClick={() => setConfirmCollectModal(true)}
                    >
                      Get&nbsp;availability&hellip;
                    </Button>
                  </Grid>
                )
              )}
              {/* We prevent gig export if not a full band manager. Could include it, but it probably isn't very useful
                  and better to lock things down as much as possible. */}
              {canEditGig && (
                <Grid item>
                  <TooltippedComponent
                    tooltip="Export gig"
                    component={
                      <Button onClick={() => exportAsCsv()}>
                        <ImportExportIcon />
                      </Button>
                    }
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md="auto">
              {availabilityRequestDatetime === undefined ||
              // -1 means n/a; but could also be before current time because of time moving forward since we retrieved
              // it, so handle both cases.
              availabilityRequestDatetime < new Date().getTime() ? null : (
                <Grid item sx={{ pt: 2 }}>
                  Availability request will go out{" "}
                  {formatDistanceToNow(availabilityRequestDatetime, {
                    addSuffix: true,
                  })}
                  <Button onClick={() => setCollectNowModal(true)}>
                    Send&nbsp;now&hellip;
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>

      {editing ? (
        <>
          <div className={classes.bigSpacer} />
          <TextField
            margin="dense"
            id="details"
            label="Details"
            variant="outlined"
            fullWidth
            multiline
            value={details}
            onChange={(event) => setDetails(event.target.value)}
          />
        </>
      ) : null}

      <ReactMarkdown>{details}</ReactMarkdown>

      {gig.id === -1 || editing ? null : (
        // TODO: maybe pass Gig and EventInstance
        <GigDetailsPlayerList
          gigId={gig.id}
          startDatetime={eventInstance.startDatetime}
        />
      )}

      {editing ? (
        <Grid
          container
          className={classes.confirm}
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Grid item>
            {gig.id === -1 ? null : (
              <Button
                variant="contained"
                onClick={() => setConfirmDeleteModal(true)}
              >
                Delete {eventType}
              </Button>
            )}
          </Grid>
          <Grid item>
            <Grid container alignItems="flex-end" justifyContent="flex-end">
              <Box pr={1}>
                <Grid item>
                  <Button onClick={handleCancel}>Cancel</Button>
                </Grid>
              </Box>
              <Grid item>
                <PreventNavigation
                  confirmText={
                    gig.id === -1 ? `Add ${eventType}` : "Confirm changes"
                  }
                  confirmChanges={() =>
                    gig.id !== -1 && !gig.draft && draft
                      ? setConfirmHideGigModal(true)
                      : confirmChanges({})
                  }
                  beforeNavigate={handleCancel}
                  saving={
                    isAddLoading || isEditLoading || isEditRehearsalLoading
                  }
                  disabled={
                    startDateTimeError ||
                    endDateTimeError ||
                    (publishGig && publishedDateTimeError) ||
                    !name ||
                    !startDatetime ||
                    !endDatetime
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      <Dialog
        open={confirmCollectModal}
        onClose={() => setConfirmCollectModal(false)}
        aria-labelledby="collect-dialog-title"
        aria-describedby="collect-dialog-description"
      >
        <DialogTitle id="collect-dialog-title">
          Start collecting availability?
        </DialogTitle>
        <DialogContent id="collect-dialog-description">
          <Stack spacing={1}>
            <DialogContentText>
              Everyone assigned to this gig will receive an email asking them
              for their availability. They will continue to receive email chases
              occasionally until they either provide their availability or you
              ask to stop collecting availability.
            </DialogContentText>
            {gig.draft ? (
              <DialogContentText>
                <strong>This gig is currently hidden.</strong> Starting to
                collect availability will publish this gig and make it visible
                to players.
              </DialogContentText>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmCollectModal(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => collectingOn()}
            color="primary"
            variant="contained"
            autoFocus
          >
            Get availability
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmHideGigModal}
        onClose={() => setConfirmHideGigModal(false)}
        aria-labelledby="hide-dialog-title"
        aria-describedby="hide-dialog-description"
      >
        <DialogTitle id="hide-dialog-title">Hide gig?</DialogTitle>
        <DialogContent id="hide-dialog-description">
          <Stack spacing={1}>
            <DialogContentText>
              This gig is currently visible to players, and you&apos;re about to
              hide it. Players will no longer see this gig, and any links they
              have to it will no longer work.
            </DialogContentText>
            {gig.publishedDatetime !== 0 ? (
              <DialogContentText>
                Additionally, this gig is currently visible to the public. It
                will be hidden from public view too.
              </DialogContentText>
            ) : null}
            {gig.collecting ? (
              <DialogContentText>
                Availability is currently being collected for this gig.
                Availability collection will stop.
              </DialogContentText>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmHideGigModal(false)} color="primary">
            Cancel
          </Button>
          <ConfirmButton
            variant="contained"
            color="primary"
            disabled={false}
            saving={isAddLoading || isEditLoading || isEditRehearsalLoading}
            onClick={confirmHideGig}
          >
            Confirm changes and hide gig
          </ConfirmButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDeleteModal}
        onClose={() => setConfirmDeleteModal(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete {eventType}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            This will delete the {eventType} and any availability collected for
            it. This cannot be undone. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDeleteEvent()} color="primary" autoFocus>
            Delete {eventType}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={collectNowModal}
        onClose={() => setCollectNowModal(false)}
        aria-labelledby="collect-now-dialog-title"
        aria-describedby="collect-now-dialog-description"
      >
        <DialogTitle id="collect-now-dialog-title">
          Send availability request notification now?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="collect-now-dialog-description">
            Send availability request notification to all players now?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCollectNowModal(false)}>Cancel</Button>
          <Button
            onClick={() => handleCollectNow()}
            variant="contained"
            autoFocus
          >
            Send request now
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
