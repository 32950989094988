import React, { useState } from "react";
import {
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { FullUser } from "dtos/User";
import CssBaseline from "@mui/material/CssBaseline";
import { Page, UserMenu } from "features/misc/UserMenu";
import { useAuth0 } from "@auth0/auth0-react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { selectMe } from "features/users/usersSlice";
import { flashMessage } from "redux-flash";
import { useAppDispatch, useAppSelector } from "app/store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDeleteMeMutation, useEditMeMutation } from "api/apiSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolbar: {
      display: "flex",
      justifyContent: "flex-end",
    },
    content: {
      maxWidth: 1000,
      padding: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 180,
    },
    button: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(5),
    },
    bigSpacer: {
      paddingTop: theme.spacing(5),
    },
    spacer: {
      paddingTop: theme.spacing(2),
    },
  }),
);

/**
 * Account UI.
 */
export function Account() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [editMe, { isLoading }] = useEditMeMutation();
  const [deleteMe] = useDeleteMeMutation();
  const me: FullUser | null = useAppSelector(selectMe);
  const [searchParams] = useSearchParams();

  const newUser =
    me !== null && (me.givenName === "" || me.emailAddress === "");
  const noEmail = me !== null && me.emailAddress === "";

  const { logout } = useAuth0();

  const [givenName, setGivenName] = useState(me === null ? "" : me.givenName);
  const [familyName, setFamilyName] = useState(
    me === null ? "" : me.familyName,
  );
  const [emailAddress, setEmailAddress] = useState(
    me === null ? "" : me.emailAddress,
  );

  const redirect = searchParams.get("redirect");
  const [modalOpen, setModalOpen] = useState(false);
  const handleConfirmDelete = async () => {
    await deleteMe();
    logout();
  };

  const submit = async function (event: React.SyntheticEvent) {
    event.preventDefault();

    await editMe({ givenName, familyName, emailAddress });
    if (!newUser) {
      // We only do this for existing users. New users will get a toast via the JoinBand page.
      await dispatch(flashMessage("Account details updated"));
    }
    if (redirect !== null) {
      // This happens when a new player has signed up by clicking on an invitation. We now need to redirect
      // to the "join" page which will call the backend to join the user to the band.
      navigate(redirect, { replace: true });
    } else {
      navigate("/player/dashboard");
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <UserMenu me={me} page={Page.ACCOUNT} logout={logout} />
        </Toolbar>
      </AppBar>

      <div className={classes.content}>
        <Typography variant="h3">
          {newUser ? "Welcome to Can You Come To Band!" : "Account settings"}
        </Typography>

        <p>
          {newUser
            ? noEmail
              ? "Just one more step and then you're good to go. Please set up your email address so we can send you availability requests."
              : "Just one more step and then you're good to go. Please set up your name so others can identify you."
            : "Please use your real name so others can identify you!"}
        </p>

        <form onSubmit={submit}>
          {noEmail ? null : (
            <>
              <div>
                <TextField
                  className={classes.formControl}
                  autoFocus
                  margin="dense"
                  id="givenName"
                  label="Given name"
                  type="text"
                  fullWidth
                  value={givenName}
                  onChange={(event) => setGivenName(event.target.value)}
                />
              </div>

              <div>
                <TextField
                  className={classes.formControl}
                  margin="dense"
                  id="familyName"
                  label="Family name"
                  type="text"
                  fullWidth
                  value={familyName}
                  onChange={(event) => setFamilyName(event.target.value)}
                />
              </div>
            </>
          )}

          {noEmail ? (
            <div>
              <TextField
                className={classes.formControl}
                margin="dense"
                id="emailAddress"
                label="Email address"
                type="text"
                fullWidth
                value={emailAddress}
                onChange={(event) => setEmailAddress(event.target.value)}
              />
            </div>
          ) : null}

          <div className={classes.button}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={
                me === null ||
                isLoading ||
                givenName.trim() === "" ||
                familyName.trim() === "" ||
                emailAddress.trim() === ""
              }
            >
              {newUser ? (noEmail ? "Set email" : "Set name") : "Update"}
            </Button>
          </div>
        </form>

        {newUser ? (
          <></>
        ) : (
          <div>
            <div className={classes.bigSpacer} />
            <Typography variant="h3">Delete account</Typography>
            <div className={classes.spacer} />
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setModalOpen(true)}
            >
              Delete account
            </Button>
          </div>
        )}
      </div>

      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete account?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will permanently delete your account and remove you and your
            availability information from all bands you belong to. You cannot
            undo this operation. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick={handleConfirmDelete}>
            Delete account
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
