import React from "react";

const dateFormat = new Intl.DateTimeFormat(undefined, {
  weekday: "long",
  day: "numeric",
  month: "long",
  year: "numeric",
});

const startTimeFormat = new Intl.DateTimeFormat(undefined, {
  hour: "numeric",
  minute: "numeric",
});

const endTimeFormat = new Intl.DateTimeFormat(undefined, {
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "short",
});

export interface WithDatetime {
  startDatetime: number;
  endDatetime: number;
}

/**
 * Returns a span containing a nicely formatted description of the gig start and end date/time.
 */
export function DatetimeSpan({ datetime }: { datetime: WithDatetime }) {
  // We do this, rather than date and start time using a single formatter, because in CI we seem to get
  // "Sunday, November 13, 2022 at 9:30 AM" whereas locally we get "Sunday, November 13, 2022, 9:30 AM", even though we
  // appear to have exactly the same formatter options (including locale, timezone, etc).
  const date = dateFormat.format(datetime.startDatetime);
  const startTime = startTimeFormat.format(datetime.startDatetime);
  const endTime = endTimeFormat.format(datetime.endDatetime);

  return (
    <span>
      {date}, {startTime}&nbsp;&ndash;&nbsp;{endTime}
    </span>
  );
}
