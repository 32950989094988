import React from "react";
import { CalendarGrid } from "features/calendar/CalendarGrid";
import {
  addMonths,
  format,
  startOfMonth,
  startOfToday,
  subMonths,
} from "date-fns";
import { enGB } from "date-fns/locale";
import { IconButton, Stack, Typography } from "@mui/material";
import { ChevronLeftSharp, ChevronRightSharp } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";

/**
 * Displays a calendar of events for the band manager.
 */
export function Calendar() {
  const params = useParams();
  const navigate = useNavigate();
  const date =
    params.year && params.month
      ? new Date(parseInt(params.year), parseInt(params.month) - 1, 1, 0, 0, 0)
      : startOfMonth(new Date());
  const today = startOfToday();

  function goTo(date: Date) {
    navigate(`/manager/calendar/${date.getFullYear()}/${date.getMonth() + 1}`);
  }

  return (
    // Have to use flexbox instead of Stack otherwise negative margin in CalendarGrid gets overridden.
    <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <Stack direction="row" spacing={2} style={{ alignItems: "center" }}>
        <Button
          onClick={() => navigate("/manager/calendar")}
          variant="outlined"
        >
          Today
        </Button>
        <Stack direction="row">
          <IconButton onClick={() => goTo(subMonths(date, 1))}>
            <ChevronLeftSharp />
          </IconButton>
          <IconButton onClick={() => goTo(addMonths(date, 1))}>
            <ChevronRightSharp />
          </IconButton>
        </Stack>
        <Typography variant="h5">
          {format(date, "LLLL yyyy", { locale: enGB })}
        </Typography>
      </Stack>
      <CalendarGrid date={date} today={today} />
    </div>
  );
}
