/**
 * Returns the keys of an object that uses numbers as keys as an array of numeric keys.
 */
import { Dictionary } from "@reduxjs/toolkit";

export function keys<V>(obj: { [id: number]: V }): number[] {
  return Object.keys(obj).map((id) => parseInt(id));
}

/**
 * Filters undefined values from the Dictionary object returned by Redux Toolkit's createEntityAdapter.
 */
export function filterDictionary<T>(dictionary: Dictionary<T>): {
  [key: string]: T;
} {
  return Object.keys(dictionary).reduce(
    (result, key) => {
      const value = dictionary[key];
      return value === undefined ? result : { ...result, [key]: value };
    },
    {} as { [key: string]: T },
  );
}

/**
 * Returns the given number as a "rank": 1 -> 1st, 2 -> 2nd, 3 -> 3rd, 4 -> 4th etc.
 */
export function th(value: number): string {
  if (value % 100 === 11 || value % 100 === 12 || value % 100 === 13) {
    return `${value}th`;
  } else if (value % 10 === 1) {
    return `${value}st`;
  } else if (value % 10 === 2) {
    return `${value}nd`;
  } else if (value % 10 === 3) {
    return `${value}rd`;
  } else {
    return `${value}th`;
  }
}

/**
 * e.g. count = 2, singular = "dog" -> "2 dogs"
 */
export function plural(
  count: number,
  singular: string,
  plural?: string,
): string {
  return count + " " + (count === 1 ? singular : plural ?? singular + "s");
}

/**
 * Turns an array of T, where T has ids into a map from id to T.
 */
export function byId<T extends { id: number }>(array: T[]) {
  return array.reduce<{ [id: number]: T }>((arrayById, obj) => {
    arrayById[obj.id] = obj;
    return arrayById;
  }, {});
}

/**
 * Turns an array of T, and a way of extracting an id from T, into a map from id to list of Ts with that id.
 */
export function groupBy<T>(
  array: T[],
  idExtractor: (item: T) => number,
): { [id: number]: T[] } {
  return array.reduce<{ [id: number]: T[] }>((arrayById, obj) => {
    const id = idExtractor(obj);
    let values = arrayById[id];
    if (values === undefined) {
      values = [];
      arrayById[id] = values;
    }
    values.push(obj);
    return arrayById;
  }, {});
}

/**
 * Converts a map from string to V1 into a new map from string to V2, based on applying a mapping function.
 */
export function mapValues<V1, V2>(
  object: { [key: string]: V1 },
  mapFn: (v: V1) => V2,
): { [key: string]: V2 } {
  return Object.keys(object).reduce(
    (result: { [key: string]: V2 }, key: string) => {
      result[key] = mapFn(object[key]);
      return result;
    },
    {},
  );
}

/**
 * Returns a link to the API.
 *
 * e.g. given "bands/1/calendar.ics", returns "https://api.canyoucometoband.com/api/bands/1/calendar.ics" in production.
 */
export function apiLink(url: string) {
  const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:3000/api";
  return `${baseUrl}/${url}`;
}
