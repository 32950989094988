import React, { useMemo, useState } from "react";
import { Player } from "dtos/Player";
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { fullName } from "features/users/userUtils";
import { selectBandId, selectMe } from "features/users/usersSlice";
import { KeyboardDoubleArrowDown, Person } from "@mui/icons-material";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { ConfirmChanges } from "utils/ConfirmChanges";
import { User } from "dtos/User";
import { TooltippedComponent } from "utils/TooltippedComponent";
import { useAppSelector } from "app/store";
import { byId, groupBy } from "utils/utils";
import { useNavigate } from "react-router-dom";
import {
  getAvailabilitiesByGigIdAndStartTime,
  useDeletePlayerMutation,
  useEditPlayerMutation,
  useGetEventInstancesQuery,
  useGetManagerAvailabilitiesQuery,
  useGetPlayersQuery,
  useGetSeatsQuery,
  useGetUsersQuery,
  useUpdatePlayerSeatMutation,
} from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { usePermissions } from "auth/usePermissions";
import { MANAGER_ROLE, PLAYER_ROLE } from "dtos/Role";
import {
  AvailabilityStatus,
  AvailabilitySummary,
} from "domain/availabilityDomain";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    availabilityOverview: {
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    availabilityOverviewWhileEditing: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    availabilityBlob: {
      display: "inline-block",
      width: "4px",
      height: "15px",
      marginRight: "1px",
    },
    available: {
      backgroundColor: "#0a0",
    },
    unavailable: {
      backgroundColor: "#d00",
    },
    bandManagerColumn: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    youLabel: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    instrumentColumn: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  }),
);

/**
 * Table of the current players in the band, along with controls to remove them and upgrade them to band managers.
 */
export function CurrentPlayers() {
  const bandId = useAppSelector(selectBandId);
  const { data: players = [] } = useGetPlayersQuery(bandId ?? skipToken);
  const [editPlayer, { isLoading: editPlayerIsLoading }] =
    useEditPlayerMutation();
  const [updatePlayerSeat, { isLoading: updatePlayerSeatIsLoading }] =
    useUpdatePlayerSeatMutation();
  const [deletePlayer, { isLoading: deletePlayerIsLoading }] =
    useDeletePlayerMutation();
  const playersById = byId(players);
  const me: User | null = useAppSelector(selectMe);
  const [deletePlayerId, setDeletePlayerId] = useState(-1);
  const [modifyRoleId, setModifyRoleId] = useState(-1);
  const [modifySeatId, setModifySeatId] = useState(-1);
  const [editingSeatPlayerId, setEditingSeatPlayerId] = useState(-1);
  const { data: allSeats = [] } = useGetSeatsQuery(bandId ?? skipToken);
  const seats = useMemo(
    () => allSeats.filter((s) => !s.deleted).sort((a, b) => a.order - b.order),
    [allSeats],
  );
  const navigate = useNavigate();

  const permissions = usePermissions();
  const canUpdatePlayers = permissions.hasPermission("UPDATE_PLAYER");
  const modifyRoleIsManager = permissions.isManager(playersById[modifyRoleId]);
  const modifyRoleIsMe = playersById[modifyRoleId]?.user === me?.id;
  const managerCount = Object.values(playersById).filter((p) =>
    permissions.isManager(p),
  ).length;

  const availabilitiesByUserId = useRecentAvailability(bandId, players);

  const playerIdsBySeat = Object.values(playersById).reduce(
    (playersBySeat, player) => {
      const val = playersBySeat[player.seat] || [];
      val.push(player.id);
      playersBySeat[player.seat] = val;
      return playersBySeat;
    },
    {} as { [seatId: number]: number[] },
  );

  const removeFromBand = (playerId: number | undefined) => {
    if (playerId) {
      handleKebabMenuClose();
      setDeletePlayerId(playerId);
    }
  };

  const confirmRemoveFromBand = async () => {
    await deletePlayer(deletePlayerId);
    setDeletePlayerId(-1);
  };

  const modifyRole = (playerId: number | undefined) => {
    if (playerId) {
      handleKebabMenuClose();
      setModifyRoleId(playerId);
    }
  };

  const confirmModifyRole = async (manager: boolean) => {
    const updatedPlayer = {
      ...playersById[modifyRoleId],
      role: manager ? MANAGER_ROLE : PLAYER_ROLE,
      roleMetadata: manager ? '{ "manages_seats": [] }' : "{}",
    };
    await editPlayer(updatedPlayer);
    setModifyRoleId(-1);
    if (modifyRoleIsMe) {
      navigate("/player/dashboard");
    }
  };

  const beginEditingSeat = (playerId: number | undefined) => {
    if (playerId) {
      handleKebabMenuClose();
      setEditingSeatPlayerId(playerId);
    }
  };

  const editSeat = async (andFutureAvailability: boolean) => {
    if (playersById[editingSeatPlayerId].seat !== modifySeatId) {
      const updatedPlayer = {
        ...playersById[editingSeatPlayerId],
        seat: modifySeatId,
      };
      await updatePlayerSeat({
        player: updatedPlayer,
        updateFuture: andFutureAvailability,
      });
    }
    setEditingSeatPlayerId(-1);
    setModifySeatId(-1);
  };

  const updateSeatDialogue = (
    <ConfirmChanges
      open={modifySeatId !== -1}
      title="Modify seat"
      message="Do you also want to update this player's seat for all future gigs and rehearsals?"
      confirmText="Update"
      onCancel={() => {
        setModifySeatId(-1);
        setEditingSeatPlayerId(-1);
      }}
      onConfirm={() => editSeat(true)}
      saving={updatePlayerSeatIsLoading}
      additionalButton={
        <Button
          onClick={() => editSeat(false)}
          color="primary"
          disabled={updatePlayerSeatIsLoading}
        >
          Don&apos;t update
        </Button>
      }
    />
  );

  const removePlayerDialogue = (
    <ConfirmChanges
      open={deletePlayerId !== -1}
      title="Remove player"
      message="This will remove this player from the band. The player will be automatically marked as unavailable for all future gigs. This cannot be undone. Are you sure?"
      confirmText="Remove player"
      onCancel={() => setDeletePlayerId(-1)}
      onConfirm={confirmRemoveFromBand}
      saving={deletePlayerIsLoading}
    />
  );

  const modifyRoleDialogue = (
    <ConfirmChanges
      open={modifyRoleId !== -1}
      title={
        modifyRoleIsManager
          ? modifyRoleIsMe
            ? "Downgrade yourself to a normal player"
            : "Downgrade to player"
          : "Upgrade to band manager"
      }
      message={
        modifyRoleIsManager
          ? modifyRoleIsMe
            ? "This will remove all band manager features for this player, and the player is you! " +
              "You'll need to get another band manager to reinstate you if you change your mind. Are you sure?"
            : "This will remove all band manager features for this player. Are you sure?"
          : "This will give this player access to all band manager features. Are you sure?"
      }
      confirmText={
        modifyRoleIsManager
          ? modifyRoleIsMe
            ? "Downgrade yourself to player"
            : "Downgrade to player"
          : "Upgrade to band manager"
      }
      onCancel={() => setModifyRoleId(-1)}
      onConfirm={() => confirmModifyRole(!modifyRoleIsManager)}
      saving={editPlayerIsLoading}
    />
  );

  const [kebabMenuAnchor, setKebabMenuAnchor] = useState<null | HTMLElement>(
    null,
  );
  const [kebabPlayer, setKebabPlayer] = useState<Player | undefined>(undefined);
  const kebabMenuOpen = Boolean(kebabMenuAnchor);
  const handleKebabClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    player: Player,
  ) => {
    setKebabPlayer(player);
    setKebabMenuAnchor(event.currentTarget);
  };
  const handleKebabMenuClose = () => {
    setKebabMenuAnchor(null);
  };

  const isManager = permissions.isManager(kebabPlayer);
  const isMe = me === null || kebabPlayer?.user === me.id;

  const kebabMenu = (
    <Menu
      id="context-menu"
      anchorEl={kebabMenuAnchor}
      open={kebabMenuOpen}
      onClose={handleKebabMenuClose}
    >
      <MenuItem onClick={() => removeFromBand(kebabPlayer?.id)} disabled={isMe}>
        <ListItemIcon>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Remove from band</ListItemText>
      </MenuItem>
      {isManager ? (
        <TooltippedComponent
          tooltip={
            managerCount === 1
              ? "You can't downgrade yourself if you're the only band manager. Make somebody else a band manager first."
              : ""
          }
          component={
            <MenuItem
              onClick={() => modifyRole(kebabPlayer?.id)}
              disabled={managerCount === 1}
            >
              <ListItemIcon>
                <KeyboardDoubleArrowDown fontSize="small" />
              </ListItemIcon>
              <ListItemText>Downgrade to player</ListItemText>
            </MenuItem>
          }
        />
      ) : (
        <MenuItem onClick={() => modifyRole(kebabPlayer?.id)}>
          <ListItemIcon>
            <UpgradeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Upgrade to manager</ListItemText>
        </MenuItem>
      )}
      <MenuItem onClick={() => beginEditingSeat(kebabPlayer?.id)}>
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Edit seat</ListItemText>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <TableContainer component={Paper} sx={{ maxWidth: 1000 }}>
        <Table>
          <TableBody>
            {seats
              .flatMap((seat) => playerIdsBySeat[seat.id])
              .filter((playerId) => playerId)
              .map((playerId) => playersById[playerId])
              .map((player) => (
                <PlayerTableRow
                  key={player.id}
                  player={player}
                  editingSeatPlayerId={editingSeatPlayerId}
                  editSeat={(seatId) => setModifySeatId(seatId)}
                  cancelEditingSeat={() => setEditingSeatPlayerId(-1)}
                  kebabMenuClick={handleKebabClick}
                  canUpdatePlayers={canUpdatePlayers}
                  availabilitiesByUserId={availabilitiesByUserId}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {updateSeatDialogue}
      {removePlayerDialogue}
      {modifyRoleDialogue}
      {kebabMenu}
    </>
  );
}

interface PlayerTableRowProps {
  player: Player;
  editingSeatPlayerId: number;
  editSeat: (seatId: number) => void;
  cancelEditingSeat: () => void;
  kebabMenuClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    player: Player,
  ) => void;
  canUpdatePlayers: boolean;
  availabilitiesByUserId: Map<number, boolean[]>;
}

function PlayerTableRow({
  player,
  editingSeatPlayerId,
  editSeat,
  cancelEditingSeat,
  kebabMenuClick,
  canUpdatePlayers,
  availabilitiesByUserId,
}: PlayerTableRowProps) {
  const me: User | null = useAppSelector(selectMe);
  const bandId = useAppSelector(selectBandId);
  const { data: users = [] } = useGetUsersQuery(bandId ?? skipToken);
  const user = byId(users)[player.user];
  const { data: seats = [] } = useGetSeatsQuery(bandId ?? skipToken);
  const seat = byId(seats)[player.seat];
  const [selectedSeat, setSelectedSeat] = useState(player.seat);
  const permissions = usePermissions();
  const classes = useStyles();

  const isManager = permissions.isManager(player);
  const isMe = me === null || player.user === me.id;

  const recentRehearsalAvailability =
    availabilitiesByUserId.get(player.user) ?? [];
  const missedCount = recentRehearsalAvailability.filter((a) => !a).length;

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell
        className={
          editingSeatPlayerId === -1
            ? classes.availabilityOverview
            : classes.availabilityOverviewWhileEditing
        }
      >
        <TooltippedComponent
          tooltip={`Recent rehearsal availability (${missedCount} missed)`}
          component={
            <span role="group" aria-label="Availability summary">
              {recentRehearsalAvailability.map((available, index) => (
                <span
                  aria-label={available ? "Available" : "Unavailable"}
                  key={index}
                  className={`${classes.availabilityBlob} ${
                    available ? classes.available : classes.unavailable
                  }`}
                ></span>
              ))}
            </span>
          }
        />
      </TableCell>
      <TableCell
        className={classes.bandManagerColumn}
        align="right"
        padding="none"
        width={40}
      >
        {isManager ? (
          <TooltippedComponent
            tooltip={"Band manager"}
            component={<Person color="secondary" />}
          />
        ) : null}
      </TableCell>
      <TableCell component="th" scope="row">
        {fullName(user)}
        <span className={classes.youLabel}>{isMe ? " (you)" : null}</span>
      </TableCell>
      {player.id === editingSeatPlayerId ? (
        <TableCell>
          <span>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedSeat}
              label="Age"
              onChange={(event: SelectChangeEvent<number>) =>
                setSelectedSeat(parseInt(event.target.value as string))
              }
              size="small"
            >
              {seats
                .filter((seat) => !seat.deleted)
                .map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.name}
                  </MenuItem>
                ))}
            </Select>
            <TooltippedComponent
              tooltip={"Confirm seat change"}
              component={
                <IconButton
                  aria-label="Confirm seat change"
                  color="primary"
                  onClick={() => editSeat(selectedSeat)}
                >
                  <CheckIcon />
                </IconButton>
              }
            />
            <TooltippedComponent
              tooltip={"Discard seat change"}
              component={
                <IconButton
                  aria-label="Discard seat change"
                  color="primary"
                  onClick={() => cancelEditingSeat()}
                >
                  <CloseIcon />
                </IconButton>
              }
            />
          </span>
        </TableCell>
      ) : (
        <TableCell className={classes.instrumentColumn}>{seat?.name}</TableCell>
      )}
      {canUpdatePlayers && editingSeatPlayerId === -1 && (
        <TableCell padding="none" align="right" width={10}>
          <TooltippedComponent
            tooltip={"Actions..."}
            component={
              <IconButton
                color="primary"
                onClick={(event) => kebabMenuClick(event, player)}
              >
                <MoreVertIcon />
              </IconButton>
            }
          />
        </TableCell>
      )}
    </TableRow>
  );
}

/**
 * Returns Map<number, boolean[]>, mapping player id to an array of boolean indicating recent rehearsal availability.
 */
function useRecentAvailability(bandId: number | undefined, players: Player[]) {
  const { data: rehearsals = [] } = useGetEventInstancesQuery(
    {
      bandId: bandId === undefined ? 0 : bandId,
      filterCriteria: { year: "last90days" },
    },
    { skip: bandId === undefined },
  );

  const {
    data: managerAvailabilityInfo = {
      byId: {},
      byGigId: {},
      byGigIdAndStartTime: {},
    },
  } = useGetManagerAvailabilitiesQuery(
    {
      bandId: bandId === undefined ? 0 : bandId,
      filterCriteria: { year: "last90days", tags: [] },
    },
    { skip: bandId === null },
  );

  const last10Rehearsals = useMemo(() => rehearsals.slice(-10), [rehearsals]);

  return useMemo(() => {
    // Initialise a map from player id to an empty array of booleans indicating rehearsal availability.
    const availabilitiesByUserId: Map<number, boolean[]> = new Map();
    for (const player of players) {
      availabilitiesByUserId.set(player.user, []);
    }

    for (const rehearsal of last10Rehearsals) {
      const availabilities = getAvailabilitiesByGigIdAndStartTime(
        managerAvailabilityInfo,
        rehearsal.gig,
        rehearsal.eventInstance.startDatetime,
      );
      const availabilitiesByUserIdForRehearsal = groupBy(
        availabilities,
        (a) => a.user,
      );
      availabilitiesByUserId.forEach((availabilitySummaries, userId) => {
        const availabilitiesForUserForRehearsal =
          availabilitiesByUserIdForRehearsal[userId];
        if (availabilitiesForUserForRehearsal === undefined) {
          // Players are assumed to be available.
          availabilitySummaries.push(true);
        } else {
          // There should be exactly one availability per user, so take that and look up their availability.
          availabilitySummaries.push(
            new AvailabilitySummary(
              availabilitiesForUserForRehearsal[0].events,
              true,
            ).playerAvailability === AvailabilityStatus.AVAILABLE,
          );
        }
      });
    }
    return availabilitiesByUserId;
  }, [last10Rehearsals, managerAvailabilityInfo]);
}
