import React, { useMemo, useState } from "react";
import {
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { selectBandId } from "features/users/usersSlice";
import { Invitation } from "dtos/Invitation";
import { format, formatDistance } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import { byId, keys } from "utils/utils";
import { enGB } from "date-fns/locale";
import { useInterval } from "utils/useInterval";
import { ConfirmChanges } from "utils/ConfirmChanges";
import { TooltippedComponent } from "utils/TooltippedComponent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CancelIcon from "@mui/icons-material/Cancel";
import { red } from "@mui/material/colors";
import { useAppSelector } from "app/store";
import { CopyableTextField } from "utils/CopyableTextField";
import {
  useAddInvitationMutation,
  useDeleteInvitationMutation,
  useGetInvitationsQuery,
  useGetPlayersQuery,
  useGetSeatsQuery,
} from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { usePermissions } from "auth/usePermissions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      maxWidth: 1000,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 180,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(5),
    },
    spacer: {
      paddingTop: theme.spacing(2),
    },
    centred: {
      alignItems: "center",
    },
    invitationIcon: {
      paddingTop: "5px",
      paddingRight: theme.spacing(2),
    },
  }),
);

/**
 * Lets the band manager invite new players to the band by generating a link.
 */
export function InvitePlayer() {
  const classes = useStyles();

  const bandId = useAppSelector(selectBandId);

  const [invitation, setInvitation] = useState<string | null>(null);

  const { data: invitations = [], isLoading: invitationsAreLoading } =
    useGetInvitationsQuery(bandId ?? skipToken);
  const [addInvitation] = useAddInvitationMutation();
  const invitationsById = byId(invitations);
  const [deleteInvitation, { isLoading: deleteInvitationIsLoading }] =
    useDeleteInvitationMutation();

  const permissions = usePermissions();
  const canInvitePlayers = permissions.hasPermission("UPDATE_INVITATION");

  const [filterToEmptySeats, setFilterToEmptySeats] = useState(true);

  const [seat, setSeat] = useState("");
  const handleChange = async (event: SelectChangeEvent) => {
    setSeat(event.target.value);
    if (bandId !== undefined) {
      setInvitation(
        await addInvitation({
          bandId,
          seatId: parseInt(event.target.value),
        }).unwrap(),
      );
    }
  };

  const { data: players = [], isLoading: playersAreLoading } =
    useGetPlayersQuery(bandId ?? skipToken);
  const playersById = byId(players);
  const { data: allSeats = [], isLoading: seatsAreLoading } = useGetSeatsQuery(
    bandId ?? skipToken,
  );
  const seatsById = byId(allSeats);
  const seats = useMemo(
    () => allSeats.filter((s) => !s.deleted).sort((a, b) => a.order - b.order),
    [allSeats],
  );
  const filledSeats = Object.values(playersById).map((player) => player.seat);

  // Force a rerender every minute so the "expires" dates update without a refresh.
  const [count, setCount] = useState(0);
  useInterval(() => {
    setCount(count + 1);
  }, 1000 * 60);

  const [deleteId, setDeleteId] = useState(-1);

  const remove = (invitationId: number) => {
    setDeleteId(invitationId);
  };

  const confirmRemove = async () => {
    await deleteInvitation(deleteId);
    setDeleteId(-1);
  };

  const isExpired = (expiryDatetime: number) =>
    expiryDatetime - new Date().getTime() < 0;

  const deleteIdIsExpired = isExpired(
    invitationsById[deleteId]?.expiryDatetime,
  );

  const tableRow = (invitation: Invitation) => {
    const expired = isExpired(invitation.expiryDatetime);
    const used = invitation.usedDatetime !== 0;
    return (
      <TableRow
        key={invitation.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {seatsById[invitation.seat]?.name}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          title={format(new Date(invitation.expiryDatetime), "PPPPp", {
            locale: enGB,
          })}
        >
          <Grid container className={classes.centred}>
            <Grid item className={classes.invitationIcon}>
              {used ? (
                <CheckCircleIcon color="primary" />
              ) : expired ? (
                <CancelIcon style={{ color: red[500] }} />
              ) : (
                <HourglassEmptyIcon />
              )}
            </Grid>
            <Grid item>
              {used
                ? `Used ${formatDistance(
                    new Date(invitation.usedDatetime),
                    new Date(),
                  )} ago`
                : expired
                  ? "Expired"
                  : `Expires in ${formatDistance(
                      new Date(invitation.expiryDatetime),
                      new Date(),
                    )}`}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell width={10}>
          {used ? null : (
            <TooltippedComponent
              tooltip={expired ? "Remove" : "Revoke invitation"}
              component={
                <IconButton
                  aria-label={expired ? "Remove" : "Revoke invitation"}
                  color="primary"
                  onClick={() => remove(invitation.id)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            />
          )}
        </TableCell>
      </TableRow>
    );
  };

  const issueNewInvitation = (
    <>
      <Typography>
        You can add players to the band by sending them an invitation. Choose a
        seat for the person you want to invite.
      </Typography>

      <FormControlLabel
        control={
          <Switch
            checked={filterToEmptySeats}
            onChange={(event) => setFilterToEmptySeats(event.target.checked)}
            name="filterToEmptySeats"
          />
        }
        label="Filter to empty seats"
      />

      <FormControl className={classes.formControl}>
        <InputLabel id="seat-label">Seat</InputLabel>
        <Select
          labelId="seat-label"
          id="seat"
          value={seat}
          onChange={handleChange}
          label="Age"
        >
          {seats
            .filter(
              (seat) =>
                !filterToEmptySeats || filledSeats.indexOf(seat.id) === -1,
            )
            .map((seat) => (
              <MenuItem key={seat.id} value={seat.id}>
                {seat.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {invitation !== null && (
        <>
          <Typography>
            Send this link to the player you want to invite:
          </Typography>
          <CopyableTextField name="invitation" text={invitation} />
        </>
      )}

      <div className={classes.spacer} />
    </>
  );

  return invitationsAreLoading || playersAreLoading || seatsAreLoading ? (
    <Fade in={true} unmountOnExit style={{ transitionDelay: "800ms" }}>
      <LinearProgress />
    </Fade>
  ) : (
    <Stack spacing={3} className={classes.content}>
      {canInvitePlayers && issueNewInvitation}
      <>
        <Typography variant="h4">Issued invitations</Typography>
        <p>
          {keys(invitationsById).length === 0
            ? "There are no recently issued invitations."
            : "Expired and used invitations will be removed automatically."}
        </p>
        <TableContainer component={Paper} sx={{ maxWidth: 1000 }}>
          <Table size="small">
            <TableBody>
              {Object.values(invitationsById)
                .slice()
                // Newest invitation first, used invitations last.
                .sort((invitation1, invitation2) => {
                  const comparisonTime1 =
                    invitation1.usedDatetime === 0
                      ? invitation1.expiryDatetime
                      : Number.MAX_SAFE_INTEGER;
                  const comparisonTime2 =
                    invitation2.usedDatetime === 0
                      ? invitation2.expiryDatetime
                      : Number.MAX_SAFE_INTEGER;
                  return comparisonTime2 - comparisonTime1;
                })
                .map((invitation) => tableRow(invitation))}
            </TableBody>
          </Table>
        </TableContainer>
        <ConfirmChanges
          open={deleteId !== -1}
          title={deleteIdIsExpired ? "Remove invitation" : "Revoke invitation"}
          message={
            deleteIdIsExpired
              ? "This invitation has already expired. Removing it will have no effect other than stopping you seeing it anymore. Note that this happens automatically after a while anyway. This cannot be undone. Are you sure?"
              : "This will revoke this invitation, preventing anyone you've sent it to from using it to join the band. This cannot be undone. Are you sure?"
          }
          confirmText={
            deleteIdIsExpired ? "Remove invitation" : "Revoke invitation"
          }
          onCancel={() => setDeleteId(-1)}
          onConfirm={confirmRemove}
          saving={deleteInvitationIsLoading}
        />
      </>
    </Stack>
  );
}
