import React from "react";
import { Chip } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Tag } from "dtos/Tag";

const useStyles = makeStyles(() =>
  createStyles({
    tag: {
      marginBottom: "3px",
      marginRight: "3px",
    },
  }),
);

/**
 * Renders a gig tag given a name and background colour.
 */
export function RawTagChip(props: { name: string; chipColour: string }) {
  const { name, chipColour, ...chipProps } = props;
  const classes = useStyles();

  const red: number = parseInt(chipColour.substring(0, 2), 16);
  const green: number = parseInt(chipColour.substring(2, 4), 16);
  const blue: number = parseInt(chipColour.substring(4, 6), 16);

  // Choose black or white text depending on how bright the background colour appears to be.
  const textColour =
    red * 0.299 + green * 0.587 + blue * 0.114 > 186 ? "#000000" : "#ffffff";

  return (
    <Chip
      label={name}
      size="small"
      className={classes.tag}
      style={{ backgroundColor: "#" + chipColour, color: textColour }}
      {...chipProps}
    />
  );
}

/**
 * Renders a gig tag given its Tag object.
 */
export function TagChip(props: { tag: Tag }) {
  const { tag, ...chipProps } = props;
  const name = tag === undefined ? "Loading..." : tag.name;
  const chipColour = tag === undefined ? "000000" : tag.colour;

  return <RawTagChip name={name} chipColour={chipColour} {...chipProps} />;
}
