import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {
  Alert,
  Box,
  Tab as MuiTab,
  Tabs,
  Theme,
  Typography,
} from "@mui/material";
import { CopyableTextField } from "utils/CopyableTextField";
import { useUrlSigner } from "features/misc/signedUrl";
import { useAppSelector } from "app/store";
import { selectBandId } from "features/users/usersSlice";
import { useGetLastCalendarRequestQuery } from "api/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { format, formatDistanceToNow } from "date-fns";
import { enGB } from "date-fns/locale";
import { TooltippedComponent } from "utils/TooltippedComponent";
import { withStyles } from "@mui/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import { apiLink } from "utils/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      maxWidth: 1000,
      padding: theme.spacing(2),
    },
    status: {
      display: "flex",
      alignItems: "top",
      paddingTop: theme.spacing(2),
    },
    result: {
      width: "100%",
      maxWidth: "388px",
    },
  }),
);

const Tab = withStyles({
  root: {
    textTransform: "none",
  },
})(MuiTab);

/**
 * Shows the player a link to an iCalendar file for all gigs, which they can add to their calendar of choice.
 */
export function CalendarIntegration() {
  const classes = useStyles();
  const bandId = useAppSelector(selectBandId);
  const urlSigner = useUrlSigner();
  const { data: lastRequest } = useGetLastCalendarRequestQuery(
    bandId ?? skipToken,
  );

  const [url, setUrl] = useState("");
  useEffect(() => {
    const getUrl = async () => {
      if (bandId === undefined) {
        return;
      }
      setUrl(
        await urlSigner.signUrl(apiLink(`bands/${bandId}/calendar.ics`), true),
      );
    };
    getUrl();
  }, [bandId]);

  const [tab, setTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <div className={classes.main}>
      <Typography variant="h3" sx={{ pb: 1 }}>
        Calendar
      </Typography>
      <p>
        You can add gigs in Can You Come To Band to the calendar on your phone
        or computer. The calendar entries tell you whether you&apos;ve said you
        can do a gig, and whether you&apos;re required to play, as well as links
        back to Can You Come To Band.
      </p>
      <Box sx={{ boxShadow: 3, maxWidth: 388 }}>
        <img
          className={classes.result}
          src="/images/1x/calendar/result.png"
          srcSet="/images/2x/calendar/result.png 2x"
          alt="Result"
        />
      </Box>
      <p>
        <strong>Note that calendar sync can be infrequent.</strong> For example,
        Google Calendar refreshes every 12&mdash;48 hours, so updates might take
        time to be reflected. Each calendar entry includes a last updated time
        to make this clear.
      </p>
      <Typography sx={{ pt: 5 }}>
        To set up your calendar, copy this link and follow the instructions
        below:
      </Typography>
      <CopyableTextField name="iCalendar link" text={url} sx={{ pt: 1 }} />
      <div className={classes.status}>
        {lastRequest ? (
          <>
            <CheckCircleIcon color="primary" />
            <TooltippedComponent
              tooltip={format(new Date(lastRequest), "PPPPp", {
                locale: enGB,
              })}
              component={
                <Box sx={{ pl: 1 }}>
                  <Typography>
                    You&apos;ve successfully added Can You Come To Band to your
                    calendar.
                  </Typography>
                  <Typography sx={{ pt: 1 }}>
                    Last sync request:{" "}
                    {formatDistanceToNow(new Date(lastRequest))} ago
                  </Typography>
                </Box>
              }
            />
          </>
        ) : (
          <>
            <WarningIcon color="secondary" />
            <Box sx={{ pl: 1 }}>
              <Typography>
                It looks like you haven&apos;t yet added Can You Come To Band to
                your calendar. Please add the link above to your calendar tool.
                This text will update when things are properly set up.
              </Typography>
            </Box>
          </>
        )}
      </div>
      <Tabs
        variant="scrollable"
        scrollButtons={false}
        value={tab}
        onChange={handleTabChange}
        aria-label="Choose your calendar provider"
        sx={{ pt: 4 }}
      >
        <Tab label="Google Calendar" {...a11yProps(0)} />
        <Tab label="Apple Calendar on iPhone" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <Alert sx={{ mt: 2 }} severity="info">
          Use these instructions to add Can You Come To Band to your Google
          Calendar on your computer and/or your Android phone or iPhone.
          <p>
            <strong>
              Note: You need to use a computer, not a phone to set this up.
            </strong>{" "}
            Once done, though, the calendar will appear wherever you use Google
            Calendar, including on your phone.
          </p>
        </Alert>

        <Typography variant="h5" sx={{ pt: 2 }}>
          Step 1
        </Typography>
        <p>
          Open{" "}
          <a href="https://calendar.google.com/CalendarIntegration.tsx">
            Google Calendar
          </a>{" "}
          on a computer.
        </p>

        <Typography variant="h5" sx={{ pt: 2 }}>
          Step 2
        </Typography>
        <p>
          In the sidebar, click the plus icon next to &quot;Other
          Calendars&quot;:
        </p>
        <div>
          <img
            src="/images/1x/calendar/google/2.png"
            srcSet="/images/2x/calendar/google/2.png 2x"
            alt="Step 2"
          />
        </div>

        <Typography variant="h5" sx={{ pt: 2 }}>
          Step 3
        </Typography>
        <p>Click the menu item &quot;From URL&quot;:</p>
        <div>
          <img
            src="/images/1x/calendar/google/3.png"
            srcSet="/images/2x/calendar/google/3.png 2x"
            alt="Step 3"
          />
        </div>

        <Typography variant="h5" sx={{ pt: 2 }}>
          Step 4
        </Typography>
        <p>
          Copy the link above into the &quot;URL of calendar&quot; field, and
          click the &quot;Add calendar&quot; button (leave &quot;Make the
          calendar publicly accessible&quot; unchecked):
        </p>
        <div>
          <img
            src="/images/1x/calendar/google/4.png"
            srcSet="/images/2x/calendar/google/4.png 2x"
            alt="Step 4"
          />
        </div>

        <Typography variant="h5" sx={{ pt: 2 }}>
          Step 5
        </Typography>
        <p>
          On your phone, you may need to make sure the calendar is set to sync.
          Open the Google Calendar app <em>on your phone</em>, open the menu,
          and click &quot;Settings&quot;:
        </p>
        <div>
          <img
            src="/images/1x/calendar/google/5.png"
            srcSet="/images/2x/calendar/google/5.png 2x"
            alt="Step 5"
          />
        </div>

        <Typography variant="h5" sx={{ pt: 2 }}>
          Step 6
        </Typography>
        <p>
          You should see the calendar you added in steps 1&mdash;4. You might
          need to click &quot;Show more&quot; to see it. Click it:
        </p>
        <div>
          <img
            src="/images/1x/calendar/google/6.png"
            srcSet="/images/2x/calendar/google/6.png 2x"
            alt="Step 6"
          />
        </div>

        <Typography variant="h5" sx={{ pt: 2 }}>
          Step 7
        </Typography>
        <p>Make sure the &quot;Sync&quot; slider is turned on:</p>
        <div>
          <img
            src="/images/1x/calendar/google/7.png"
            srcSet="/images/2x/calendar/google/7.png 2x"
            alt="Step 7"
          />
        </div>
        <p>That&apos;s it! You should be done.</p>
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <Alert sx={{ mt: 2 }} severity="info">
          Use these instructions to add Can You Come To Band to your Apple
          Calendar on your iPhone.{" "}
          <p>
            <strong>
              Note: if you use Google Calendar on your iPhone, follow the Google
              Calendar instructions.
            </strong>
          </p>
        </Alert>
        <Typography variant="h5" sx={{ pt: 2 }}>
          Step 1
        </Typography>
        <p>
          Open the Calendar app on your iPhone and click &quot;Calendars&quot;
          at the bottom of the screen:
        </p>
        <div>
          <img
            src="/images/1x/calendar/iphone/1.png"
            srcSet="/images/2x/calendar/iphone/1.png 2x"
            alt="Step 1"
          />
        </div>
        <Typography variant="h5" sx={{ pt: 2 }}>
          Step 2
        </Typography>
        <p>Click &quot;Add Calendar&quot; at the bottom left of the screen:</p>
        <div>
          <img
            src="/images/1x/calendar/iphone/2.png"
            srcSet="/images/2x/calendar/iphone/2.png 2x"
            alt="Step 2"
          />
        </div>

        <Typography variant="h5" sx={{ pt: 2 }}>
          Step 3
        </Typography>
        <p>Choose &quot;Add Subscription Calendar&quot; from the menu:</p>
        <div>
          <img
            src="/images/1x/calendar/iphone/3.png"
            srcSet="/images/2x/calendar/iphone/3.png 2x"
            alt="Step 3"
          />
        </div>

        <Typography variant="h5" sx={{ pt: 2 }}>
          Step 4
        </Typography>
        <p>
          Copy the link above into the &quot;SUBSCRIPTION URL&quot; field, and
          click the &quot;Subscribe&quot; button:
        </p>
        <div>
          <img
            src="/images/1x/calendar/iphone/4.png"
            srcSet="/images/2x/calendar/iphone/4.png 2x"
            alt="Step 4"
          />
        </div>

        <Typography variant="h5" sx={{ pt: 2 }}>
          Step 5
        </Typography>
        <p>
          Edit any details you want to change, such as colour, and then click
          &quot;Add&quot; at the top of the screen:
        </p>
        <div>
          <img
            src="/images/1x/calendar/iphone/5.png"
            srcSet="/images/2x/calendar/iphone/5.png 2x"
            alt="Step 5"
          />
        </div>

        <p>That&apos;s it! You should be done.</p>
      </TabPanel>
    </div>
  );
}

function a11yProps(index: number | null) {
  return {
    id: `tab-${index}`,
    value: index,
    "aria-controls": `tab-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <span>{children}</span>}
    </div>
  );
}
