import {
  addDays,
  differenceInCalendarDays,
  endOfMonth,
  startOfMonth,
  subDays,
} from "date-fns";

/**
 * Given a date, returns an array containing the dates of the month that date belongs to. The array is always 7 columns
 * and enough rows to fit every date of the month (4-6). The rows and columns correspond to weeks of the month and days
 * of the week respectively. The first day of the month is always on the first row, and Mondays are always in the first
 * column. Each date is at midnight local time.
 */
export function getMonthArray(date: Date): Date[][] {
  // Get the day of the week of the first day of the month (0 = Sun, 1 = Mon, ...)
  const firstDayOfMonth = startOfMonth(date).getDay();
  // If we wanted the first day of the week displayed to be a Sunday, we can just subtract this number of days from the
  // start of the month. But we want it to be Monday, so subtract 1 (add 6 modulo 7).
  const subtractFromStart = (firstDayOfMonth + 6) % 7;
  // Now the day at the top left of the grid of days is the start of the month minus the amount calculated.
  let current = subDays(startOfMonth(date), subtractFromStart);

  const daysToDisplay = differenceInCalendarDays(endOfMonth(date), current) + 1;
  const rows = Math.ceil(daysToDisplay / 7);

  const days = [];
  for (let row = 0; row < rows; row++) {
    const week = [];
    for (let col = 0; col < 7; col++) {
      week.push(current);
      current = addDays(current, 1);
    }
    days.push(week);
  }

  return days;
}
