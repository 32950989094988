import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Box, Fade, LinearProgress, Link, Theme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useGetEventInstancesQuery } from "api/apiSlice";
import { useAppSelector } from "app/store";
import { selectBandId } from "features/users/usersSlice";
import { PlayerEventDetails } from "features/player/PlayerEventDetails";
import { useNavigate, useParams } from "react-router-dom";
import { EventInstance } from "dtos/EventInstance";
import { RehearsalList } from "features/player/RehearsalList";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      maxWidth: 1000,
      padding: theme.spacing(2),
    },
    paper: {
      marginTop: theme.spacing(2),
    },
    key: {
      display: "flex",
      justifyContent: "flex-end",
      flexWrap: "wrap",
      paddingRight: "0.2em",
      paddingTop: "1em",
      fontSize: "80%",
      color: "#666",
    },
    keySet: {
      display: "flex",
      paddingBottom: "0.5em",
    },
    keyItem: {
      paddingRight: "10px",
      display: "flex",
      alignItems: "center",
    },
  }),
);

/**
 * Rehearsal main page. Shows upcoming rehearsals, and a date selector for sending apologies for rehearsals in the far
 * future.
 */
export function Rehearsals() {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const startDatetime =
    params.startDatetime === undefined ? null : parseInt(params.startDatetime);

  const bandId = useAppSelector(selectBandId);
  // TODO: don't want to just get the next 100 events over the next year or whatever, need to get the actual definition
  // so we can fill the calendar in 2035 if we like for example.
  const { data: rehearsals = [], isLoading } = useGetEventInstancesQuery(
    {
      bandId: bandId === undefined ? 0 : bandId,
      filterCriteria: { year: "future" },
    },
    { skip: bandId === undefined },
  );

  const changeDate = (date: Date | null) => {
    if (date === null) {
      navigate(`/player/rehearsals`);
      return;
    }

    // TODO (516): there could be more than one rehearsal on a given date. For now, we assume there's only one.
    const eventInstanceForGig = rehearsals?.find((r) =>
      rehearsalOnDate(r.eventInstance, date),
    );

    if (!eventInstanceForGig) {
      return;
    }

    navigate(
      `/player/rehearsals/${eventInstanceForGig.gig}/${eventInstanceForGig.eventInstance.startDatetime}`,
    );
  };

  if (isLoading) {
    return (
      <Fade in={true} unmountOnExit style={{ transitionDelay: "800ms" }}>
        <LinearProgress />
      </Fade>
    );
  }

  if (rehearsals.length === 0) {
    return <p>No upcoming rehearsals</p>;
  }

  const anyRehearsalOnDate = (date: Date) => {
    return !rehearsals.some((eventInstance) =>
      rehearsalOnDate(eventInstance.eventInstance, date),
    );
  };

  const rehearsalOnDate = (eventInstance: EventInstance, date: Date) =>
    eventInstance.startDatetime >= date.getTime() &&
    eventInstance.startDatetime < date.getTime() + 1000 * 60 * 60 * 24;

  return (
    <div className={classes.main}>
      {startDatetime === null ? (
        <>
          <p>Upcoming rehearsals and those you&apos;re unavailable for:</p>
          <RehearsalList limit={5} allUnavailable={true} />
          <p>Choose a rehearsal to add apologies:</p>
          <DatePicker<Date>
            onChange={changeDate}
            shouldDisableDate={(date) => anyRehearsalOnDate(date)}
            sx={{ pb: "20px" }}
          />
        </>
      ) : (
        <>
          <Box sx={{ pb: 2 }}>
            <Link
              href="/player/rehearsals"
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <ChevronLeftIcon sx={{ marginLeft: -1 }} />{" "}
              <span>Back to rehearsal list</span>
            </Link>
          </Box>
          <PlayerEventDetails />
        </>
      )}
    </div>
  );
}
