import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Person } from "@mui/icons-material";
import React from "react";
import { useGetAvailabilitySummariesQuery } from "api/apiSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      marginTop: theme.spacing(3),
    },
    table: {
      marginTop: theme.spacing(2),
      maxWidth: "600px",
    },
  }),
);

/**
 * Table that shows players the lineup for a gig.
 */
export function GigLineupTable(props: {
  gigId: number;
  startDatetime: number;
}) {
  const classes = useStyles();

  const { data: availabilitySummaries = [] } = useGetAvailabilitySummariesQuery(
    { gigId: props.gigId, startDatetime: props.startDatetime },
  );

  return availabilitySummaries.length > 0 ? (
    <>
      <Typography variant="h4" className={classes.heading}>
        Lineup
      </Typography>
      <TableContainer component={Paper} className={classes.table}>
        <Table size="small" aria-label="Lineup">
          <TableBody>
            {availabilitySummaries
              .slice()
              .sort((a, b) => a.order - b.order)
              .map((summary, i) => (
                <TableRow key={i}>
                  <TableCell>{summary.seat}</TableCell>
                  <TableCell align="right" padding="none">
                    {summary.dep ? (
                      <Person color="secondary" titleAccess="Dep" />
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {summary.givenName} {summary.familyName}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ) : null;
}
