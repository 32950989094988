import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Link, Theme, Typography } from "@mui/material";
import { useGetEventInstancesQuery } from "api/apiSlice";
import { useAppSelector } from "app/store";
import { selectBandId } from "features/users/usersSlice";
import { PlayerAvailabilitySummary } from "features/player/PlayerAvailabilitySummary";
import { RehearsalList } from "features/player/RehearsalList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      maxWidth: 1000,
      padding: theme.spacing(2),
    },
  }),
);

/**
 * Player home page. Shows a summary of upcoming rehearsals and gigs.
 */
export function PlayerDashboard() {
  const classes = useStyles();
  const bandId = useAppSelector(selectBandId);
  const { data: rehearsals = [] } = useGetEventInstancesQuery(
    {
      bandId: bandId === undefined ? 0 : bandId,
      filterCriteria: { year: "future" },
    },
    { skip: bandId === undefined },
  );

  return (
    <div className={classes.main}>
      <Typography variant="h3" sx={{ pb: 3 }}>
        Upcoming rehearsals
      </Typography>
      <RehearsalList limit={3} allUnavailable={false} />

      {rehearsals.length === 0 ? (
        <p>No upcoming rehearsals.</p>
      ) : (
        <Link
          href="/player/rehearsals"
          underline="hover"
          sx={{ pt: 2, display: "flex" }}
        >
          <span>Manage rehearsals</span>
        </Link>
      )}

      <Typography variant="h3" sx={{ pt: 5, pb: 3 }}>
        Upcoming gigs
      </Typography>
      <PlayerAvailabilitySummary limit={3} />
      <Link href="/player/gigs" underline="hover" sx={{ display: "flex" }}>
        <span>See all&hellip;</span>
      </Link>
    </div>
  );
}
