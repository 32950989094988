import React from "react";
import {
  AvailabilityStatus,
  AvailabilitySummary,
} from "domain/availabilityDomain";
import { ShowAvailability } from "features/player/ShowAvailability";
import { SetAvailability } from "features/player/SetAvailability";
import { AvailabilityEvent } from "dtos/AvailabilityEvent";

interface ShowAndSetAvailabilityProps {
  availabilityId?: number;
  gigId?: number;
  startDatetime?: number;
  availabilityEvents?: AvailabilityEvent[];
  availabilityUserId?: number;
  editable: boolean;
  availabilityAssumed: boolean;
  onConfirm: () => void;
}

/**
 * Lets the user set their availability, displays that availability, and lets the user change their mind.
 */
export function ShowAndSetAvailability({
  availabilityId,
  gigId,
  startDatetime,
  availabilityEvents,
  availabilityUserId,
  editable,
  availabilityAssumed,
  onConfirm,
}: ShowAndSetAvailabilityProps) {
  const summary = new AvailabilitySummary(
    availabilityEvents || [],
    availabilityAssumed,
  );

  const availabilityKnown =
    summary.playerAvailability !== AvailabilityStatus.NOT_KNOWN;

  const [settingAvailability, setSettingAvailability] =
    React.useState(!availabilityKnown);

  return summary.canGiveAvailability() ? (
    settingAvailability ? (
      <SetAvailability
        availabilityId={availabilityId}
        gigId={gigId}
        startDatetime={startDatetime}
        availabilityEvents={availabilityEvents || []}
        availabilityAssumed={availabilityAssumed}
        onCancel={() => setSettingAvailability(false)}
        onConfirm={onConfirm}
      />
    ) : (
      <ShowAvailability
        availabilityEvents={availabilityEvents || []}
        availabilityUserId={availabilityUserId || 0}
        editable={editable}
        availabilityAssumed={availabilityAssumed}
        onChangeAvailability={() => setSettingAvailability(true)}
      />
    )
  ) : null;
}
